import React, { useEffect } from 'react';
import { isExpired } from 'react-jwt';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { selectAuth, selectUserInformation, setAuth, setUserInformation } from '../../../store/reducers';
import { Loading } from '../../atoms/Loading/Loading';

type PrivateRouteProps = {
  children?: React.ReactNode;
};

export function PrivateRoute(props: PrivateRouteProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authInfo = useAppSelector(selectAuth);

  const checkAuthenticated = () => {
    const token = localStorage.getItem('JWT');
    const user = localStorage.getItem('User');
    const termAgree = localStorage.getItem('TermsAgree');
    const privacyPolicyAgree = localStorage.getItem('PrivacyPolicyAgree');
    const newsletterAgree = localStorage.getItem('NewsletterAgree');
    if (token && user) {
      const decodedToken = jwtDecode(token) as object;
      if (!isExpired(token) || (decodedToken && !('exp' in decodedToken))) {
        const auth = {
          isAuthenticated: true,
          isTermsAgree: termAgree === 'true',
          isPrivacyPolicyAgree: privacyPolicyAgree === 'true',
          isNewsletterAgree: newsletterAgree === 'true',
          name: user,
        };
        dispatch(setAuth(auth));
      } else {
        const auth = {
          isAuthenticated: false,
          isTermsAgree: termAgree === 'true',
          isPrivacyPolicyAgree: privacyPolicyAgree === 'true',
          isNewsletterAgree: newsletterAgree === 'true',
        };
        dispatch(setAuth(auth));
        navigate('/login');
      }
    } else {
      const auth = {
        isAuthenticated: false,
        isTermsAgree: termAgree === 'true',
        isPrivacyPolicyAgree: privacyPolicyAgree === 'true',
        isNewsletterAgree: newsletterAgree === 'true',
      };
      dispatch(setAuth(auth));
      navigate('/login');
    }
  };

  useEffect(() => {
    checkAuthenticated();
  }, []);

  if (!authInfo.isAuthenticated) {
    return <Loading />;
  }

  if (props.children) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }

  return null;
}
