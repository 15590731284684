import classNames from 'classnames/bind';
import { useAppSelector } from '../../../store/hooks';
import { selectAuth } from '../../../store/reducers';
import { BarButton, BarTitle } from '../../atoms';
import { UserHeader } from '../../organisms/UserHeader/UserHeader';
import styles from './Bar.module.scss';

const cx = classNames.bind(styles);

type BarProps = {
  barTitle?: string;
  showBackButton?: boolean;
  isBasic?: boolean;
};

export function Bar({ barTitle, showBackButton = false, isBasic }: BarProps) {
  const auth = useAppSelector(selectAuth);

  // Hide navigation on desktop displays when back button is not displayed
  const navigationClasses = cx({ navigation: true, hideNavigation: !showBackButton });

  return (
    <div className={styles.Bar}>
      <div className={navigationClasses}>
        <BarButton showBackButton={showBackButton} />
        <BarTitle title={barTitle} />
      </div>
      <div style={{ marginLeft: 'auto' }}></div>
      {!isBasic && auth.isAuthenticated && (
        <div className='hide-on-mobile'>
          <UserHeader />
        </div>
      )}
    </div>
  );
}
