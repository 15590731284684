import type { CSSProperties } from 'react';
import React from 'react';
import { mergeStyles } from '../../../helper/UtilityFunctions';
import { styles } from './PickerField.style';

type PickerItem = {
  value: string | number | boolean;
  label: string;
};

type PickerFieldProps = {
  firstLabel?: string;
  items: PickerItem[];
  onChange: (value: string) => void;
  value?: string;
  hasError?: boolean;
  disableFirstLabel?: boolean;
  style?: CSSProperties[] | CSSProperties;
  enabled?: boolean;
};

export function PickerField({
  firstLabel = '',
  items,
  onChange,
  value,
  hasError = false,
  disableFirstLabel = true,
  style = [],
  enabled = true,
}: PickerFieldProps): JSX.Element {
  const defaultStyles = mergeStyles([styles.picker, hasError ? styles.error : {}]);

  let givenStyles = {};

  if (style) {
    if (Array.isArray(style)) {
      givenStyles = mergeStyles(style);
    } else {
      givenStyles = style;
    }
  }

  const effectiveStyles = mergeStyles([defaultStyles, givenStyles]);

  return (
    <select
      style={effectiveStyles}
      value={value}
      onChange={(event) => onChange(event.currentTarget.value)}
      disabled={!enabled}
    >
      {!disableFirstLabel && <option value=''>{firstLabel}</option>}
      {items.map((item, index) => {
        return (
          <option label={item.label} value={item.value.toString()} key={index}>
            {item.label}
          </option>
        );
      })}
    </select>
  );
}
