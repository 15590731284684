import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectAppData, setAppData } from '../../../store/reducers';
import { Icon } from '..';
import classes from './BarButton.module.scss';

type BarButtonProps = {
  showBackButton: boolean;
};

export function BarButton({ showBackButton = false }: BarButtonProps): JSX.Element {
  const appData = useAppSelector(selectAppData);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const onClickHamburger = () => {
    const reduxData = {
      ...appData,
      navigationDrawerOpen: !appData.navigationDrawerOpen,
    };

    dispatch(setAppData(reduxData));
  };

  const onClickChevron = () => {
    navigate(-1);
  };

  return (
    <>
      {!showBackButton ? (
        <div className={classes.HamburgerMenu}>
          <div className='clickable' onClick={onClickHamburger}>
            <Icon icon='menu' size={20} />
          </div>
        </div>
      ) : (
        <>
          <div className='clickable' style={{ transform: 'rotate(90deg)' }} onClick={onClickChevron}>
            <Icon icon='chevron' size={20} />
          </div>
        </>
      )}
    </>
  );
}
