import React from 'react';
import classNames from 'classnames/bind';
import { ProgressStep } from '../../atoms';
import style from './ProgressBar.module.scss';

const cx = classNames.bind(style);

type ProgressBarProps = {
  count: number;
  countActive: number;
};

export function ProgressBar({ count, countActive }: ProgressBarProps): JSX.Element {
  const barClasses = cx('ProgressBar');
  const stepLabelClasses = cx('StepLabel');

  return (
    <>
      <div className={stepLabelClasses}>
        Schritt {countActive} / {count}
      </div>
      <div className={barClasses}>
        {Array.from({ length: count }, (_, i) => (
          <ProgressStep key={i} isActive={i < countActive}></ProgressStep>
        ))}
      </div>
    </>
  );
}
