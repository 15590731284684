import React from 'react';

type DownloadProps = {
  size?: number;
};

export function Download({ size = 20 }: DownloadProps): JSX.Element {
  return (
    <svg width={size} height={size} viewBox='0 0 19 18'>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Anamnese-Splash-Screen' transform='translate(-51.000000, -494.000000)' fill='#1D1D1B' fillRule='nonzero'>
          <g id='Element-4' transform='translate(51.000000, 494.000000)'>
            <path
              fill='white'
              stroke='white'
              strokeLinecap='round'
              strokeWidth='0.4'
              d='M9.5,14 C9.22385714,14 9,13.7832663 9,13.5159107 L9,0.48408927 C9,0.216733682 9.22385714,0 9.5,0 C9.77614286,0 10,0.216733682 10,0.48408927 L10,13.5159107 C10,13.7832663 9.77614286,14 9.5,14 Z'
            />
            <path
              fill='white'
              stroke='white'
              strokeLinecap='round'
              strokeWidth='0.4'
              d='M4.25302257,7.07705164 C4.507115,6.93073199 4.84559286,6.99903327 5.00781082,7.22914521 L9.50043244,13.5896138 L13.9930541,7.22914521 C14.155272,6.99903327 14.4937499,6.93073199 14.7478423,7.07705164 C15.0019347,7.22337129 15.0756215,7.5305158 14.9154096,7.75865616 L9.96154333,14.7718596 C9.86124369,14.9132503 9.68712351,15 9.5002987,15 C9.3134739,15 9.13948745,14.9133912 9.03905408,14.7718596 L4.0854553,7.75865616 C4.02781643,7.67584966 4,7.58445269 4,7.49390068 C4,7.33124506 4.08973475,7.17140599 4.25302257,7.07705164 Z'
            />
            <path
              fill='white'
              stroke='white'
              strokeLinecap='round'
              strokeWidth='0.4'
              d='M18.5223628,18 L0.47763724,18 C0.213845016,18 0,17.7761424 0,17.5 C0,17.2238576 0.213845016,17 0.47763724,17 L18.5223628,17 C18.786155,17 19,17.2238576 19,17.5 C19,17.7761424 18.786155,18 18.5223628,18 Z'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
