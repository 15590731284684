import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface AuthState {
  isAuthenticated?: boolean;
  isTermsAgree?: boolean;
  name?: string;
  isPrivacyPolicyAgree?: boolean;
  isNewsletterAgree?: boolean;
}

const initialState: AuthState = {
  isAuthenticated: false,
  isTermsAgree: false,
  name: '',
  isPrivacyPolicyAgree: false,
  isNewsletterAgree: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<AuthState>) => {
      return action.payload;
    },
  },
});

export const { setAuth } = authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;
