import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Button, Headline, Label, RadioButton } from '../../../atoms';
import { ProgressBar } from '../../../molecules';
import { BasicHeader } from '../../../organisms/Header/BasicHeader';
import style from './Step1.module.scss';

const cx = classNames.bind(style);
export function Step1(): JSX.Element {
  const navigation = useNavigate();
  const [isAlreadyCannabisPatient, setAlreadyCannabisPatient] = useState<undefined | boolean>();

  useEffect(() => {
    const cannabisPatient = sessionStorage.getItem('isAlreadyCannabisPatient');
    if (cannabisPatient) {
      setAlreadyCannabisPatient(cannabisPatient === 'true');
    }
  }, []);

  const isButtonDisabled = isAlreadyCannabisPatient === undefined;

  const nextStep = () => {
    if (isAlreadyCannabisPatient !== undefined) {
      sessionStorage.setItem('isAlreadyCannabisPatient', isAlreadyCannabisPatient.toString());
      navigation('/questionnaire/step-2', { replace: true });
    }
  };

  const progressBarContainer = cx({ ProgressBarContainer: true });
  const questionContainerClasses = cx('flex', 'flex-column', 'mb-3');
  const questionLabelClasses = cx('mb-1');
  const radioContainerClasses = cx('flex', 'flex-space-around', 'mb-1');
  const buttonContainerClasses = cx('flex', 'flex-center', 'mt-5', 'mb-4');

  return (
    <>
      <BasicHeader />
      <div className={progressBarContainer}>
        <ProgressBar count={8} countActive={1} />
      </div>
      <div style={{ margin: '0 auto', maxWidth: '500px' }}>
        <div>
          <Headline level={3}>Vorausgehende Cannabistherapie?</Headline>
        </div>

        <div className={questionContainerClasses}>
          <div className={questionLabelClasses}>
            <Label>
              Haben Sie in den letzten zwölf Monaten ein Cannabisrezept von einem deutschen Arzt erhalten und eine
              Videosprechstunde oder Sprechstunde vor Ort dazu wahrgenommen?
            </Label>
          </div>
        </div>
        <div className={radioContainerClasses}>
          <span>
            <RadioButton
              id={'isAlreadyCannabisPatientTrue'}
              name={'isAlreadyCannabisPatient'}
              value={'true'}
              defaultChecked={isAlreadyCannabisPatient === true}
              onChange={($event) => setAlreadyCannabisPatient($event.target.checked === true)}
            >
              Ja
            </RadioButton>
          </span>
          <span>
            <RadioButton
              id={'isAlreadyCannabisPatientFalse'}
              name={'isAlreadyCannabisPatient'}
              value={'false'}
              defaultChecked={isAlreadyCannabisPatient === false}
              onChange={($event) => setAlreadyCannabisPatient($event.target.checked === false)}
            >
              Nein
            </RadioButton>
          </span>
        </div>
        <div className={buttonContainerClasses}>
          <Button onClick={nextStep} disabled={isButtonDisabled}>
            Weiter
          </Button>
        </div>
      </div>
    </>
  );
}
