import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Button, Headline, InputField, Label, RadioButton } from '../../../atoms';
import { ProgressBar } from '../../../molecules';
import { BasicHeader } from '../../../organisms/Header/BasicHeader';
import { diagnosticDoctorOptions } from './RadioOptions';
import style from './Step2.module.scss';

const cx = classNames.bind(style);
export function Step2(): JSX.Element {
  const navigation = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [isAlreadyCannabisPatient, setAlreadyCannabisPatient] = useState<boolean>(false);
  const [recipeDoctor, setRecipeDoctor] = useState('');
  const [lastCannabisRecipeDate, setLastCannabisRecipeDate] = useState('');
  const [mainDisease, setMainDisease] = useState('');
  const [diagnosticDoctor, setDiagnosticDoctor] = useState('');
  const [sinceDisease, setSinceDisease] = useState('');
  const [otherDiagnosticDoctor, setOtherDiagnosticDoctor] = useState('');

  useEffect(() => {
    const cannabisPatient = sessionStorage.getItem('isAlreadyCannabisPatient');
    if (!cannabisPatient) {
      navigation('/questionnaire/step-1', { replace: true });

      return;
    }
    setAlreadyCannabisPatient(cannabisPatient === 'true');

    const step2Data = sessionStorage.getItem('questionnaire-step2');
    if (step2Data !== null) {
      const parsedData = JSON.parse(step2Data);
      setRecipeDoctor(parsedData.recipeDoctor);
      setLastCannabisRecipeDate(parsedData.lastCannabisRecipeDate);
      setMainDisease(parsedData.mainDisease);
      setDiagnosticDoctor(parsedData.diagnosticDoctor);
      setSinceDisease(parsedData.sinceDisease);
      setOtherDiagnosticDoctor(parsedData.otherDiagnosticDoctor);
    }
    setLoading(false);
  }, []);

  const isButtonDisabled =
    (isAlreadyCannabisPatient && (recipeDoctor === '' || lastCannabisRecipeDate === '')) ||
    mainDisease === '' ||
    diagnosticDoctor === '' ||
    sinceDisease === '' ||
    (diagnosticDoctor === 'Anderer' && otherDiagnosticDoctor === '');

  const nextStep = () => {
    const stepData = {
      recipeDoctor,
      lastCannabisRecipeDate,
      mainDisease,
      diagnosticDoctor,
      sinceDisease,
      otherDiagnosticDoctor,
    };
    sessionStorage.setItem('questionnaire-step2', JSON.stringify(stepData));
    navigation('/questionnaire/step-3', { replace: true });
  };

  const progressBarContainer = cx({ ProgressBarContainer: true });
  const questionContainerClasses = cx('flex', 'flex-column', 'mb-3');
  const questionLabelClasses = cx('mb-1');
  const buttonContainerClasses = cx('flex', 'flex-center', 'mt-5', 'mb-4');
  const radioContainerClasses = cx({ RadioContainer: true }, 'flex', 'mb-1', 'flex-column', 'gap-1');

  return (
    <>
      <BasicHeader />
      <div className={progressBarContainer}>
        <ProgressBar count={8} countActive={2} />
      </div>
      <div style={{ margin: '0 auto', maxWidth: '500px' }}>
        <div>
          <Headline level={3}>Vorausgehende Cannabistherapie</Headline>
        </div>
        {!loading && (
          <>
            {isAlreadyCannabisPatient && (
              <>
                <div className={questionContainerClasses}>
                  <div className={questionLabelClasses}>
                    <div>
                      <Headline level={3}>Vorausgehende Cannabistherapie</Headline>
                    </div>
                  </div>
                  <div>
                    <InputField onChange={setRecipeDoctor} value={recipeDoctor} placeholder='Vor- und Nachname' />
                  </div>
                </div>
                <div className={questionContainerClasses}>
                  <div className={questionLabelClasses}>
                    <Label>Von welchem Datum ist Ihr letztes Cannabisrezept?</Label>
                  </div>
                  <div>
                    <InputField type={'Date'} onChange={setLastCannabisRecipeDate} value={lastCannabisRecipeDate} />
                  </div>
                </div>
              </>
            )}
            {!isAlreadyCannabisPatient && (
              <>
                <div>
                  <Headline level={3}>Ihre Erkrankung</Headline>
                </div>
              </>
            )}
            <div className={questionContainerClasses}>
              <div className={questionLabelClasses}>
                <Label>
                  Wegen welcher Erkrankung ziehen Sie eine Beratung zur Cannabistherapie in Erwägung (bitte vorerst nur
                  eine nennen)?
                </Label>
              </div>
              <div>
                <InputField onChange={setMainDisease} value={mainDisease} />
              </div>
            </div>

            <div className={questionContainerClasses}>
              <div className={questionLabelClasses}>
                <Label>Welcher Arzt hat diese Erkrankung bei Ihnen diagnostiziert?</Label>
              </div>
              <div className={radioContainerClasses}>
                {diagnosticDoctorOptions.map((option, index) => (
                  <div key={index}>
                    <RadioButton
                      id={`diagnostic-doctor-${index}`}
                      name={'diagnosticDoctor'}
                      value={option.value}
                      checked={option.value === diagnosticDoctor}
                      onChange={($event) => setDiagnosticDoctor($event.target.value)}
                    >
                      {option.label}
                    </RadioButton>
                    {option.value === 'Anderer' && diagnosticDoctor === 'Anderer' && (
                      <InputField onChange={setOtherDiagnosticDoctor} value={otherDiagnosticDoctor} />
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className={questionContainerClasses}>
              <div className={questionLabelClasses}>
                <Label>Seit wann leiden Sie unter dieser Erkrankung? </Label>
              </div>
              <div>
                <InputField type={'Date'} onChange={setSinceDisease} value={sinceDisease} />
              </div>
            </div>

            <div className={buttonContainerClasses}>
              <Button onClick={nextStep} disabled={isButtonDisabled}>
                Weiter
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
