import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import PharmacyService from '../../../services/Pharmacy.service';
import { useAppSelector } from '../../../store/hooks';
import { selectPharmacies, selectUserInformation, selectWindowData } from '../../../store/reducers';
import type { ReducerAction } from '../../../types/navigationIndex.types';
import type { Pharmacy } from '../../../types/ninox.types';
import { Button, Headline, Image, Loading } from '../../atoms';
import { PremiumCheck } from '../../atoms/Icon/Icons/PremiumCheck';
import { Verified } from '../../atoms/Icon/Icons/Verified';
import { Header } from '../../organisms/Header/Header';
import { PharmacyConfirmationModal } from './PharmacyConfirmation';
import classes from './PharmacyDetails.module.scss';

type PharmacyDetailsProps = {
  setCurrentPage: React.Dispatch<ReducerAction>;
};

export const PharmacyDetails = (props: PharmacyDetailsProps) => {
  const { setCurrentPage } = props;
  const navigate = useNavigate();
  const { pharmacyId } = useParams();
  const userInformation = useAppSelector(selectUserInformation);
  const pharmaciesRedux = useAppSelector(selectPharmacies);
  const { isMobile } = useAppSelector(selectWindowData);

  const [loading, setLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [pharmacy, setPharmacy] = useState<Pharmacy>({
    id: 0,
    name: '',
    category: 0,
    isPublic: false,
    descriptionShort: '',
    image: '',
    isVerified: false,
    rating: 0,
    city: '',
    postcode: '',
    street: '',
    houseNumber: '',
    phone: '',
    email: '',
    website: '',
    patientPharmacyrelation: [],
  });
  const disable = pharmacy.id === userInformation.patientPharmacyRelation?.id;

  useEffect(() => {
    if (!pharmacyId) {
      console.error('Pharmacy parameter is missing in URL');

      return;
    }

    // See if the redux store already contains the requested pharmacy
    let loadedPharmacy = pharmaciesRedux.find((pharmacyItem) => pharmacyItem.id === parseInt(pharmacyId, 10));

    if (!loadedPharmacy) {
      setLoading(true);
      (async () => {
        const premiumPharmacies = await PharmacyService.getPharmacies('1');
        const verifiedPharmacies = await PharmacyService.getPharmacies('2');
        const standardPharmacies = await PharmacyService.getPharmacies('3');
        const loadedPharmacies = [...premiumPharmacies.data, ...verifiedPharmacies.data, ...standardPharmacies.data];

        loadedPharmacy = loadedPharmacies.find((pharmacyItem) => pharmacyItem.id === parseInt(pharmacyId, 10));

        if (!loadedPharmacy) {
          // Pharmacy was not in redux store and cannot be loaded
          console.error('Pharmacy ', pharmacyId, 'could not be found');
          setLoading(false);

          return;
        }

        setPharmacy(loadedPharmacy);
        setLoading(false);
      })();
    } else {
      setPharmacy(loadedPharmacy);
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Header />
      <div className={classes.PharmacyDetails}>
        <PharmacyConfirmationModal
          pharmacy={pharmacy}
          confirmationModal={confirmationModal}
          setConfirmationModal={setConfirmationModal}
          setCurrentPage={setCurrentPage}
        />
        <div className={classes.container}>
          <div className={classes.header}>
            {pharmacy.category === 1 && (
              <Image
                className={classes.image}
                source={{ width: isMobile ? 250 : 300, height: 100, uri: `data:image/jpg;base64,${pharmacy.image}` }}
                tintColor={'#3db384'}
              />
            )}
            {[2, 3].includes(pharmacy.category) && <div className={classes['pharmacy-name']}>{pharmacy.name}</div>}
            {[1, 2].includes(pharmacy.category) && (
              <div className={classes.rating}>
                <div className={classes.icon}>
                  {pharmacy.category === 1 && (
                    <div className={classes['icon-and-text']}>
                      <PremiumCheck />
                      Beliebte Apotheke
                    </div>
                  )}
                  {pharmacy.category === 2 && (
                    <div className={classes['icon-and-text']}>
                      <Verified />
                      Verifizierte Apotheke
                    </div>
                  )}
                </div>
                <StarRatings
                  rating={pharmacy.rating}
                  starDimension='19px'
                  starEmptyColor={'rgba(61, 179, 132, 0.25)'}
                  starRatedColor={'#3db384'}
                  starSpacing='2px'
                />
              </div>
            )}
          </div>
          <div className={classes['button-container']}>
            <Button onClick={() => setConfirmationModal(true)} disabled={disable}>
              Apotheke jetzt wählen
            </Button>
          </div>
          <div className={classes['contact-details']}>
            <Headline level={4}>Kontaktdaten</Headline>
            <div className={classes['details-row']}>
              <div className={classes['details-item']}>Apotheke</div>
              <div className={classes['details-value']}>
                {pharmacy.name}
                {pharmacy.city ? ` - ${pharmacy.city}` : ''}
              </div>
            </div>
            <div className={classes['details-row']}>
              <div className={classes['details-item']}>Telefonnummer</div>
              <div className={classes['details-value']}>
                <a href={`tel: ${pharmacy.phone}`}>{pharmacy.phone}</a>
              </div>
            </div>
            <div className={classes['details-row']}>
              <div className={classes['details-item']}>E-Mail</div>
              <div className={classes['details-value']}>
                <a href={`mailto: ${pharmacy.email}`}>{pharmacy.email}</a>
              </div>
            </div>
            <div className={classes['details-row']}>
              <div className={classes['details-item']}>Website</div>
              <div className={classes['details-value']}>
                <a target='_blank' href={pharmacy.website}>
                  {pharmacy.website}
                </a>
              </div>
            </div>
          </div>
          <div className={classes['back-button-container']}>
            <div className={classes['back-button']} onClick={() => navigate(-1)}>
              &#8592; Zurück
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
