import React from 'react';
import { useAppSelector } from '../../../store/hooks';
import { selectAuth } from '../../../store/reducers';
import { Bar } from '../../molecules';
import { NavigationDrawer } from '../NavigationDrawer/NavigationDrawer';
import classes from './Header.module.scss';

type HeaderProps = {
  barTitle?: string;
  showBackButton?: boolean;
};

export function Header({ barTitle, showBackButton }: HeaderProps): JSX.Element {
  const auth = useAppSelector(selectAuth);

  const headerClassName = `${classes.Header} Header`;

  return (
    <>
      {auth.isAuthenticated && (
        <div className={headerClassName}>
          <NavigationDrawer />
          <Bar barTitle={barTitle} showBackButton={showBackButton} isBasic={false} />
        </div>
      )}
    </>
  );
}
