import type { CSSProperties } from 'react';

type ReturnType = {
  [key: string]: CSSProperties;
};

export const styles = (isMobile: boolean, isPhablet: boolean, isDesktop: boolean): ReturnType => {
  return {
    container: {
      flex: 1,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 20,
      marginBottom: 20,
      width: '100%',
      maxWidth: isDesktop ? 1080 : '100%',
    },
    heading2: {
      fontSize: 24,
      fontWeight: 'bold',
      marginTop: 20,
      marginBottom: 20,
      color: '#000',
    },
    heading3: {
      fontSize: 20,
      fontWeight: 'bold',
      marginTop: 20,
      marginBottom: 20,
      color: '#000',
    },
    fontBold: {
      fontWeight: 'bold',
    },
    fontWeight100: {
      fontWeight: '100',
    },
    fontSize24: {
      fontSize: 24,
    },
    fontSize120: {
      fontSize: 120,
    },
    row: {
      flexDirection: 'row',
    },
    column: {
      flexDirection: 'column',
    },
    marginRow: {
      marginTop: isMobile ? 15 : 25,
      marginBottom: isMobile ? 15 : 25,
    },
    marginTop25: {
      marginTop: 25,
    },
    marginBottom0: {
      marginBottom: 0,
    },
    marginBottom15: {
      marginBottom: 15,
    },
    marginBottom25: {
      marginBottom: 25,
    },
    marginRight0: {
      marginRight: 0,
    },
    marginLeft40: {
      marginLeft: 40,
    },
    flex025: {
      flex: 0.25,
    },
    flex1: {
      flex: 1,
    },
    flex5: {
      flex: 5,
    },
    flex15: {
      flex: 1.5,
    },
    alignCenter: {
      alignItems: 'center',
    },
    justifyCenter: {
      justifyContent: 'center',
    },
    saveMessageContainer: {
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'relative',
      minWidth: 150,
    },
    saveMessageText: {
      color: '#3db384',
      position: 'absolute',
      left: 0,
      right: 0,
    },
    buttonContainer: {
      justifyContent: 'center',
      marginTop: 30,
      marginBottom: 30,
    },
    button: {
      backgroundColor: '#3db384',
      borderRadius: 24,
      paddingLeft: 50,
      paddingRight: 50,
      paddingTop: 10,
      paddingBottom: 10,
    },
    buttonText: {
      color: '#fff',
    },
    backButtonText: {
      color: '#3db384',
    },
    buttonLoading: {
      backgroundColor: 'rgba(61, 179, 132, .5)',
      borderRadius: 24,
      paddingLeft: 50,
      paddingRight: 50,
      paddingTop: 10,
      paddingBottom: 10,
    },
    slider: {
      height: 20,
      flex: 1,
    },
    marginTopText: {
      marginTop: 27,
    },
    questionLabel: {
      marginRight: isPhablet || isDesktop ? 15 : 0,
      marginTop: isPhablet || isDesktop ? 27 : 0,
      marginBottom: isMobile ? 15 : undefined,
      fontWeight: isMobile ? 'bold' : undefined,
      fontSize: isMobile ? 17 : undefined,
    },
    marginLeftText: {
      marginLeft: isPhablet || isDesktop ? 30 : 0,
    },
    textAlignCenter: {
      textAlign: 'center',
    },
  };
};
