import type { CSSProperties } from 'react';

export const styles = {
  picker: {
    outline: 'none',
    borderWidth: 0,
    backgroundColor: '#EFEFEE',
    borderRadius: 24,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 12,
    paddingRight: 12,
    minHeight: 36,
    marginTop: 6,
    marginRight: 25,
    minWidth: 125,
    flex: 1,
    width: '100%',
    WebkitAppearance: 'none',
    color: '#000000',
    fontSize: 14,
    fontFamily: 'Montserrat-Regular, sans-serif',
  },
  error: {
    borderColor: '#ff0000',
    borderWidth: 1,
  },
} as { [key: string]: CSSProperties };
