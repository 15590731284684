import React from 'react';
import { COLORS } from '../../../helper/GlobalStyles';
import styles from './InputLoader.module.scss';

type InputLoaderProps = {
  color?: string;
  marginRight?: number;
};

// eslint-disable-next-line import/no-default-export
export default function InputLoader({ color = COLORS.white, marginRight = 10 }: InputLoaderProps) {
  return <div className={styles.InputLoader} style={{ borderColor: color, marginRight }}></div>;
}
