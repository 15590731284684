import * as React from 'react';

type LogoutProps = {
  color?: string;
};

export function Logout({ color }: LogoutProps): JSX.Element {
  return (
    <svg width='25px' height='25px' viewBox='0 0 26 28'>
      <g fill={color} fillRule='nonzero' stroke='none' strokeWidth={1}>
        <path d='M25.773 14.124l.012-.012c.017-.022.034-.039.045-.061.006-.006.006-.011.011-.017.012-.023.029-.045.04-.067 0-.006.006-.012.006-.012a.91.91 0 00.034-.073c0-.005 0-.005.005-.01.012-.023.017-.051.029-.08 0-.005 0-.01.005-.01.006-.029.017-.051.017-.08 0-.01 0-.016.006-.027.006-.023.006-.045.011-.068.006-.033.006-.061.006-.095s0-.062-.006-.095c0-.023-.005-.045-.011-.068 0-.011 0-.017-.006-.028-.005-.028-.011-.05-.017-.078 0-.006 0-.012-.005-.012-.006-.028-.017-.05-.029-.078 0-.006 0-.006-.005-.011-.012-.023-.023-.051-.034-.073 0-.006-.006-.012-.006-.012-.011-.022-.023-.045-.04-.067-.005-.006-.005-.011-.011-.017-.017-.022-.028-.045-.045-.062l-.012-.01c-.023-.023-.04-.051-.068-.074l-5.608-5.545a.983.983 0 00-1.378 0 .958.958 0 000 1.364l3.947 3.906H6.976a.97.97 0 00-.976.96.97.97 0 00.975.965h15.696l-3.918 3.879a.958.958 0 000 1.363.976.976 0 001.372 0l5.574-5.517c.029-.033.051-.056.074-.078z' />
        <path d='M5.47 1.96h7.52c.558 0 1.01-.44 1.01-.977C14 .44 13.548 0 12.99 0H5.47C2.456 0 0 2.39 0 5.321V22.68C0 25.61 2.456 28 5.47 28h7.396c.558 0 1.01-.44 1.01-.977 0-.543-.452-.983-1.01-.983H5.47c-1.904 0-3.455-1.51-3.455-3.361V5.32c.006-1.857 1.551-3.36 3.455-3.36z' />
      </g>
    </svg>
  );
}
