import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Icon, Loading } from '../../atoms';
import classes from './DocumentPreview.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type DocumentPreviewProps = {
  uri: string;
  type: string;
  name: string;
  setViewDocument: (value: boolean) => void;
};

export function DocumentPreview(props: DocumentPreviewProps) {
  const pdfSource = { uri: `data:application/pdf;base64,${props.uri}` };
  const imageSource = { uri: `data:image/${props.type};base64,${props.uri}` };

  return (
    <div className={classes.DocumentPreview}>
      <div className={classes['title-bar']}>
        <div className={classes.close} onClick={() => props.setViewDocument(false)}>
          <Icon icon='chevron' size={20} />
        </div>
        <div className={classes.filename}>{props.name}</div>
      </div>
      {props.type !== 'pdf' && (
        <div className={classes['img-area']}>
          <img src={imageSource.uri} />
        </div>
      )}
      {props.type === 'pdf' && (
        <div className={classes['pdf-area']}>
          <Document className={classes['pdf-doc']} file={pdfSource.uri} loading={<Loading />}>
            <Page
              className={classes['pdf-page']}
              pageNumber={1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          </Document>
        </div>
      )}
    </div>
  );
}
