import React from 'react';

type UncheckedProps = {
  size?: number;
  color?: string;
};

export function Unchecked({ size = 20, color = '#efefee' }: UncheckedProps): JSX.Element {
  return (
    <svg viewBox='0 0 32 32' width={size} height={size}>
      <rect width={32} height={32} rx={4} fill={color} />
    </svg>
  );
}
