import type { ReactNode } from 'react';
import React from 'react';
import classNames from 'classnames/bind';
import style from './RadioButton.module.scss';

const cx = classNames.bind(style);

type RadioButtonProps = {
  children: ReactNode;
  name: string;
  id: string;
  extraClasses?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
} & React.InputHTMLAttributes<HTMLInputElement>;

export function RadioButton({ id, name, children, ...props }: RadioButtonProps): JSX.Element {
  const radioButtonClasses = cx('RadioButton');
  let labelClasses = cx('Label');

  if (props.extraClasses) {
    labelClasses += ` ${props.extraClasses}`;
  }

  const changeEventHandler = props.onChange || (() => {});

  return (
    <label className={labelClasses} htmlFor={id}>
      <input className={radioButtonClasses} type='radio' id={id} name={name} {...props} onChange={changeEventHandler} />
      {children}
    </label>
  );
}
