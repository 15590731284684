import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Headline, Icon } from '../../../atoms';
import classes from './Step2.module.scss';

export function Step2() {
  const cx = classNames.bind(classes);

  const containerClasses = cx({ Container: true });
  const iconContainerClasses = cx({ IconContainer: true });
  const hiddenButtonClasses = cx({ HiddenButton: true });

  const navigation = useNavigate();

  const navigateToLogin = async () => {
    navigation('/login', { replace: true });
  };

  return (
    <div className={containerClasses}>
      <div className={iconContainerClasses}>
        <Icon icon='checkCircle' size={140} />
      </div>
      <Headline level={3}>Vielen Dank!</Headline>
      <p>
        <b>Ihr Check-In ist abgeschlossen.</b>
        <br />
        Bitte geben Sie das Tablet nun an der Rezeption ab. Anschließend werden Sie zu Ihrem Arztgespräch abgeholt.
      </p>
      <div className={hiddenButtonClasses} onDoubleClick={navigateToLogin}></div>
    </div>
  );
}
