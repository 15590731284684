import React from 'react';

type AnswerQuestionProps = {
  size?: number;
};

export function AnswerQuestion({ size = 20 }: AnswerQuestionProps): JSX.Element {
  return (
    <svg width={size} height={size} viewBox='0 0 234 234' enable-background='new 0 0 234 234'>
      <g>
        <path
          fill='#3DB384'
          d='M117,61.81c-6.065,0-11,4.935-11,11c0,0.552,0.447,1,1,1s1-0.448,1-1c0-4.962,4.037-9,9-9
    c4.479,0,9,2.448,9,7.918c0,3.764-2.463,5.503-4.846,7.186c-0.895,0.631-1.738,1.228-2.427,1.916
    C116.79,82.766,116,84.673,116,87.41v2.631c0,0.552,0.447,1,1,1s1-0.448,1-1V87.41c0-2.233,0.561-3.585,2.142-5.167
    c0.567-0.567,1.344-1.116,2.167-1.696c2.535-1.791,5.691-4.019,5.691-8.819C128,65.888,123.477,61.81,117,61.81z'
        />
        <circle fill='#3DB384' cx='116.987' cy='97.634' r='1.5' />
        <path
          fill='#3DB384'
          d='M104.411,144h45c0.828,0,1.5-0.672,1.5-1.5s-0.672-1.5-1.5-1.5h-45c-0.828,0-1.5,0.672-1.5,1.5
    S103.583,144,104.411,144z'
        />
        <path
          fill='#3DB384'
          d='M87.589,137c0.828,0,1.5-0.672,1.5-1.5s-0.672-1.5-1.5-1.5h-9c-0.828,0-1.5,0.672-1.5,1.5v14
    c0,0.828,0.672,1.5,1.5,1.5h14c0.828,0,1.5-0.672,1.5-1.5v-2.789c0-0.828-0.672-1.5-1.5-1.5s-1.5,0.672-1.5,1.5V148h-11v-11H87.589
    z'
        />
        <path
          fill='#3DB384'
          d='M155.411,163h-51c-0.828,0-1.5,0.672-1.5,1.5s0.672,1.5,1.5,1.5h51c0.828,0,1.5-0.672,1.5-1.5
    S156.239,163,155.411,163z'
        />
        <path
          fill='#3DB384'
          d='M144.411,185h-40c-0.828,0-1.5,0.672-1.5,1.5s0.672,1.5,1.5,1.5h40c0.828,0,1.5-0.672,1.5-1.5
    S145.239,185,144.411,185z'
        />
        <path
          fill='#3DB384'
          d='M92.589,178h-14c-0.828,0-1.5,0.672-1.5,1.5v14c0,0.828,0.672,1.5,1.5,1.5h14c0.828,0,1.5-0.672,1.5-1.5
    v-14C94.089,178.672,93.417,178,92.589,178z M91.089,192h-11v-11h11V192z'
        />
        <path
          fill='#3DB384'
          d='M96.255,133.294l-9.188,9.363l-2.291-2.291c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414
    l3.719,3.719l10.602-10.805c0.387-0.394,0.381-1.027-0.014-1.414C97.275,132.893,96.643,132.897,96.255,133.294z'
        />
        <path
          fill='#3DB384'
          d='M92.589,167.211c-0.828,0-1.5,0.672-1.5,1.5V170h-11v-11h7.5c0.828,0,1.5-0.672,1.5-1.5s-0.672-1.5-1.5-1.5
    h-9c-0.828,0-1.5,0.672-1.5,1.5v14c0,0.828,0.672,1.5,1.5,1.5h14c0.828,0,1.5-0.672,1.5-1.5v-2.789
    C94.089,167.883,93.417,167.211,92.589,167.211z'
        />
        <path
          fill='#3DB384'
          d='M96.255,155.294l-9.188,9.363l-2.291-2.291c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414
    l3.719,3.719l10.602-10.805c0.387-0.394,0.381-1.027-0.014-1.414C97.275,154.893,96.643,154.897,96.255,155.294z'
        />
        <path
          fill='#3DB384'
          d='M200,78h-41.525C157.94,55.578,139.547,37.5,117,37.5S76.06,55.578,75.525,78H34v2h41.525
    c0.535,22.422,18.927,40.5,41.475,40.5s40.94-18.078,41.475-40.5H200V78z M117,117.5c-21.229,0-38.5-17.271-38.5-38.5
    S95.771,40.5,117,40.5s38.5,17.271,38.5,38.5S138.229,117.5,117,117.5z'
        />
      </g>
    </svg>
  );
}
