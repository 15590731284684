import type { CSSProperties } from 'react';

export const styles = (isDesktop: boolean) => {
  return {
    container: {
      flex: 1,
      height: '100%',
      marginTop: 50,
      marginBottom: 50,
    },
    whiteBackground: {
      backgroundColor: '#fff',
    },
    scrollview: {
      height: '100%',
      width: '100%',
      backgroundColor: '#fff',
    },
    alignCenter: {
      alignItems: 'center',
    },
    justifyContentStart: {
      justifyContent: 'flex-start',
    },
    heading2: {
      fontSize: isDesktop ? 29 : 19,
      fontWeight: 'bold',
      marginBottom: 5,
      color: '#000',
    },
    textBold: {
      fontSize: 15,
      color: '#000',
      fontWeight: 'bold',
    },
    label: {
      marginBottom: 5,
    },
    marginRow: {
      marginTop: isDesktop ? 25 : 10,
      marginBottom: isDesktop ? 25 : 10,
    },
    alignItemsStart: {
      alignItems: 'flex-start',
    },
    greenCircleContainer: {
      height: isDesktop ? 135 : 80,
      width: isDesktop ? 135 : 80,
      paddingTop: isDesktop ? 20 : 20,
      paddingBottom: isDesktop ? 20 : 20,
      paddingLeft: isDesktop ? 10 : 10,
      paddingRight: isDesktop ? 10 : 10,
      borderRadius: isDesktop ? 135 : 80,
      backgroundColor: '#3db384',
    },
    greenCircleText: {
      alignItems: 'center',
      textAlign: 'center',
      color: '#fff',
      marginTop: isDesktop ? 15 : 0,
      fontSize: isDesktop ? 52 : 30,
    },
    buttonContainer: {
      flex: 1,
      justifyContent: isDesktop ? 'flex-start' : 'center',
      marginTop: isDesktop ? 0 : 25,
    },
    button: {
      backgroundColor: '#3db384',
      borderRadius: 24,
      paddingLeft: 35,
      paddingRight: 35,
      paddingTop: 10,
      paddingBottom: 10,
    },
    buttonText: {
      color: '#fff',
    },
    width100: {
      width: '100%',
    },
    deleteButtonContainer: {
      flex: 1,
      justifyContent: isDesktop ? 'flex-start' : 'center',
      marginTop: isDesktop ? undefined : 30,
    },
    deleteDescription: {
      marginTop: 10,
    },
    deleteButton: {
      borderRadius: 24,
      borderWidth: 1,
    },
    deleteButtonSecondary: {
      backgroundColor: '#fff',
      borderColor: '#DC0000',
      borderStyle: 'solid',
      paddingLeft: 35,
      paddingRight: 35,
      paddingTop: 10,
      paddingBottom: 10,
    },
    deleteButtonSecondaryText: {
      color: '#DC0000',
      fontSize: isDesktop ? undefined : 12,
    },
    deleteButtonPrimary: {
      backgroundColor: '#DC0000',
      borderColor: '#DC0000',
      paddingLeft: isDesktop ? 35 : 24,
      paddingRight: isDesktop ? 35 : 24,
      paddingTop: isDesktop ? 10 : 6,
      paddingBottom: isDesktop ? 10 : 6,
      marginRight: 16,
    },
    deleteButtonSuccess: {
      backgroundColor: '#3db384',
      borderColor: '#3db384',
      paddingLeft: isDesktop ? 35 : 24,
      paddingRight: isDesktop ? 35 : 24,
      paddingTop: isDesktop ? 10 : 6,
      paddingBottom: isDesktop ? 10 : 6,
      marginRight: 16,
    },
    deleteButtonPrimaryText: {
      color: '#fff',
      fontSize: isDesktop ? undefined : 12,
      fontWeight: '700',
    },
    cancelButton: {
      backgroundColor: '#fff',
      borderColor: '#3db384',
      paddingLeft: isDesktop ? 35 : 24,
      paddingRight: isDesktop ? 35 : 24,
      paddingTop: isDesktop ? 10 : 6,
      paddingBottom: isDesktop ? 10 : 6,
      marginRight: 16,
    },
    cancelButtonText: {
      color: '#3db384',
      fontSize: isDesktop ? undefined : 12,
      fontWeight: '700',
    },
    deleteModal: {
      marginTop: 2,
      marginBottom: 2,
      marginLeft: 2,
      marginRight: 2,
    },
    deleteModalWeb: {
      flex: 1,
      justifyContent: 'center',
    },
    deleteModalTitle: {
      fontSize: isDesktop ? 24 : 20,
      fontWeight: 'bold',
      marginBottom: isDesktop ? 24 : 16,
      color: '#000',
    },
    deleteModalText: {
      fontSize: 14,
      marginBottom: 10,
    },
    deleteButtons: {
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: isDesktop ? 24 : 16,
    },
    textLink: {
      textDecorationLine: 'underline',
      cursor: 'pointer',
    },
  } as { [key: string]: CSSProperties };
};
