export const diagnosticDoctorOptions = [
  { label: 'Hausarzt', value: 'Hausarzt' },
  { label: 'Neurologe', value: 'Neurologe' },
  { label: 'Orthopäde', value: 'Orthopäde' },
  { label: 'Psychiater', value: 'Psychiater' },
  { label: 'Dermatologe', value: 'Dermatologe' },
  { label: 'Rheumatologe', value: 'Rheumatologe' },
  { label: 'Gynäkologe', value: 'Gynäkologe' },
  { label: 'Facharzt für Innere Medizin', value: 'Facharzt für Innere Medizin' },
  { label: 'HNO-Arzt', value: 'HNO-Arzt' },
  { label: 'Heilpraktiker', value: 'Heilpraktiker' },
  { label: 'Anderer', value: 'Anderer' },
  { label: 'Noch keiner', value: 'Noch keiner' },
];

export const diseaseSinceOptions = [
  { label: 'weniger als einen Monat', value: 'weniger als einen Monat' },
  { label: 'weniger als drei Monate', value: 'weniger als drei Monate' },
  { label: 'weniger als ein Jahr', value: 'weniger als ein Jahr' },
  { label: 'länger als ein Jahr', value: 'länger als ein Jahr' },
];
