import type { CSSProperties, FormEvent, KeyboardEvent } from 'react';
import React from 'react';
import { mergeStyles } from '../../../helper/UtilityFunctions';
import { styles as inlineStyles } from './InputField.style';
import styles from './InputField.module.scss';

type InputFieldProps = {
  onChange: (text: string) => void;
  secure?: boolean;
  isNumber?: boolean;
  isEmail?: boolean;
  isTel?: boolean;
  style?: CSSProperties[] | CSSProperties;
  value: string;
  multiline?: boolean;
  isEditable?: boolean;
  hasError?: boolean;
  onKeyPress?: (event: KeyboardEvent) => void;
  type?: string;
  placeholder?: string;
};

export function InputField({
  isEditable = true,
  onChange,
  secure = false,
  isNumber = false,
  isEmail = false,
  isTel = false,
  style = [],
  value,
  multiline = false,
  hasError = false,
  onKeyPress,
  type = 'text',
  placeholder = '',
}: InputFieldProps): JSX.Element {
  let propStyles: CSSProperties;

  if (Array.isArray(style)) {
    propStyles = mergeStyles(style);
  } else {
    propStyles = style;
  }

  let effectiveInlineStyles = mergeStyles([inlineStyles.input, propStyles]);

  if (hasError) {
    effectiveInlineStyles = mergeStyles([effectiveInlineStyles, inlineStyles.error]);
  }

  let effectiveType = multiline ? 'textarea' : type;
  effectiveType = isNumber ? 'number' : effectiveType;
  effectiveType = isEmail ? 'email' : effectiveType;
  effectiveType = secure ? 'password' : effectiveType;
  effectiveType = isTel ? 'tel' : effectiveType;

  if (effectiveType === 'textarea') {
    effectiveInlineStyles = mergeStyles([effectiveInlineStyles, inlineStyles.textarea]);

    return (
      <textarea
        className={styles.TextArea}
        value={value}
        onChange={(event: FormEvent<HTMLTextAreaElement>) => {
          onChange(event.currentTarget.value);
        }}
        disabled={!isEditable}
        onKeyPress={onKeyPress}
        style={effectiveInlineStyles}
        placeholder={placeholder}
      />
    );
  }

  return (
    <input
      className={styles.Input}
      type={effectiveType}
      value={value}
      onChange={(event: FormEvent<HTMLInputElement>) => {
        onChange(event.currentTarget.value);
      }}
      disabled={!isEditable}
      onKeyPress={onKeyPress}
      style={effectiveInlineStyles}
      placeholder={placeholder}
    />
  );
}
