import React from 'react';

export function Social({ size = 20, color = '#fff' }): JSX.Element {
  return (
    <svg viewBox='0 0 17.93 17.24' width={size} height={size}>
      <path
        d='M5 8.37a1.13 1.13 0 01-1.14-1.12 1.12 1.12 0 112.24 0A1.13 1.13 0 015 8.37zm4.14 0A1.13 1.13 0 018 7.25a1.12 1.12 0 111.12 1.12zm4.17 0a1.13 1.13 0 01-1.12-1.12 1.11 1.11 0 011.12-1.12 1.12 1.12 0 110 2.24z'
        fill={color}
      />
      <path
        d='M6.78 13.09a.65.65 0 00-.58.15 5.68 5.68 0 01-3.7 1.64 8 8 0 001.2-2.24c0-.1.08-.18.11-.26a.63.63 0 00-.21-.73 5.51 5.51 0 01-2.37-4.38c0-3.33 3.46-6 7.74-6s7.74 2.71 7.74 6a5.15 5.15 0 01-.78 2.66 5.54 5.54 0 011 .71 6.24 6.24 0 001-3.37c0-4-4-7.27-9-7.27S0 3.26 0 7.27a6.61 6.61 0 002.5 5 5 5 0 01-1.82 2.61.59.59 0 00-.26.63.61.61 0 00.47.49 7.2 7.2 0 001.17.1 7 7 0 004.77-1.77 7.77 7.77 0 001.43.18A3.44 3.44 0 018 13.24a7 7 0 01-1.22-.15z'
        fill={color}
      />
      <circle cx={4.98} cy={7.25} r={1.12} fill={color} />
      <circle cx={9.12} cy={7.25} r={1.12} fill={color} />
      <circle cx={13.29} cy={7.25} r={1.12} fill={color} />
      <path
        d='M15.77 15.67A2.89 2.89 0 0017 13.4a2.81 2.81 0 00-.94-2 4.23 4.23 0 00-.78-.55 4.74 4.74 0 00-2.22-.55c-2.16 0-3.94 1.41-3.94 3.13a2.33 2.33 0 00.21 1 4 4 0 003.73 2.17 5.25 5.25 0 001.09-.13 3.1 3.1 0 002.64.8 2.88 2.88 0 01-1.02-1.6z'
        fill={color}
      />
    </svg>
  );
}
