import { isMobile } from '../../../helper/Breakpoints';

export const styles = {
  row: {
    flexDirection: 'row',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    marginHorizontal: isMobile ? 25 : 50,
    marginTop: 45,
    marginBottom: 25,
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 10,
  },
  logo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  chevron: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.25s ease-out',
  },
  chevronRotate: {
    transform: 'rotate(180deg)',
  },
  user: {
    alignItems: 'center',
    position: 'relative',
  },
  userText: {
    marginLeft: 15,
    marginRight: 15,
  },
  userMenu: {
    position: 'absolute',
    display: 'none',
    top: 40,
    left: 'auto',
    right: 0,
  },
  userMenuDisplay: {
    display: 'flex',
  },
  padding10: {
    padding: 10,
  },
};
