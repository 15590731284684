import React from 'react';

type StartTherapieProps = {
  size?: number;
};

export function StartTherapie({ size = 20 }: StartTherapieProps): JSX.Element {
  return (
    <svg width={size} height={size} viewBox='0 0 234 234' enable-background='new 0 0 234 234'>
      <g>
        <path
          fill='#3DB384'
          d='M169,97.141V59.476c0-7.155-5.801-12.976-12.932-12.976H144v-5.097c0-2.481-2.019-4.5-4.5-4.5
		s-4.5,2.019-4.5,4.5V46.5H81v-5.097c0-2.481-2.019-4.5-4.5-4.5s-4.5,2.019-4.5,4.5V46.5H59.932C52.801,46.5,47,52.321,47,59.476
		v96.527c0,7.144,5.782,12.957,12.897,12.976c0.004,0,0.009,0.001,0.013,0.001c0.001,0,0.003,0,0.004,0
		c0.006,0,0.012,0.001,0.018,0.001v-0.003c0.818-0.012,1.478-0.677,1.478-1.498s-0.66-1.486-1.478-1.498v-0.002
		c-5.477,0-9.932-4.476-9.932-9.978V69.434h116v27.13c-2.514-0.395-5.089-0.605-7.712-0.605c-2.304,0-4.567,0.171-6.788,0.477V88
		c0-1.103-0.897-2-2-2h-11c-1.103,0-2,0.897-2,2v11c0,0.672,0.336,1.264,0.845,1.627c-4.585,2.153-8.79,4.985-12.478,8.373H114.5
		c-1.103,0-2,0.897-2,2v11c0,0.85,0.536,1.573,1.285,1.862c-3.169,6.518-4.951,13.83-4.951,21.552
		c0,27.27,22.185,49.454,49.454,49.454s49.454-22.185,49.454-49.454C207.742,121.823,191.135,102.05,169,97.141z M138,41.403
		c0-0.827,0.673-1.5,1.5-1.5s1.5,0.673,1.5,1.5v12.128c0,0.827-0.673,1.5-1.5,1.5s-1.5-0.673-1.5-1.5V41.403z M75,41.403
		c0-0.827,0.673-1.5,1.5-1.5s1.5,0.673,1.5,1.5v12.128c0,0.827-0.673,1.5-1.5,1.5s-1.5-0.673-1.5-1.5V41.403z M50,67.434v-7.958
		c0-5.501,4.455-9.976,9.932-9.976H72v4.032c0,2.481,2.019,4.5,4.5,4.5s4.5-2.019,4.5-4.5V49.5h54v4.032c0,2.481,2.019,4.5,4.5,4.5
		s4.5-2.019,4.5-4.5V49.5h12.068c5.477,0,9.932,4.475,9.932,9.976v7.958H50z M138.5,88h11v8.757
		c-2.853,0.514-5.625,1.261-8.286,2.243H138.5V88z M114.5,111h8.321c-3.292,3.392-6.101,7.25-8.321,11.468V111z M127.118,111h0.382
		c0-0.104-0.015-0.204-0.031-0.304c8.207-7.294,19.001-11.736,30.819-11.736c25.615,0,46.454,20.839,46.454,46.455
		c0,10.32-3.387,19.862-9.101,27.581c-0.134-5.484-2.62-10.033-7.11-12.759c-0.925-0.562-1.744-0.99-2.505-1.309
		c-0.001-0.001-0.003-0.001-0.004-0.002c-1.672-0.698-2.755-0.71-4.008-0.724c-1.364-0.016-3.062-0.034-6.404-1.089
		c-6.382-2.016-8.086-5.391-8.375-6.084v-4.913c2.173-2.326,3.737-5.176,4.627-8.464c0.185-0.605,0.413-1.273,0.66-1.996
		c1.054-3.085,2.497-7.31,2.386-11.974l0.009-0.354c0-2.378-0.496-4.681-1.475-6.843c-1.248-2.76-3.228-5.129-5.726-6.852
		c-2.781-1.919-6.042-2.933-9.428-2.933c-3.388,0-6.647,1.014-9.428,2.933c-2.498,1.723-4.478,4.092-5.726,6.852
		c-0.979,2.162-1.475,4.465-1.475,6.843l0.008,0.306c-0.11,4.713,1.333,8.938,2.387,12.021c0.247,0.724,0.476,1.392,0.651,1.967
		c0.899,3.316,2.462,6.167,4.635,8.495v4.912c-0.298,0.711-2.008,4.074-8.374,6.085c-3.343,1.055-5.04,1.073-6.404,1.089
		c-1.254,0.014-2.337,0.026-4.012,0.726c0,0-0.001,0-0.001,0.001c-0.759,0.317-1.578,0.745-2.504,1.308
		c-4.49,2.726-6.976,7.275-7.111,12.76c-5.715-7.719-9.102-17.261-9.102-27.581C111.834,131.783,117.736,119.506,127.118,111z
		 M182.477,185.057c0.012-0.087,0.025-0.176,0.037-0.263c0.002-0.001,0.005-0.003,0.007-0.004c0.769-5.162,0.868-8.707,0.874-8.933
		c0.013-0.552-0.424-1.01-0.977-1.023c-0.531,0.007-1.01,0.425-1.023,0.977c-0.001,0.059-0.12,4.277-1.097,10.205c0,0,0,0,0.001,0
		c-0.018,0.108-0.036,0.217-0.053,0.325c-6.542,3.524-14.019,5.528-21.956,5.528c-7.889,0-15.321-1.982-21.835-5.466
		c-0.023-0.142-0.03-0.182-0.052-0.325c0,0,0.001,0,0.001,0.001c-0.986-5.956-1.107-10.208-1.109-10.268
		c-0.014-0.544-0.458-0.976-1-0.976c-0.008,0-0.016,0-0.024,0c-0.552,0.014-0.988,0.472-0.976,1.023
		c0.006,0.226,0.107,3.803,0.885,9.003c0.016,0.109,0.026,0.186,0.038,0.272c-4.206-2.559-7.967-5.775-11.16-9.492
		c-0.699-5.955,1.476-10.931,6.028-13.695c0.841-0.51,1.573-0.894,2.239-1.172c0.001-0.001,0.002-0.001,0.004-0.002
		c1.312-0.548,2.129-0.557,3.259-0.569c1.496-0.017,3.358-0.037,6.983-1.183c2.301-0.726,4.061-1.633,5.424-2.563v3.393
		c-2.504,0.205-4.594,1.124-6.076,2.709c-1.729,1.848-2.528,4.522-2.313,7.734c0.429,6.401,1.103,10.123,1.121,10.219
		c0.66,5.442,3.146,6.628,5.143,6.654c0.265,0.235,0.61,0.383,0.99,0.383c0.825,0,1.5-0.675,1.5-1.5v-0.108
		c0-0.825-0.675-1.5-1.5-1.5c-0.536,0-1.004,0.287-1.27,0.712c-0.834-0.084-2.369-0.68-2.887-4.941
		c-0.007-0.037-0.682-3.778-1.102-10.052c-0.18-2.685,0.418-4.782,1.777-6.235c1.29-1.379,3.231-2.112,5.616-2.128
		c2.385,0.016,4.327,0.749,5.616,2.128c1.359,1.453,1.957,3.551,1.777,6.235c-0.42,6.273-1.095,10.015-1.111,10.111
		c-0.51,4.209-2.043,4.798-2.877,4.881c-0.265-0.425-0.734-0.712-1.27-0.712c-0.825,0-1.5,0.675-1.5,1.5c0,0.825,0.675,1.5,1.5,1.5
		c0.324,0,0.623-0.107,0.869-0.283c0.019,0.001,0.035,0.011,0.054,0.011c2.009,0,4.543-1.154,5.2-6.597
		c0.028-0.155,0.702-3.877,1.131-10.278c0.215-3.212-0.585-5.887-2.313-7.734c-1.482-1.585-3.572-2.504-6.076-2.709v-4.708
		c0-0.091-0.029-0.172-0.052-0.256c1.807-1.754,2.318-3.26,2.354-3.372l0.047-5.772c0-0.001,0-0.002,0-0.002v-0.01
		c0-0.036-0.017-0.067-0.021-0.102c-0.01-0.094-0.02-0.187-0.056-0.275c-0.032-0.078-0.085-0.142-0.135-0.209
		c-0.027-0.036-0.04-0.079-0.073-0.113c-2.085-2.134-3.572-4.802-4.43-7.961c-0.189-0.624-0.425-1.313-0.68-2.06
		c-1.062-3.106-2.382-6.971-2.279-11.369l-0.008-0.312c0-2.092,0.437-4.116,1.297-6.019c1.099-2.429,2.841-4.514,5.039-6.029
		c2.445-1.688,5.312-2.58,8.292-2.58c2.979,0,5.846,0.892,8.293,2.58c2.197,1.516,3.939,3.601,5.038,6.029
		c0.86,1.902,1.297,3.927,1.297,6.019l-0.009,0.352c0.104,4.357-1.217,8.224-2.278,11.33c-0.255,0.746-0.49,1.435-0.688,2.089
		c-0.849,3.13-2.337,5.798-4.421,7.931c-0.024,0.025-0.033,0.057-0.054,0.083c-0.06,0.075-0.119,0.15-0.156,0.24
		c-0.035,0.085-0.043,0.175-0.053,0.265c-0.004,0.038-0.022,0.072-0.022,0.112v5.482l0.047,0.304c0.049,0.152,0.971,2.89,4.87,5.32
		v11.504c-2.833,0.478-5,2.942-5,5.91c0,3.309,2.691,6,5.999,6c3.309,0,6.001-2.691,6.001-6c0-2.967-2.167-5.431-5-5.91v-10.427
		c0.85,0.397,1.793,0.772,2.856,1.108c3.625,1.146,5.487,1.166,6.984,1.183c1.13,0.012,1.946,0.021,3.259,0.569h0.001
		c0.669,0.28,1.401,0.664,2.24,1.174c4.553,2.764,6.728,7.742,6.028,13.695C190.357,179.322,186.635,182.511,182.477,185.057z
		 M171.151,170.247c2.206,0,4.001,1.794,4.001,4s-1.795,4-4.001,4c-2.205,0-3.999-1.794-3.999-4S168.946,170.247,171.151,170.247z'
        />
        <path
          fill='#3DB384'
          d='M101.5,86h-11c-1.103,0-2,0.897-2,2v11c0,1.103,0.897,2,2,2h11c1.103,0,2-0.897,2-2V88
		C103.5,86.897,102.603,86,101.5,86z M90.5,99V88h11l0.001,11H90.5z'
        />
        <path
          fill='#3DB384'
          d='M114.5,101h11c1.103,0,2-0.897,2-2V88c0-1.103-0.897-2-2-2h-11c-1.103,0-2,0.897-2,2v11
		C112.5,100.103,113.397,101,114.5,101z M114.5,88h11l0.001,11H114.5V88z'
        />
        <path
          fill='#3DB384'
          d='M77.5,109h-11c-1.103,0-2,0.897-2,2v11c0,1.103,0.897,2,2,2h11c1.103,0,2-0.897,2-2v-11
		C79.5,109.897,78.603,109,77.5,109z M66.5,122v-11h11l0.002,11H66.5z'
        />
        <path
          fill='#3DB384'
          d='M101.5,109h-11c-1.103,0-2,0.897-2,2v11c0,1.103,0.897,2,2,2h11c1.103,0,2-0.897,2-2v-11
		C103.5,109.897,102.603,109,101.5,109z M90.5,122v-11h11l0.002,11H90.5z'
        />
        <path
          fill='#3DB384'
          d='M77.5,132h-11c-1.103,0-2,0.897-2,2v11c0,1.103,0.897,2,2,2h11c1.103,0,2-0.897,2-2v-11
		C79.5,132.897,78.603,132,77.5,132z M66.5,145v-11h11l0.002,11H66.5z'
        />
        <path
          fill='#3DB384'
          d='M101.5,132h-11c-1.103,0-2,0.897-2,2v11c0,1.103,0.897,2,2,2h11c1.103,0,2-0.897,2-2v-11
		C103.5,132.897,102.603,132,101.5,132z M90.5,145v-11h11l0.002,11H90.5z'
        />
        <circle fill='#3DB384' cx='171.152' cy='174.247' r='1.5' />
      </g>
    </svg>
  );
}
