import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Headline } from '../../atoms';
import { Header } from '../../organisms/Header/Header';
import classes from './Error.module.scss';

export function Error() {
  const navigate = useNavigate();

  const location = useLocation();
  let message = location.state?.message;

  if (!message) {
    message = '';
  }

  return (
    <React.Fragment>
      <Header />
      <div className={classes.Error}>
        <Headline level={3}>Fehler</Headline>
        <div>{message}</div>
        <div className={classes['button-container']}>
          <Button onClick={() => navigate('/dashboard')}>Zum Dashboard</Button>
        </div>
      </div>
    </React.Fragment>
  );
}
