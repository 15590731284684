import { Icon } from '../../atoms';
import styles from './BaseHeader.module.scss';

export function BaseHeader() {
  return (
    <div className={styles.BaseHeader}>
      <a href='https://www.nowomed.com'>
        <Icon icon='nowomed' size={220} />
      </a>
    </div>
  );
}
