export const styles = {
  scrollview: {
    height: '100%',
    paddingLeft: 25,
    paddingRight: 25,
    backgroundColor: 'white',
  },
  container: {
    flex: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,
    marginBottom: 20,
  },
  row: {
    flexDirection: 'row',
  },
  alignCenter: {
    textAlign: 'center',
  },
  firstLabel: {
    marginBottom: 20,
  },
  heading3: {
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 20,
    marginBottom: 20,
    color: '#000',
  },
  inputContainer: {
    marginTop: 20,
    marginBottom: 20,
    position: 'relative',
  },
  error: {
    color: 'red',
    position: 'absolute',
    width: '100%',
    top: 65,
  },
  errorDelete: {
    color: 'red',
    width: '100%',
    margin: 'auto',
    textAlign: 'center',
  },
  errorDeleteContainer: {
    width: '80%',
  },
  errorPharmacyUserContainer: {
    width: '90%',
  },
  errorServer: {
    color: 'red',
    position: 'absolute',
    width: '100%',
    top: 105,
  },
  errorUnverified: {
    textAlign: 'center',
    color: 'red',
    width: '100%',
  },
  marginTop20: {
    marginTop: 20,
  },
  marginTop50: {
    marginTop: 50,
  },
  marginTop100: {
    marginTop: 100,
  },
  marginBottom20: {
    marginBottom: 20,
  },
  marginBottomXL: {
    marginBottom: 40,
  },
  buttonContainer: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
  },
  backButtonText: {
    color: '#3db384',
  },
  input: {
    marginRight: 0,
  },
  heading4: {
    fontSize: 16,
    marginVertical: 20,
    color: '#000',
  },
};
