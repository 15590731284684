import type { CSSProperties } from 'react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { mergeStyles } from '../../../helper/UtilityFunctions';
import UserService from '../../../services/User.service';
import { useAppDispatch } from '../../../store/hooks';
import { setAuth } from '../../../store/reducers';
import { Button, InputField, Label } from '../../atoms';
import { BaseHeader } from '../../organisms/BaseHeader/BaseHeader';
import { styles as inlineStyles } from './Login.style';
import styles from './Login.module.scss';

export function Login() {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  // Activate User's Account
  useEffect(() => {
    const token = searchParams.get('token');
    const paramEmail = searchParams.get('email');

    if (token && paramEmail) {
      UserService.activateAccount(token, paramEmail).then((res) => {
        if (res.error) {
          console.error(res);
        }
      });
    }
  }, []);

  const login = async () => {
    setError('');
    const loginData = { email, password };

    const response = await UserService.login(loginData);
    if (!response.error) {
      localStorage.setItem('JWT', response.data.token);
      localStorage.setItem('User', response.data.name);
      setEmail('');
      setPassword('');
      const authData = {
        isAuthenticated: true,
        isTermsAgree: response.data.isTermsAgree,
        name: response.data.name,
        isPrivacyPolicyAgree: response.data.isPrivacyPolicyAgree,
        isNewsletterAgree: response.data.isNewsletterAgree,
      };
      dispatch(setAuth(authData));

      navigate('/dashboard', { replace: true });
    }

    if (response.error) {
      if (response.message.response?.data?.error) {
        setError(response.message.response.data.error);
      } else {
        setError('error');
      }
      console.error(response.message);
    }
  };

  const errorStyle = inlineStyles.error as CSSProperties;
  const passwordStyles = mergeStyles([
    inlineStyles.inputContainer as CSSProperties,
    inlineStyles.marginBottomXL as CSSProperties,
  ]);

  return (
    <React.Fragment>
      <BaseHeader />
      <div>
        <div className={styles.container} style={inlineStyles.container}>
          <div style={inlineStyles.heading3}>CheckIn Login</div>
          <Label className={styles['first-label']} style={[{ marginBottom: 20 }]}>
            Melden Sie sich mit Ihrer E-Mail-Adresse und Ihrem Passwort an.
          </Label>

          <div className={styles['input-container']} style={inlineStyles.inputContainer as CSSProperties}>
            <div>
              <Label>E-Mail-Adresse</Label>
            </div>
            <div className={styles['input-field']}>
              <InputField
                onChange={setEmail}
                value={email}
                style={[inlineStyles.input]}
                isEmail={true}
                onKeyPress={(event) => (event.nativeEvent.key === 'Enter' ? login() : null)}
              />
            </div>
            {error === 'email' && <div style={errorStyle}>Die E-Mail-Adresse ist falsch.</div>}
            {error === 'error' && (
              <div style={errorStyle}>Es ist ein Fehler aufgetreten, versuchen Sie es bitte erneut.</div>
            )}
          </div>

          <div style={passwordStyles}>
            <div>
              <Label>Passwort</Label>
            </div>
            <div className={styles['input-field']}>
              <InputField
                onChange={setPassword}
                secure={true}
                value={password}
                style={inlineStyles.input}
                onKeyPress={(event) => (event.key === 'Enter' ? login() : null)}
              />
            </div>
            {error === 'password' && (
              <div style={inlineStyles.error as CSSProperties}>Das Passwort ist nicht korrekt.</div>
            )}
          </div>

          <div className={styles['button-container']} style={inlineStyles.buttonContainer as CSSProperties}>
            {error === 'active' && (
              <div className={styles['error-unverified']} style={inlineStyles.errorUnverified as CSSProperties}>
                Ihr Account ist nicht verifiziert. Bitte verifizieren Sie Ihren Account über den Link in der Ihnen
                gesendeten E-Mail.
              </div>
            )}
            <Button onClick={login}>Anmelden</Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
