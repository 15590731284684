import React from 'react';
import classNames from 'classnames/bind';
import { Button, Headline } from '../../atoms';
import { Header } from '../../organisms/Header/Header';
import classes from './Contact.module.scss';

const cx = classNames.bind(classes);

export function Contact() {
  return (
    <React.Fragment>
      <Header />
      <div className={cx('Contact')}>
        <Headline level={3}>Kontakt</Headline>
        <div className={cx('info-text')}>
          Teilen Sie uns gerne Ihre Anliegen über unser Kontaktformular auf unserer Webseite mit.
        </div>
        <div className={cx('button-container')}>
          <Button
            onClick={() => {
              window.open('https://www.nowomed.com/de/kontakt/anfrage/', '_blank');
            }}
          >
            Zum Kontaktformular
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}
