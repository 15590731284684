import React from 'react';

type ChevronProps = {
  size?: number;
  color?: string;
};

export function Chevron({ size = 16, color = '#000' }: ChevronProps): JSX.Element {
  return (
    <svg viewBox='0 0 11.77 7.3' width={size} height={size}>
      <path d='M1,1,5.89,5.89,10.77,1' fill='none' stroke={color} strokeLinecap='round' strokeWidth='1' />
    </svg>
  );
}
