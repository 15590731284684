import type { ChangeEvent } from 'react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatFileName } from '../../../helper/UtilityFunctions';
import UserService from '../../../services/User.service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectFiles, selectUserInformation, setFiles } from '../../../store/reducers';
import { Button, Headline, Loading, Modal } from '../../atoms';
import { DocumentBox } from '../../molecules/DocumentBox/DocumentBox';
import { Header } from '../../organisms';
import { DocumentPreview } from '../../organisms/DocumentPreview/DocumentPreview';
import classes from './Documents.module.scss';

export function Documents() {
  const files = useAppSelector(selectFiles);
  const userInfo = useAppSelector(selectUserInformation);
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const [deletableFiles, setDeletableFiles] = useState<string[]>([]);
  const [showUploadError, setShowUploadError] = useState(false);
  const [uploadFileName, setUploadFileName] = useState('');
  const [showExistingDocumentModalVisible, setShowExistingDocumentModalVisible] = useState(false);
  const [fileInputValue, setFileInputValue] = useState('');

  const [documentUri, setDocumentUri] = useState('');
  const [viewDocument, setViewDocument] = useState(false);
  const [documentType, setDocumentType] = useState('');
  const [documentName, setDocumentName] = useState('');

  const validFileExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'odt', 'xls', 'xlsx', 'ods'];

  const getUserFiles = async () => {
    setLoading(true);
    const fileResponse = await UserService.getFiles();
    if (fileResponse.error) {
      console.error(fileResponse);
    }
    if (!fileResponse.error) {
      dispatch(setFiles(fileResponse.data));
    }
    setLoading(false);
  };

  const onChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const validFile = validFileExtensions.some((extension) => {
      if (event.target.files![0].name.endsWith(extension)) {
        return true;
      }

      return false;
    });

    if (!validFile) {
      setUploadFileName(event.target.files![0].name);
      setShowUploadError(true);

      return;
    }

    setShowUploadError(false);

    if (event.target.files!.length) {
      /**
       * /^ => start of string
       * (?=[a-z0-9\_\-]) => look ahead in the string for a pattern containing only alphanumeric and _-
       * [a-z0-9\_\-]+ => any of these characters one or more times
       * [\\s]{0} => no spaces in the string
       * [\.]{1} => exactly one dot after the file name
       * [a-z]+ => only alphabetical after the dot for the file extension one or more times
       * $/ => end of string
       * /i => case insensitive
       *
       */
      const validFileRegex =
        // eslint-disable-next-line no-useless-escape
        /^(?=[a-z0-9\_\-])[a-z0-9\_\-]+[\\s]{0}[\.]{1}[a-z]+$/i;
      let renamedFile;
      if (!validFileRegex.test(event.target.files![0].name)) {
        // create a new File object because the name property is read only in web.
        renamedFile = new File([event.target.files![0]], formatFileName(event.target.files![0].name));
      }

      let isFound = false;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files.length; i++) {
        // check if there is a renamed file
        if (renamedFile) {
          if (files[i].filename === renamedFile.name) {
            isFound = true;
            break;
          }
        } else if (files[i].filename === event.target.files![0].name) {
          isFound = true;
          break;
        }
      }
      if (isFound) {
        setShowExistingDocumentModalVisible(true);
        setFileInputValue('');

        return;
      }
      setLoading(true);

      const formData = new FormData();
      const file = renamedFile || event.target.files![0];

      formData.append('file', file);

      await UserService.uploadFile(formData);

      const fileName = event.target.files![0].name;
      setDeletableFiles!([...deletableFiles!, renamedFile ? renamedFile.name : fileName]);
      getUserFiles();
    }
  };

  const openDocument = (uri: string, name: string) => {
    const fileExtensions = ['pdf', 'jpg', 'jpeg', 'png'];
    const fileExt = fileExtensions.find((ext) => name.endsWith(ext));

    if (fileExt) {
      setDocumentUri(uri);
      setViewDocument(true);
      setDocumentType(fileExt);
      setDocumentName(name);
    }
  };

  useEffect(() => {
    getUserFiles();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      {viewDocument && (
        <DocumentPreview uri={documentUri} type={documentType} name={documentName} setViewDocument={setViewDocument} />
      )}
      <Header />
      <Modal
        modalVisible={showUploadError}
        setModalVisible={setShowUploadError}
        cb={() => setShowUploadError(false)}
        disableSecondaryButton={true}
        primaryButtonText='Schließen'
      >
        <div>Fehler beim Upload von</div>
        <div>{uploadFileName}</div>
        <div>Erlaubte Dateiendungen:</div>
        <div>.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx</div>
      </Modal>
      <Modal
        modalVisible={showExistingDocumentModalVisible}
        setModalVisible={setShowExistingDocumentModalVisible}
        cb={() => setShowExistingDocumentModalVisible(false)}
        disableSecondaryButton={true}
        primaryButtonText='Schließen'
      >
        <Headline level={3}>Ungültiger Dokumentenname</Headline>
        <div>Sie haben bereits ein Dokument mit diesem Namen hochgeladen. Bitte benennen Sie das Dokument um.</div>
      </Modal>
      <div className={classes.Documents}>
        <div className={classes.container}>
          <div className={classes.description}>
            <Headline level={3}>Meine Dokumente</Headline>
            <p>Hier können Sie Ihre medizinischen Dokumente hochladen.</p>
            <p>Dies können zum Beispiel Arztbriefe, Medikamentenpläne, Entlassbriefe etc. sein.</p>
          </div>
          <div className={classes.documents}>
            {files.length > 0 ? (
              files.map((file, index) => {
                if (file.type === 'other') {
                  return (
                    <DocumentBox
                      filetype='other'
                      key={index}
                      id={file.id}
                      filename={file.filename}
                      thumb={file.thumbnail}
                      reload={getUserFiles}
                      openDocument={openDocument}
                      setLoading={setLoading}
                      canDelete={
                        deletableFiles.some((deletableFile) => file.filename === deletableFile) ||
                        !userInfo?.progress.isDataConfirm
                      }
                    />
                  );
                }

                return null;
              })
            ) : (
              <div className={classes.noDocumentsUpladed}>Sie haben noch keine Dokumente übermittelt.</div>
            )}
          </div>
          <div className={classes['upload-button-container']}>
            <input
              type='file'
              onChange={onChangeFile}
              id='file-upload'
              accept='.pdf, image/*, .doc, .docx, .odt, .xls, .xlsx, .ods'
              style={{ display: 'none' }}
              value={fileInputValue}
            />
            <label htmlFor='file-upload'>
              <Button>Dokument hinzufügen</Button>
            </label>
          </div>
          <div className={classes['back-to-dashboard-container']}>
            <Link className={classes['back-to-dashboard-btn']} to='/dashboard'>
              <div>Zurück zum Dashboard</div>
            </Link>
          </div>
          {files.length > 0 && (
            <div className={classes.prescriptions}>
              {files.some((file) => file.type === 'prescription') && <Headline level={3}>Meine Rezepte</Headline>}
              {files.map((file, index) => {
                if (file.type !== 'other') {
                  return (
                    <DocumentBox
                      filetype='prescription'
                      fileCreated={file.created}
                      key={index}
                      id={file.id}
                      filename={file.filename}
                      thumb={file.thumbnail}
                      reload={getUserFiles}
                      openDocument={openDocument}
                      setLoading={setLoading}
                      canDelete={
                        deletableFiles.some((deletableFile) => file.filename === deletableFile) ||
                        !userInfo?.progress.isDataConfirm
                      }
                    />
                  );
                }

                return null;
              })}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
