import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Headline } from '../../atoms';
import { Header } from '../../organisms/Header/Header';
import classes from './UnavailableAppointment.module.scss';

export function UnavailableAppointment() {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Header />
      <div className={classes.UnavailableAppointment}>
        <Headline level={3}>Momentan sind keine Termine verfügbar</Headline>
        <div className={classes['info-text']}>
          Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal oder kontaktieren Sie unseren Support.
        </div>
        <div className={classes['button-container']}>
          <Button className={classes.button} onClick={() => navigate('/contact')}>
            Support kontaktieren
          </Button>
          <Button className={classes.button} onClick={() => navigate('/dashboard')}>
            Zum Dashboard
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}
