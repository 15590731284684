import type { NavigationIndex, PayloadKeys, ReducerAction } from '../types/navigationIndex.types';

export const initialState: NavigationIndex = {
  firstPresentationIndex: 1,
  secondaryDiagnosisIndex: 1,
  medicationIndex: 1,
  furtherQuestionsIndex: 1,
  onlineAppointmentIndex: 1,
  offlineFirstAppointmentIndex: 1,
  onlineFirstAppointmentIndex: 1,
  followUpPrescriptionsIndex: 1,
};

const resetState = () => ({
  firstPresentationIndex: 1,
  secondaryDiagnosisIndex: 1,
  medicationIndex: 1,
  furtherQuestionsIndex: 1,
  onlineAppointmentIndex: 1,
  offlineFirstAppointmentIndex: 1,
  onlineFirstAppointmentIndex: 1,
  followUpPrescriptionsIndex: 1,
});

export const reactReducer = (state: NavigationIndex, action: ReducerAction): NavigationIndex => {
  const { type, payload } = action;
  const keys: PayloadKeys[] = Object.keys(payload) as PayloadKeys[];
  let newState: NavigationIndex = JSON.parse(JSON.stringify(state));

  switch (type) {
    case '[UPDATE]':
      // eslint-disable-next-line no-return-assign
      keys.forEach((key) => (newState[key] = payload[key] as number));
      break;
    case '[RESET]':
      newState = resetState();
      break;
    default:
      break;
  }

  return newState;
};
