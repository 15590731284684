// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { format, setDefaultOptions } from 'date-fns';
import { de } from 'date-fns/locale';
import type { ReducerAction } from '../../../../types/navigationIndex.types';
import type { DoctorSlot } from '../../../../types/user.types';
import { Button, Icon } from '../../../atoms';
import classes from './StepTwoOnlineFirstAppointment.module.scss';

const cx = classNames.bind(classes);

type StepTwoOnlineFirstAppointmentProps = {
  setCurrentPage: React.Dispatch<ReducerAction>;
  onlineFirstAppointmentInfo: any;
  setOnlineFirstAppointmentInfo: (value: any) => void;
};
const stepsOnlineAppointmentClasses = cx({ StepTwoOnlineFirstAppointment: true, StepsContainer: true });
const infoTextClasses = cx({ InfoText: true });
const selectedDateContainerClasses = cx({ SelectedDateContainer: true });
const selectedDateClasses = cx({ SelectedDate: true });
const slotsContainerClasses = cx({ SlotsContainer: true });
const slotClasses = cx({ Slot: true });
const slotSelectedClasses = cx({ Slot: true, Selected: true });
const slotTextClasses = cx({ SlotText: true });
const slotDoctorClasses = cx({ SlotText: true, SlotDoctor: true });
const slotButtonClasses = cx({ SlotButton: true });

export function StepTwoOnlineFirstAppointment({
  setCurrentPage,
  onlineFirstAppointmentInfo,
  setOnlineFirstAppointmentInfo,
}: StepTwoOnlineFirstAppointmentProps) {
  // set locale for date-fns
  setDefaultOptions({ locale: de });

  const [selectedSlot, setSelectedSlot] = useState<DoctorSlot | undefined>();

  const getDoctorName = (id: string) => {
    let name = '';
    let title = '';
    onlineFirstAppointmentInfo.doctors.map((item: any) => {
      if (item.id === parseInt(id, 10)) {
        title = item.title ? (item.title === 'professor' ? 'Prof.' : 'Dr.') : '';
        name = `${title} ${item.firstname} ${item.lastname}`;
      }

      return item;
    });

    return name;
  };

  const getDoctorStamp = (id: string) => {
    const doctor = onlineFirstAppointmentInfo.doctors.find((item: any) => {
      if (item.id === parseInt(id, 10)) {
        return true;
      }

      return false;
    });

    if (doctor) {
      return doctor.stamp;
    }

    return '';
  };

  const next = () => {
    if (selectedSlot) {
      setOnlineFirstAppointmentInfo({
        ...onlineFirstAppointmentInfo,
        doctorName: getDoctorName(selectedSlot.doctorId),
        doctorId: selectedSlot.doctorId,
        selectedSlot,
        doctorStamp: getDoctorStamp(selectedSlot.doctorId),
      });
      const reducerType = {
        type: '[UPDATE]',
        payload: {
          onlineFirstAppointmentIndex: 3,
        },
      };
      setCurrentPage(reducerType);
    }
  };

  function sortDates(array: Array<DoctorSlot>) {
    return array.sort((a: DoctorSlot, b: DoctorSlot) => {
      if (new Date(a.start) < new Date(b.start)) {
        return -1;
      }
      if (new Date(a.start) > new Date(b.start)) {
        return 1;
      }

      return 0;
    });
  }

  const SlotInterval = ({ slot }: { slot: DoctorSlot }) => {
    return (
      <div className={classes.SlotInterval}>
        {new Date(slot.start).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        })}{' '}
        -{' '}
        {new Date(slot.end).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        })}{' '}
        Uhr
      </div>
    );
  };

  return (
    <div className={stepsOnlineAppointmentClasses}>
      <Icon icon='calendarWithDays' size={80} color={'#3db384'} />
      <div className={infoTextClasses}>Wann soll Ihr Online Erstgespräch stattfinden?</div>

      <div className={selectedDateContainerClasses}>
        <div className={selectedDateClasses}>
          Verfügbare Termine am <b>{format(onlineFirstAppointmentInfo.selectedDate, 'PPP')}</b>
        </div>
        <a
          onClick={() => {
            const reducerType = {
              type: '[UPDATE]',
              payload: { onlineFirstAppointmentIndex: 1 },
            };
            setCurrentPage(reducerType);
          }}
        >
          Datum ändern
        </a>
      </div>
      <div style={{ width: '100%' }}>
        <h4>Wählen Sie bitte die Uhrzeit</h4>
      </div>
      <div className={slotsContainerClasses}>
        {sortDates(onlineFirstAppointmentInfo.doctorSlots).map((slot: DoctorSlot, i: number) => (
          <div
            className={selectedSlot === slot ? slotSelectedClasses : slotClasses}
            key={i}
            onClick={() => setSelectedSlot(slot)}
          >
            <div>
              <div className={classes.selected}>
                <div className={slotTextClasses}>
                  <SlotInterval slot={slot} />
                </div>
                <div className={slotDoctorClasses}>
                  <Icon
                    icon='user'
                    size={16}
                    color={
                      selectedSlot && slot.start === selectedSlot.start && slot.doctorId === selectedSlot.doctorId
                        ? '#ffffff'
                        : '#000000'
                    }
                  />
                  Arzt: {getDoctorName(slot.doctorId)}
                </div>
              </div>
              {selectedSlot && slot.start === selectedSlot.start && slot.doctorId === selectedSlot.doctorId && (
                <Button onClick={next} className={slotButtonClasses} inlineBlock={true}>
                  Termin buchen
                </Button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
