import React from 'react';

type WaitingProps = {
  size?: number;
  color?: string;
};

export function Waiting({ size = 80, color = '#3db384' }: WaitingProps): JSX.Element {
  return (
    <svg width={size} height={size} viewBox='0 0 71 71'>
      <circle cx='12.6' cy='35.5' r='6.2' fill={color} />
      <circle cx='35.6' cy='35.5' r='8.5' fill={color} />
      <circle cx='58.6' cy='35.5' r='6.2' fill={color} />
    </svg>
  );
}
