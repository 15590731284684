import React from 'react';

type ChronicPainsProps = {
  active: boolean;
};

export function ChronicPains({ active }: ChronicPainsProps): JSX.Element {
  return (
    <svg viewBox='0 0 49.93 78.3'>
      <g fill='none'>
        <path
          strokeWidth={1.13}
          stroke={active ? '#fff' : '#3db384'}
          d='M11.05 26.49a4.1 4.1 0 103.75.13M29.77 55.3a4.1 4.1 0 105.46 2 4 4 0 00-1.72-1.84'
        />
        <path
          strokeWidth={1.13}
          stroke={active ? '#fff' : '#3db384'}
          d='M12.81 31.15a.95.95 0 10-.95-.94.94.94 0 00.95.94M31.51 59.97a.95.95 0 100-1.89.95.95 0 000 1.89M30.12 16.7a9.8 9.8 0 004.52-8.27V.58M25.04 41.84v4.87M25.04 32.15v4.87M25.04 22.46v4.87M25.04 12.76v4.87M25.04 51.54v4.87M25.04 61.23v4.87'
        />
        <path
          strokeWidth={1.13}
          stroke={active ? '#fff' : '#3db384'}
          d='M19.77 13.58v5.26s-.92 2.9-5.92 4.46-4.6-.27-8.29 2-4.73 6.11-4.21 9.86 1.32 17 1.32 17l-2.11 19M30.15 13.58v5.26s.9 2.9 5.9 4.46 4.6-.27 8.29 2 4.73 6.12 4.21 9.87-1.32 17-1.32 17l2.11 19'
        />
        <path
          strokeWidth={1.13}
          stroke={active ? '#fff' : '#3db384'}
          d='M8.33 35.3a61.49 61.49 0 001.84 12.37c1.71 6.71 2.1 6.32 1.31 10S8.72 67.14 8.19 70.3a68.73 68.73 0 00-.65 7.44M41.72 35.3a61.49 61.49 0 01-1.84 12.37c-1.71 6.71-2.11 6.32-1.32 10s2.77 9.47 3.29 12.63a66.31 66.31 0 01.66 7.44M15.05.57v7.86a9.79 9.79 0 004.71 8.38'
        />
      </g>
    </svg>
  );
}
