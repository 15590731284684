import React from 'react';

type CheckedProps = {
  size?: number;
  color?: string;
  background?: boolean;
};

export function Checked({ size = 20, color = '#3db384', background = true }: CheckedProps): JSX.Element {
  return (
    <svg width={size} height={size} viewBox='0 0 32 32'>
      {background && <rect width='32' height='32' rx='4' fill='#efefee' />}
      <path
        d='M14.12,23.52l-7-6.68a1,1,0,0,1,0-1.34,1,1,0,0,1,1.35,0l5.51,5.25L23.1,9.11A.94.94,0,0,1,24.43,9a1,1,0,0,1,.16,1.34Z'
        fill={color}
      />
    </svg>
  );
}
