import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { TERM } from '../../../enums';
import UserService from '../../../services/User.service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectAuth, selectTerms, setAuth } from '../../../store/reducers';
import { Button, Headline, Modal } from '../../atoms';
import { Checkbox } from '../../molecules/Checkbox/Checkbox';
import { UserHeader } from '../../organisms/UserHeader/UserHeader';
import classes from './ConfirmTerms.module.scss';

const cx = classNames.bind(classes);

export function ConfirmTerms() {
  const navigate = useNavigate();

  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  const terms = useAppSelector(selectTerms);

  const [agbs, setAgbs] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalHeadline, setModalHeadline] = useState('');

  useEffect(() => {
    setAgbs(auth.isTermsAgree || false);
    setPrivacyPolicy(auth.isPrivacyPolicyAgree || false);
    setNewsletter(auth.isNewsletterAgree || false);

    if (auth.isTermsAgree && auth.isPrivacyPolicyAgree) {
      navigate('/dashboard');
    }
  }, []);

  const openModal = (term: TERM) => {
    const content = terms.find((t) => t.type === term)?.termText || '';
    const headline = terms.find((t) => t.type === term)?.headline || '';
    setModalContent(content);
    setModalHeadline(headline);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalContent('');
    setModalHeadline('');
    setModalVisible(false);
  };

  const confirmTerms = async () => {
    const res = await UserService.confirmTerms({
      terms: agbs,
      privacyPolicy,
      newsletter,
    });
    if (res.error) {
      console.error(res);

      return;
    }

    dispatch(
      setAuth({
        ...auth,
        isTermsAgree: agbs,
        isPrivacyPolicyAgree: privacyPolicy,
        isNewsletterAgree: newsletter,
      })
    );

    if (agbs) {
      localStorage.setItem('TermsAgree', 'true');
    }
    if (privacyPolicy) {
      localStorage.setItem('PrivacyPolicyAgree', 'true');
    }
    if (newsletter) {
      localStorage.setItem('NewsletterAgree', 'true');
    }
    if (agbs && privacyPolicy) {
      navigate('/dashboard');
    }
  };

  return (
    <React.Fragment>
      <div className={cx('TopBar')}>
        <UserHeader doNotShowProfileLink={true} />
      </div>
      <Modal
        modalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        cb={closeModal}
        disableSecondaryButton={true}
        primaryButtonText='Schließen'
      >
        <Headline className={cx('ModalHeadline')} level={2}>
          {modalHeadline}
        </Headline>
        <div className={cx('ModalText')} dangerouslySetInnerHTML={{ __html: modalContent }}></div>
      </Modal>
      <div className={cx('ConfirmTerms')}>
        <Headline level={3}>Bedingungen akzeptieren</Headline>
        <div className={cx('InfoText')}>
          Unsere allgemeinen Geschäftsbedingungen/Angaben zum Datenschutz haben sich geändert. Bitte akzeptieren Sie die
          neuen Bedingungen.
        </div>
        {!auth.isTermsAgree && (
          <div className={cx('CheckboxAndText')}>
            <Checkbox checked={agbs} label='' onClick={() => setAgbs(!agbs)} />
            <div className={cx('Text')}>
              Ich stimme den{' '}
              <div onClick={() => openModal(TERM.TERMS)} className={cx('ModalOpener')}>
                allgemeinen Geschäftsbedingungen
              </div>{' '}
              zu.
            </div>
          </div>
        )}
        {!auth.isPrivacyPolicyAgree && (
          <div className={cx('CheckboxAndText')}>
            <Checkbox checked={privacyPolicy} label='' onClick={() => setPrivacyPolicy(!privacyPolicy)} />
            <div className={cx('Text')}>
              Ich stimme den{' '}
              <div onClick={() => openModal(TERM.PRIVACY_POLICY)} className={cx('ModalOpener')}>
                Angaben zum Datenschutz
              </div>{' '}
              zu.
            </div>
          </div>
        )}
        {!auth.isNewsletterAgree && (
          <div className={cx('CheckboxAndText')}>
            <Checkbox checked={newsletter} label='' onClick={() => setNewsletter(!newsletter)} />
            <div className={cx('Text')}>Ich möchte über Neuigkeiten per Newsletter informiert werden.</div>
          </div>
        )}
        <div className={cx('ButtonContainer')}>
          <Button onClick={confirmTerms} disabled={!agbs || !privacyPolicy}>
            Weiter
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}
