import type { CSSProperties } from 'react';

export const styles = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  scale: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexGrow: 0,
    marginBottom: 10,
  },
  slider: {
    flex: 1,
    flexGrow: 0,
  },
  error: {
    borderColor: '#ff0000',
    borderWidth: 1,
  },
} as { [key: string]: CSSProperties };
