import React from 'react';

export function Pharmacy({ size = 20, color = '#3db384' }): JSX.Element {
  return (
    <svg width={size} height={size} viewBox='0 0 79 77'>
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fill={color} fillRule='nonzero'>
          <path d='M40.356.47l.128.117 37.931 38a2 2 0 01-2.703 2.943l-.127-.117-6.085-6.096V75a2 2 0 01-1.85 1.995L67.5 77h-56a2 2 0 01-1.995-1.85L9.5 75V35.175l-6.068 6.222a2 2 0 01-2.7.15l-.129-.115a2 2 0 01-.15-2.7l.115-.129 37.07-38A2 2 0 0140.355.47zm-1.271 4.377L13.5 31.074V73h52V31.31L39.085 4.847z' />
          <path d='M39 27a3 3 0 012.995 2.824L42 30l-.001 11.5H53.5a3 3 0 01.176 5.995l-.176.005H41.999L42 59a3 3 0 01-5.995.176L36 59l-.001-11.5H24.5a3 3 0 01-.176-5.995l.176-.005h11.499L36 30a3 3 0 013-3z' />
        </g>
      </g>
    </svg>
  );
}
