import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { isInObject } from '../../../../helper/UtilityFunctions';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectPrescriptionInfo, selectUserInformation, setPrescriptionInfo } from '../../../../store/reducers';
import type { ReducerAction } from '../../../../types/navigationIndex.types';
import { Button, Headline, RadioButton } from '../../../atoms';
import classes from './Pharmacy.module.scss';

const cx = classNames.bind(classes);

type PharmacyProps = {
  setCurrentPage: React.Dispatch<ReducerAction>;
};

export function Pharmacy({ setCurrentPage }: PharmacyProps) {
  const navigate = useNavigate();

  const userInformation = useAppSelector(selectUserInformation);
  const prescriptionInfo = useAppSelector(selectPrescriptionInfo);
  const [prescriptionTemp, setPrescriptionTemp] = useState(prescriptionInfo);
  const dispatch = useAppDispatch();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const error = !isInObject(prescriptionTemp, 'isSamePharmacy');
    if (error) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [prescriptionTemp]);

  const choosePharmacy = () => {
    dispatch(setPrescriptionInfo(prescriptionTemp));

    navigate('/pharmacy-list');
  };

  const handleBackButton = () => {
    const reducerType = {
      type: '[UPDATE]',
      payload: {
        followUpPrescriptionsIndex: 5,
      },
    };
    setCurrentPage(reducerType);
  };

  const next = () => {
    dispatch(setPrescriptionInfo(prescriptionTemp));

    const reducerType = {
      type: '[UPDATE]',
      payload: {
        followUpPrescriptionsIndex: 7,
      },
    };
    setCurrentPage(reducerType);
  };

  return (
    <div className={cx('Pharmacy')}>
      <Headline level={3}>Apotheke</Headline>
      <div className={cx('pharmacy-info')}>
        <div className={cx('label')}>Sie haben folgende Apotheke gewählt:</div>
        <div className={cx('pharmacy-name')}>
          {userInformation?.patientPharmacyRelation?.name || 'Keine Apotheke gewählt'}
        </div>
      </div>
      <div className={cx('question-and-radio')}>
        <div className={cx('question')}>Ist diese weiterhin aktuell?</div>
        <div className={cx('choices')}>
          <RadioButton
            extraClasses={cx('radio-button')}
            checked={prescriptionTemp.isSamePharmacy === true}
            value='yes'
            name='is-same-pharmacy'
            id='is-same-pharmacy-yes'
            onClick={() => {
              setPrescriptionTemp({
                ...prescriptionTemp,
                isSamePharmacy: true,
              });
            }}
          >
            Ja
          </RadioButton>
          <RadioButton
            extraClasses={cx('radio-button')}
            checked={prescriptionTemp.isSamePharmacy === false}
            value='no'
            name='is-same-pharmacy'
            id='is-same-pharmacy-no'
            onClick={() => {
              setPrescriptionTemp({
                ...prescriptionTemp,
                isSamePharmacy: false,
              });
            }}
          >
            Nein
          </RadioButton>
        </div>
      </div>
      <div className={cx('button-container')}>
        {prescriptionTemp?.isSamePharmacy !== false && (
          <React.Fragment>
            <Button secondary className={cx('nav-button')} onClick={handleBackButton}>
              Zurück
            </Button>
            <Button className={cx('nav-button')} disabled={isButtonDisabled} onClick={next}>
              Weiter
            </Button>
          </React.Fragment>
        )}
        {prescriptionTemp?.isSamePharmacy === false && (
          <div className={cx('new-pharmacy')}>
            <div className={cx('text')}>Bitte wählen Sie Ihre neue Apotheke.</div>
            <Button className={cx('nav-button')} onClick={choosePharmacy}>
              Apotheke wählen
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
