import React from 'react';
import classNames from 'classnames/bind';
import classes from './TreatmentContract.module.scss';

const cx = classNames.bind(classes);

type TreatmentContractProps = {
  doctorStamp: string;
};

export function TreatmentContract(props: TreatmentContractProps) {
  let patient: any = {};

  const patientInfo = sessionStorage.getItem('patient');
  if (patientInfo === null) {
    // eslint-disable-next-line no-alert
    alert('Error - no patientInfo in sessionStorage');
  }
  if (patientInfo !== null) {
    patient = JSON.parse(patientInfo);
  }
  const userBirthday = new Date(patient.birthday).toLocaleDateString('de-DE', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });

  const doctorStampFormatted = props.doctorStamp.replace(/\n/g, ', ');

  const addressSet = patient?.address && patient.address.length > 0;

  const firstAddress = addressSet ? patient.address[0] : null;

  let patientStreet = '';
  let patientHouseNumber = '';
  let patientPostcode = '';
  let patientCity = '';

  if (firstAddress) {
    patientStreet = firstAddress.street;
    patientHouseNumber = firstAddress.houseNumber;
    patientPostcode = firstAddress.postcode;
    patientCity = firstAddress.city;
  }

  return (
    <React.Fragment>
      <React.Fragment>
        <h1>Behandlungsvertrag</h1>
        <div>
          <strong>Zwischen</strong>
        </div>
        <p>
          {patient.firstname} {patient.lastname}
        </p>
        <p>geb. am {userBirthday}</p>
        <div>
          <strong>wohnhaft in</strong>
        </div>
        <p>
          {patientStreet} {patientHouseNumber}
        </p>
        <p>
          {patientPostcode} {patientCity}
        </p>
        <div>
          <strong>und</strong>
        </div>
        <div className={cx('DoctorStamp')}>{props.doctorStamp}</div>
      </React.Fragment>
      <React.Fragment>
        <h2>über die Erbringung medizinischer Leistungen im Rahmen eines Behandlungsvertrages (§ 630a BGB)</h2>

        <h3 className={cx('heading')}>PRÄAMBEL</h3>
        <p>
          I. Der PATIENT wünscht eine medizinische Beratung und Behandlung des ARZTES, bei Vorliegen der notwendigen
          Voraussetzungen ggf. auch unter Nutzung von (digitalen) Kommunikationsmitteln (wie Videosprechstunde,
          Telefonanruf, E-Mail, etc.).
        </p>
        <p>
          II. Der ARZT ist ausschließlich privatärztlich tätig und erbringt keine Leistungen im Rahmen der
          vertragsärztlichen Versorgung (gesetzliche Krankenkasse).
        </p>
        <p>
          III. Der ARZT ist grundsätzlich bereit, seine ärztlichen Beratungs- und Behandlungsdienstleistungen auch über
          Kommunikationsmittel zu erbringen, wenn die notwendigen Voraussetzungen gem. § 7 Abs. 4 der
          (Muster-)Berufsordnung für die in Deutschland tätigen Ärztinnen und Ärzte (“MBO-Ä”) und der Berufsordnung
          seiner Bezirksärztekammer erfüllt sind.
        </p>
        <p>Dies vorausgeschickt, vereinbaren die PARTEIEN das Folgende:</p>

        <h3 className={cx('heading')}>1 Vertragsgegenstand</h3>
        <p>
          1.1 Der ARZT erbringt im Rahmen dieses Behandlungsvertrages gegenüber dem PATIENTEN privatärztliche
          medizinische Leistungen (wie beispielsweise Aufklärung, Befunderhebung, Beratung, Behandlung, Erstellen eines
          Behandlungs- und/oder Medikamentenplans) entweder im persönlichen Kontakt oder - soweit die Voraussetzungen
          des § 7 Abs. 4 MBO vorliegen – auch über Kommunikationsmittel (wie Videosprechstunde, Telefon, E-Mails, etc.)
          ohne gleichzeitige körperliche Anwesenheit (nachfolgend „FERNBEHANDLUNG“ genannt).
        </p>
        <p>
          1.2 Über das medizinisch notwendige Maß hinausgehende Leistungen oder Leistungen auf Verlangen des Patienten
          werden im Rahmen dieses Behandlungsvertrages nicht erbracht.
        </p>
        <p>
          1.3 Zur Klarstellung:
          <ol type='a'>
            <li>
              Der PATIENT hat keinen Anspruch auf Durchführung der privatärztlichen medizinischen Dienstleistungen des
              Arztes im Rahmen einer FERNBEHANDLUNG, dies liegt allein im Ermessen des ARZTES. Insbesondere wenn der
              ARZT zu dem Ergebnis gelangt, dass eine FERNBEHANDLUNG nicht ärztlich vertretbar ist oder die
              erforderliche ärztliche Sorgfalt insbesondere durch die Art und Weise der Befunderhebung, Beratung,
              Behandlung sowie Dokumentation nicht gewahrt wird, ist eine FERNBEHANDLUNG nicht möglich. In einem solchen
              Fall wird der PATIENT entsprechend informiert
            </li>
            <li>
              Der Behandlungsvertrag stellt einen besonderen Dienstvertrag dar. Der PATIENT hat daher die erbrachten
              Leistungen zu vergüten, ein besonderer Erfolg oder eine bestimmte, vom PATIENTEN gewünschte aber vom ARZT
              nicht als medizinisch notwendig erachtete Leistung oder Therapie wird nicht geschuldet. Dem ARZT obliegt
              die Verantwortung für die medizinische Beratung und Behandlung und damit auch die Behandlungshoheit.
            </li>
          </ol>
        </p>
        <p>
          1.4 Die Behandlung des PATIENTEN erfolgt nach den zum Zeitpunkt der Behandlung bestehenden, allgemein
          anerkannten fachlichen Standards. Mit ausdrücklicher oder konkludenter Zustimmung des Patienten ist es dem
          ARZT gestattet, Leistungen im Rahmen der gesetzlichen Bestimmungen auch von befähigtem Hilfspersonal
          durchführen zu lassen, die seiner Aufsicht und Weisung unterstehen.
        </p>
        <p>
          1.5 Der PATIENT ist verpflichtet, die vom ARZT erbrachten medizinischen Leistungen nach gestellter Rechnung zu
          vergüten (siehe Ziffer 4).
        </p>

        <h3 className={cx('heading')}>2 Mitwirkungspflichten, Information</h3>
        <p>
          2.1 Der PATIENT wird alle für die medizinische Beratung und Behandlung durch den ARZT notwendigen
          Informationen wahrheitsgemäß und vollständig mitteilen.
        </p>
        <p>
          2.2 Wird die medizinische Beratung und Behandlung durch den ARZT bei Vorliegen der entsprechenden
          Voraussetzung durch FERNBEHANDLUNG erbracht, so hat der PATIENT selbst dafür Sorge zu tragen, dass auf seiner
          Seite alle technischen Voraussetzungen erfüllt sind, damit eine Beratung und Behandlung durch FERNBEHANDLUNG –
          wie im Rahmen dieses Behandlungsvertrages vereinbart – möglich ist.
        </p>
        <p>
          2.3 Der ARZT ist verpflichtet, dem PATIENTEN in verständlicher Weise zu Beginn der Behandlung und, soweit
          erforderlich, in deren Verlauf sämtliche für die Behandlung wesentlichen Umstände zu erläutern, insbesondere
          die voraussichtliche gesundheitliche Entwicklung, die Therapie und die zu und nach der Therapie zu
          ergreifenden Maßnahmen.
        </p>
        <p>
          2.4 Der ARZT ist zudem verpflichtet, den PATIENTEN vor der Behandlung im Rahmen einer FERNBEHANDLUNG über die
          Besonderheiten der FERNBEHANDLUNG aufzuklären.
        </p>

        <h3 className={cx('heading')}>3 Terminvereinbarung und -absagen</h3>
        <p>
          3.1 Termine zur Beratung und Behandlung können persönlich in unserer Praxis, Telefonisch oder Online gemacht
          werden.
        </p>
        <p>
          3.2 Zur Klarstellung im Falle der Terminbuchung über www.nowomed.com/de Dem PATIENT ist bewusst, dass die
          Plattform ein vom ARZT unabhängiges Unternehmen ist und die Nutzung der Plattform unabhängig von diesem
          Behandlungsvertrag erfolgt und der ARZT für die Inhalte der Plattform und ihre ordnungsgemäße Funktion keine
          Haftung übernimmt. Bei der Eingabe der Daten des PATIENTEN über die Plattform sind die Datenschutzerklärung
          der Plattform und die entsprechende Einwilligung des PATIENTEN in die Datenverarbeitung zu beachten, diese
          erfolgen unabhängig von diesem Behandlungsvertrag.
        </p>
        <p>
          3.3 Kann der PATIENT einen gebuchten Termin nicht wahrnehmen, so ist dieser unverzüglich telefonisch über die
          Telefonnummer zu finden auf www.nowomed.de, per E-Mail an support@nowomed.de oder über sein Nutzerkonto bei
          der Plattform abzusagen.
        </p>
        <p>
          3.4 Einen von Ihnen gebuchten Termin können Sie bis zu 24 Stunden vor dem geplanten Termin kostenfrei
          umbuchen. Dazu kontaktieren Sie bitte den Kundensupport von nowomed unter www.nowomed.de, oder per E-Mail an
          support@nowomed.de
          <ol type='a'>
            <li>
              Sollten Sie den Termin innerhalb von 24 Stunden vor dem geplanten Termin absagen, wird von nowohealth ein
              Ausfallhonorar in Höhe des Ärztehonorars erhoben. Die Kosten für Termine am Standort oder Online-Termine
              sind auf der Internetseite unter: https://www.nowomed.com/de/ ersichtlich.
            </li>
            <li>
              Das Ausfallhonorar wird von nowohealth ebenso erhoben, wenn Sie einen Termin nicht stornieren aber zu dem
              vereinbarten Termin nicht erscheinen bzw. nicht erreichbar sind.
            </li>
            <li>
              Nowohealth erlässt Ihnen das Ausfallhonorar nur, wenn Sie mittels ärztlichem oder anderweitigem
              behördlichem Zeugnis gegenüber nowomed nachweisen, dass ihnen die Wahrnehmung des Termins nicht möglich
              war.
            </li>
          </ol>
        </p>

        <h3 className={cx('heading')}>4 Abrechnung und Vergütung</h3>
        <p>
          4.1 Die Abrechnung der privatärztlichen Leistungen aufgrund dieses Behandlungsvertrages erfolgt auf Basis der
          Gebührenordnung für Ärzte („GOÄ“) und etwaiger Analogziffern. Erhobene Steigerungssätze (Faktor) begründen
          sich aus inhaltlichem oder zeitlichem Mehraufwand der betreffenden Leistung gegenüber dem einfachen Satz und
          werden auf der Rechnung aufgeführt. Eine Überschreitung des 2,3-fachen Gebührensatzes wird in der Rechnung
          entsprechend begründet.
        </p>
        <p>4.2 Der Patient wird bei der Terminvereinbarung über die voraussichtlichen Kosten informiert.</p>
        <p>
          4.3 Um Ihren Termin verbindlich zu reservieren, ist eine Reservierungsgebühr in Höhe der voraussichtlichen
          Behandlungskosten nach der Gebührenordnung für Ärzte zu entrichten. Über die Zahlung dieser
          Reservierungsgebühr erhalten Sie eine Zahlungsbestätigung. Wenn Ihre ärztliche Konsultation stattgefunden hat,
          erhalten Sie eine Rechnung über die erbrachte Leistung nach der Gebührenordnung für Ärzte, die mit der bereits
          gezahlten Reservierungsgebühr verrechnet wird.
        </p>
        <p>
          4.4 Die Rechnungstellung und den Einzug seiner Privatliquidation lässt der ARZT über die Ärztliche
          Verrechnungsstelle Büdingen GmbH Ärztliche Gemeinschaftseinrichtung, Gymnasiumstraße 18-20, 63654 Büdingen
          („ABRECHNUNGSSTELLE“) vornehmen, an die er seine Forderung für die medizinische Behandlung und Beratung nach
          diesem Behandlungsvertrag abtritt. Die ABRECHNUNGSSTELLE erhält dazu von Seiten des ARZTES sämtliche zu diesem
          Zweck benötigten Daten über den PATIENTEN sowie ggf. die für die Rechnung notwendigen Daten der Behandlung und
          Beratung. Der Patient erklärt durch Akzeptieren
          <ul>
            <li>
              der Anlage 1 seine Kenntnis, dass er die privatärztliche Behandlung nach diesem Behandlungsvertrag
              wünscht, die Kosten aus diesem Behandlungsvertrags selbst zu tragen hat und er mit der Abtretung der
              Forderung aus diesem Vertrag an die ABRECHNUNGSSTELLE einverstanden ist und die Kosten daher durch die
              ABRECHNUNGSSTELLE abgerechnet und eingezogen werden.
            </li>
            <li>
              der Anlage 2 sein jederzeit widerrufliches Einverständnis der Erhebung und Verarbeitung seiner
              personenbezogenen Daten (einschließlich Gesundheitsdaten) wie dort dargestellt, insbesondere die
              Übermittlung an die ABRECHNUNGSSTELLE soweit diese für die Rechnungsstellung und Einziehung der Forderung
              aus diesem Behandlungsvertrag notwendig sind und die entsprechende Entbindung des ARZTES von der
              Schweigepflicht, wie in am Ende des Behandlungsvertrag spezifiziert dargestellt.
            </li>
          </ul>
        </p>
        <p>
          4.5 Der PATIENT ist auch darüber informiert, dass die Krankenversicherung/Beihilfestelle die Erstattung des
          Rechnungsbetrages ganz oder teilweise ablehnen kann. Eine Übernahme der Kosten durch eine Erstattungsstelle
          ist nicht sichergestellt.
        </p>
        <p>
          4.6 <strong>Wenn der PATIENT gesetzlich-versichert ist:</strong> Der PATIENT wird darauf hingewiesen, dass
          Leistungen nach diesem Behandlungsvertrag [ggf.] Leistungen darstellen [können], die bei der Inanspruchnahme
          von Vertragsärzten und bei Vorliegen der entsprechenden Voraussetzungen von der gesetzlichen Krankenkasse
          übernommen werden könnten. Dennoch wünscht der PATIENT die Inanspruchnahme der Leistungen nach diesem
          Behandlungsvertrag als private Leistung und übernimmt sämtliche Kosten persönlich.
        </p>

        <h3 className={cx('heading')}>5 Video-Diensteanbieter / Datenschutz</h3>
        <p>
          5.1 Soweit der ARZT seine medizinischen Beratungs- und Behandlungsleistungen im Rahmen einer FERNBEHANDLUNG
          erbringt, benutzt der ARZT für die FERNBEHANDLUNG die Software des Video-Diensteanbieters eSprechstunde.
        </p>
        <p>
          5.2 Die Verarbeitung der personenbezogenen Daten des PATIENTEN erfolgt unter Beachtung der gesetzlichen
          Regelungen, insbesondere der Bestimmungen über den Datenschutz und der ärztlichen Schweigepflicht. Die
          erforderlichen Informationen, Hinweise und Einwilligungen sind Gegenstand der Anlage 2 .
        </p>
        <h3 className={cx('heading')}>6 Haftung</h3>
        <p>Die Haftung des ARZTES richtet sich nach den gesetzlichen Vorschriften.</p>

        <h3 className={cx('heading')}>7 Dauer und Kündigung</h3>
        <p>7.1 Der Behandlungsvertrag tritt mit Annahme des PATIENTEN in Kraft.</p>
        <p>
          7.2 Der Behandlungsvertrag kann jederzeit in Textform an die E-Mail-Adresse support@nowomed.de gekündigt
          werden. Der ARZT hat bei einer Kündigung § 627 Abs. 2 BGB zu beachten.
        </p>
        <p>
          7.3 Im Falle der Kündigung hat der PATIENT Leistungen des ARZTES, die bereits vor der Kündigung erbracht
          wurden, ordnungsgemäß zu vergüten.
        </p>

        <h3 className={cx('heading')}>8 Rechtswahl</h3>
        <p>
          Dieser Behandlungsvertrag unterliegt deutschem Recht und ist nach deutschem Recht auszulegen;
          kollisionsrechtliche Regelungen finden keine Anwendung.
        </p>
        <h3 className={cx('heading')}>9 Schlussbestimmungen</h3>
        <p>
          9.1 Die in diesem Behandlungsvertrag verwendeten Überschriften dienen lediglich der Übersichtlichkeit und
          haben keinen Einfluss auf die Auslegung der Behandlungsvertrag.
        </p>
        <p>
          9.2 Sollten einzelne Bestimmungen dieses Behandlungsvertrages ungültig oder nicht durchsetzbar sein oder
          werden, bleiben die anderen Bestimmungen davon unberührt. In einem solchen Fall wird die ungültige oder nicht
          durchsetzbare Bestimmung durch eine gültige Bestimmung ersetzt, die den Geschäftszielen der ungültigen oder
          nicht durchsetzbaren Bestimmung möglichst nahekommt. Dies gilt auch im Falle einer Regelungslücke.
        </p>
        <h3 className={cx('heading')}>
          Einwilligung zur Weitergabe Ihrer personenbezogenen Daten{' '}
          <u>und Entbindung von der ärztlichen Schweigepflicht</u>
        </h3>
        <p>
          Im Rahmen Ihrer Behandlung durch {doctorStampFormatted} ist die Weitergabe Ihrer Daten an externe Stellen
          erforderlich.
        </p>
        <p>
          Für die Abrechnung unserer Leistungen treten wir unsere Forderung an Ärztliche Verrechnungsstelle Büdingen
          GmbH Ärztliche Gemeinschaftseinrichtung, Gymnasiumstraße 18- 20, 63654 Büdingen („ABRECHNUNGSSTELLE“) ab und
          übermitteln wir Ihre Daten zum Zwecke der Rechnungsstellung und Einziehung der Forderung an die
          ABRECHNUNGSSTELLE.
        </p>
        <p>
          Ausführliche Informationen zu der Verarbeitung Ihrer personenbezogener Daten durch uns finden Sie in unseren
          Datenschutzinformationen.
        </p>
        <p>
          Bitte beachten Sie, dass Sie Ihre Einwilligung jederzeit (zum Beispiel schriftlich gerichtet an uns per E-Mail
          an support@nowomed.de) ohne Angabe von Gründen widerrufen können. Der Widerruf Ihrer Einwilligung berührt
          nicht die auf Grund der zuvor erteilten Einwilligung erfolgte Verarbeitung Ihrer personenbezogenen Daten. Das
          bedeutet, dass die Einwilligung nur für die Zukunft, also für die Zeit nach dem Widerruf entfällt. Im Fall des
          Widerrufs werden wir Ihre Daten zu den genannten Zwecken nicht mehr weiterverarbeiten.
        </p>
        <h3 className={cx('heading')}>1. Übermittlung an die ABRECHNUNGSSTELLE</h3>
        <p>
          Zur Abwicklung der Vergütung für unsere Leistung übermitteln wir folgende Daten an die oben genannte
          ABRECHNUNGSSTELLE:
          <ul>
            <li>Name</li>
            <li>Adresse</li>
            <li>Geburstdatum</li>
          </ul>
        </p>
        <p>
          Die für uns tätige ABRECHNUNGSSTELLE ist: Ärztliche Verrechnungsstelle Büdingen GmbH Ärztliche
          Gemeinschaftseinrichtung, Gymnasiumstraße 18-20, 63654 Büdingen.
        </p>
        <h3 className={cx('heading')}>2. Entbindung von der Schweigepflicht</h3>
        <p>Ich</p>
        <p>
          <strong>
            {patient.firstname} {patient.lastname}
          </strong>
        </p>
        <p>geb. am {userBirthday}</p>
        <div>
          <strong>wohnhaft in</strong>
        </div>
        <p>
          {patientStreet} {patientHouseNumber}
        </p>
        <p>
          {patientPostcode} {patientCity}
        </p>
        <p>
          bin damit einverstanden, dass zum Zweck der Abrechnung der Leistungen des Arztes/der Ärztin{' '}
          {doctorStampFormatted} meine zur Abrechnung notwendigen Daten, wie z.B. Name, Geburtsdatum, Adresse,
          medizinische Leistung, an die Ärztliche Verrechnungsstelle Büdingen GmbH Ärztliche Gemeinschaftseinrichtung,
          Gymnasiumstraße 18-20, 63654 Büdingen (auch elektronisch) übermittelt werden.
        </p>
        <p>Insoweit entbinde ich den Arzt/die Ärztin {doctorStampFormatted} von der ärztlichen Schweigepflicht.</p>
        <p>
          Ich wurde ausführlich über Sinn und Zweck dieser Schweigepflichtentbindung sowie über die Folgen einer
          Verweigerung beraten
        </p>
        <p>
          Die vorstehenden Erklärungen werden freiwillig abgegeben. Sie können jederzeit ganz oder teilweise widerrufen
          werden.
        </p>

        <h3 className={cx('heading')}>EINWILLINGUNG</h3>
        <p>
          Ich willige ein, dass die oben genannte Arztpraxis die vorstehend genannten Daten im Rahmen der
          Zahlungsabwicklung an die vorgenannte ABRECHNUNGSSTELLE weitergeben darf.
        </p>
        <h3 className={cx('heading')}>Anlage 1</h3>
        <h3 className={cx('heading')}>
          Erklärung des PATIENTEN zur Abrechnung und Abtretung der Forderungen des ARZTES
        </h3>
        <p>
          <strong>
            {patient.firstname} {patient.lastname}
          </strong>
        </p>
        <p>geb. am {userBirthday}</p>
        <div>
          <strong>wohnhaft in</strong>
        </div>
        <p>
          {patientStreet} {patientHouseNumber}
        </p>
        <p>
          {patientPostcode} {patientCity}
        </p>
        <p>
          Ich wünsche ausdrücklich die privatärztliche Behandlung nach diesem Behandlungsvertrag und bestätige, dass ich
          die Abrechnungserläuterungen und den voraussichtlichen Gesamtbetrag den Sie im Buchungsprozess eingesehen
          haben zur Kenntnis genommen habe. Ich erkläre mich damit einverstanden, die Behandlungskosten in vollem Umfang
          selbst zu tragen.
        </p>
        <p>
          Ich bin auch darüber informiert, dass die Krankenversicherung/Beihilfestelle die Erstattung des
          Rechnungsbetrages ganz oder teilweise ablehnen kann. Eine Übernahme der Kosten durch eine Erstattungsstelle
          ist nicht sichergestellt. Zusätzlich bestätige ich, dass ich sämtliche Kosten, die für Auftragsleistungen im
          Rahmen dieses Behandlungsvertrages von anderen Leistungserbringern, z. B. Laborarzt, in Rechnung gestellt
          werden, in vollem Umfang übernehme.
        </p>
        <p>
          Mir ist bewusst, dass Leistungen nach diesem Behandlungsvertrag [ggf.] Leistungen darstellen, die bei der
          Inanspruchnahme von Vertragsärzten von der gesetzlichen Krankenkasse übernommen werden könnten. Dennoch
          wünsche ich die Inanspruchnahme der Leistungen nach diesem Behandlungsvertrag als private Leistung und
          übernehme sämtliche Kosten persönlich.
        </p>
        <p>
          Mir ist bekannt, dass der ARZT die Rechnungsstellung und den Einzug seiner Forderungen von Ärztliche
          Verrechnungsstelle Büdingen GmbH vornehmen lässt seine Forderungen aus diesem Behandlungsvertrag. Die
          Abrechnungsstelle erhält dazu von Seiten des ARZTES sämtliche zu diesem Zweck benötigten Daten über mich.
        </p>

        <h3 className={cx('heading')}>Anlage 2</h3>
        <h3 className={cx('heading')}>Datenschutzerklärung für Patienten</h3>
        <p>
          Wir sind Nowohealth GmbH. In dieser Patientendatenschutzerklärung informieren wir Sie darüber, wie wir Ihre
          personenbezogenen Daten und insbesondere Ihre Gesundheitsdaten im Rahmen unserer ärztlichen Behandlung
          verarbeiten. Personenbezogene Daten sind nach Art. 4 Nr. 1 DS-GVO alle Informationen, die Sie direkt
          identifizieren (z. B. Ihre Name) oder identifizierbar machen (z. B. ein Geburtsdatum oder eine bestimmte
          Kennziffer). Eine Verarbeitung (z.B. Erhebung, Speicherung, Weitergabe, Auswertung oder Löschung) Ihrer
          personenbezogenen Daten erfolgt ausschließlich dann, wenn uns diese Verarbeitung gesetzlich erlaubt ist oder
          Sie in die Verarbeitung eingewilligt haben.
        </p>
        <h3 className={cx('heading')}>1 Verantwortlichkeit für die Datenverarbeitung </h3>
        <p>
          Verantwortlich für die Verarbeitung Ihrer personenbezogenen Daten ist: Nowohealth GmbH, Jasperallee 86/87,
          38102 Braunschweig. Sie erreichen uns für allgemeine Fragen entweder telefonisch unter 032 2218 57962, oder
          per E-Mail an support@nowomed.de.
        </p>
        <h3 className={cx('heading')}>2 Datenkategorien, Zweck und Rechtsgrundlage der Datenverarbeitung</h3>
        <p>
          2.1 Datenkategorien Im Rahmen unserer ärztlichen Tätigkeit verarbeiten wir in der Regel die folgenden
          Kategorien personenbezogener Daten von Ihnen:
          <ul>
            <li>Allgemeine Angaben zur Person (z.B. Ihr Name, Ihr Alter, Ihre Adresse)</li>{' '}
            <li>Informationen über Ihre Gesundheit, wie etwa Anamnesen, Diagnosen, Therapievorschläge und Befunde.</li>
          </ul>
        </p>
        <p>
          2.2 Zwecke und Rechtsgrundlage der Datenverarbeitung. Die Verarbeitung Ihrer personenbezogenen Daten und
          insbesondere Ihrer Gesundheitsdaten erfolgt auf Grundlage zur Erfüllung des Behandlungsvertrages und damit
          unmittelbar für den Zweck der Gesundheitsvorsorge und Heilbehandlung (Art. 9 Abs. 2 lit. h, Abs. 3 i.V.m. Art.
          6 Abs. 1 lit. b DS-GVO). In bestimmten Fällen, etwa bei der Übermittlung Ihrer Daten an andere Stellen, kann
          die Datenverarbeitung auch auf Grundlage Ihrer zuvor erteilten, ausdrücklichen Einwilligung erfolgen (Art. 9
          Abs. 1 lit. a i.V.m. Art. 6 Abs. 1 lit. a, Art. 7 DS-GVO). Bitte haben Sie Verständnis dafür, dass die
          Verarbeitung Ihrer Gesundheitsdaten Voraussetzung für Ihre Behandlung ist. Wenn wir die notwendigen
          Informationen nicht erhalten, können wir unsere Pflichten aus dem Behandlungsvertrag nicht erfüllen und keine
          sorgfältige Behandlung garantieren.
        </p>
        <h3 className={cx('heading')}>3 Erheben personenbezogener Daten</h3>
        <p>
          Ihre Daten erhalten wir von Nowohealth GmbH als Betreiberin der Plattform https://www.nowomed.com/de/
          („PLATTFORM“). Die Übermittlung ist gerechtfertigt durch Ihre gegenüber der PLATTFORM. Wir benötigen von Ihnen
          folgende personenbezogene Daten und gesundheitsbezogene Informationen über Sie:
          <ul>
            <li>
              Informationen über ihre persönlichen Daten: Name, Geburtsdatum, Adresse, Email-Adresse, Geschlecht,
              Telefonnummer
            </li>
            <li>
              Informationen über ihre persönlichen Gesundheitsinformationen, die mittels eines medizinischen Fragebogens
              gesammelt werden wie bestehende Diagnose, Medikamenteneinnahme, Fragen zu möglichen Kontraindikationen und
              Vorerkrankungen.
            </li>
            <li>
              Informationen, die zur Abrechnung unserer Leistungen erforderlich sind, wie Name, Geburtsdatum, Adresse
            </li>
          </ul>
        </p>
        <h3 className={cx('heading')}>4 Empfänger Ihrer Daten</h3>
        <p>
          Grundsätzlich werden Ihre Daten nur durch uns und unsere zur Verschwiegenheit verpflichteten Mitarbeiter
          verarbeitet. An Dritte übermitteln wir personenbezogene Daten nur dann, wenn dies gesetzlich erlaubt ist oder
          Sie eingewilligt haben.
        </p>
        <p>
          Empfänger Ihrer Daten können vor allem andere Ärzte/Psychotherapeuten, Ärztekammern und Privatärztliche
          Verrechnungsstellen sein. Im letzteren Fall übermitteln wir die Daten allerdings nur dann, wenn Sie nochmals
          ausdrücklich schriftlich in die Datenübertragung eingewilligt haben. Entsprechende Einwilligungen stellen wir
          Ihnen zur Verfügung, falls dies relevant sein sollte.{' '}
        </p>
        <p>
          Die Übermittlung Ihrer Daten erfolgt zudem zum Zwecke der Abrechnung der unsererseits erbrachten Leistungen an
          die dafür durch uns beauftragte Ärztliche Verrechnungsstelle Büdingen GmbH Ärztliche Gemeinschaftseinrichtung,
          Gymnasiumstraße 18-20, 63654 Büdingen („ABRECHNUNGSSTELLE“). Die Weitergabe Ihrer Daten erfolgt nur nach Ihrer
          vorherigen Einwilligung und Entbindung von der gesetzlichen Schweigepflicht (siehe Einwilligung am Ende dieser
          Erklärung).{' '}
        </p>
        <p>
          Für die die Datenverarbeitung der medizinischen Daten verwenden wir die Software Red Medical. Ihre
          Patientendaten werden über die Software in eine Cloud gespeichert. Hinsichtlich der Nutzung dieser
          cloudbasierten Software haben wir mit diesem Service-Provider einen Auftragsverarbeitungsvertrag nach Art. 28
          DS-GVO abgeschlossen.
        </p>
        <h3 className={cx('heading')}>5 Speicherdauer Ihrer Daten</h3>
        <p>
          Ihre personenbezogenen Daten werden von uns nur so lange gespeichert, wie dies für die Durchführung der
          Behandlung erforderlich ist.
        </p>
        <p>
          Wir weisen darauf hin, dass ihr Arzt aufgrund rechtlicher Vorgaben dazu verpflichtet ist, diese Daten
          mindestens zehn Jahre nach Abschluss des Behandlungsvertrages aufzubewahren.{' '}
        </p>
        <h3 className={cx('heading')}>6 Ihre Betroffenenrechte nach der DS-GVO</h3>
        <p>Im Hinblick auf die durch uns durchgeführte Verarbeitung haben Sie folgende Rechte: </p>
        <ul>
          <li>
            Auskunftsrecht: Sie sind berechtigt, eine Bestätigung darüber zu verlangen, ob wir personenbezogene Daten
            von Ihnen verarbeiten (Art. 15 Abs. 1 DS-GVO). Ist dies der Fall, sind Sie ferner berechtigt, Auskunft über
            diese Daten und den Umfang ihrer Verarbeitung (Art. 15 Abs. 1 DS-GVO) zu verlangen sowie eine Kopie zu
            erhalten (Art. 15 Abs. 3 DS-GVO).
          </li>
          <li>
            Recht auf Berichtigung und Vervollständigung: Sie haben ein Recht auf Berichtigung Ihrer Daten, sofern die
            von uns verarbeiteten Daten unrichtig sind (Art. 16 Abs. 1 S. 2 DS-GVO). Sind Ihre Daten hingegen
            unvollständig haben Sie das Recht die Vervollständigung zu verlangen (Art. 16 Abs. 1 S. 2 DS-GVO).
          </li>
          <li>
            Recht auf Löschung: Sie haben das Recht, Löschung Ihrer personenbezogenen Daten zu verlangen, wenn ein
            gesetzlicher Löschungsgrund vorliegt (Art. 17 Abs. 1 lit. a – f DS-GVO). Dieses Recht unterliegt
            Einschränkungen, wenn die weitere Verarbeitung für gesetzlich bestimmte Zwecke erforderlich ist (Art. 17
            Abs. 3 lit. a – e DS-GVO).{' '}
          </li>
          <li>
            Recht auf Einschrän-kung der Verarbeitung: Sie können die Einschränkung der Verarbeitung Ihrer Daten
            verlangen, wenn die gesetzlichen Voraussetzungen vorliegen (Art. 18 Abs. 1 DS-GVO). berechtigt, unter den
            Voraussetzungen von Art. 18 DS-GVO von uns zu verlangen, dass wir die Verarbeitung Deiner personenbezogenen
            Daten einschränken.
          </li>
          <li>
            Widerruf einer Einwilligung: Sofern wir Ihre Daten aufgrund einer von Ihnen erteilten Einwilligung
            verarbeiten, können Sie diese Einwilligung jederzeit widerrufen. Von dem Moment des Widerrufs an sind wir
            nicht mehr dazu berechtigt, Daten von Ihnen aufgrund dieser Einwilligung zu verarbeiten.
          </li>
        </ul>
        <p>
          Sie haben zudem das Recht, sich mit einer Beschwerde an die zuständige Datenschutz-Aufsichtsbehörde zu wenden:
          Der Landesbeauftragte für den Datenschutz Niedersachsen, Prinzenstraße 5, 30159 Hannover, Telefon: 0511
          120-4500, Email: poststelle@lfd.niedersachsen.de
        </p>
      </React.Fragment>
    </React.Fragment>
  );
}
