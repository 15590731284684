import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useAppDispatch } from '../../../../store/hooks';
import { loadUserInformation } from '../../../../store/reducers';
import type { ReducerAction } from '../../../../types/navigationIndex.types';
import { Button, Headline } from '../../../atoms';
import classes from './SuccessPage.module.scss';

const cx = classNames.bind(classes);

type SuccessPageProps = {
  setCurrentPage: React.Dispatch<ReducerAction>;
};

export function SuccessPage({ setCurrentPage }: SuccessPageProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const next = () => {
    dispatch(loadUserInformation);
    navigate('/dashboard');

    const reducerType = {
      type: '[UPDATE]',
      payload: {
        followUpPrescriptionsIndex: 1,
      },
    };
    setCurrentPage(reducerType);
  };

  return (
    <div className={cx('SuccessPage')}>
      <Headline level={3}>Vielen Dank</Headline>
      <div className={cx('info-text')}>Ihr Folgerezeptantrag wurde erfolgreich abgeschickt.</div>
      <div className={cx('button-container')}>
        <Button onClick={next}>Zum Dashboard</Button>
      </div>
    </div>
  );
}
