import type { CSSProperties } from 'react';
import * as React from 'react';

type VerifiedProps = {
  color?: string;
  styles?: CSSProperties;
  size?: string | number;
};

export function Verified({ size = 15, color = '#3DB384', styles }: VerifiedProps): JSX.Element {
  const svgStyles = typeof styles !== 'undefined' ? styles : {};

  return (
    <svg width={size} height={size} viewBox='0 0 30 35' fill={color} style={svgStyles}>
      <g id='05-Apotheke-wählen' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g id='verified-icon' fill={color}>
          <path
            d='M15,0 L30,6.3760957 L30,15.9383532 C30,24.7549935 23.5819715,32.9970182 15,35 C6.41802847,32.9970182 0,24.7474493 0,15.9383532 L0,15.9383532 L0,6.3760957 L15,0 Z M22.6476003,10 L11.6627219,20.4947174 L7.35239974,16.3717927 L5,18.6206607 L11.6692965,25 L25,12.248868 L22.6476003,10 Z'
            id='Combined-Shape'
          ></path>
        </g>
      </g>
    </svg>
  );
}
