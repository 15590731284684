import React from 'react';
import classNames from 'classnames/bind';
import style from './ProgressStep.module.scss';

const cx = classNames.bind(style);

type ProgressStepProps = {
  isActive: boolean;
};

export function ProgressStep({ isActive }: ProgressStepProps): JSX.Element {
  const stepClasses = cx('ProgressStep', { ProgressStepActive: isActive });

  return <div className={stepClasses}></div>;
}
