const windowSize = {
  width: window.innerWidth,
  height: window.innerHeight,
};

const isMobile = windowSize.width < 480;
const isTablet = windowSize.width >= 480 && windowSize.width < 768;
const isPhablet = windowSize.width >= 768 && windowSize.width < 1280;
const isDesktop = windowSize.width >= 1280;
const isLaptopScreen = windowSize.width >= 1280 && windowSize.width < 1800;
const isLargeScreen = windowSize.width >= 1570;
const isMobileOrTablet = isMobile || isTablet;

export { isDesktop, isLaptopScreen, isLargeScreen, isMobile, isMobileOrTablet, isPhablet, isTablet };
