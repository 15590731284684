import React from 'react';

type QuestionnaireProps = {
  size?: number;
  color?: string;
};

export function Questionnaire({ size = 80, color = '#3db384' }: QuestionnaireProps): JSX.Element {
  return (
    <svg width={size} height={size} viewBox='0 0 80 80'>
      <g>
        <path
          fill={color}
          d='M39.7,17.8c3.1-1.5,5.2-4.6,5.2-8.3S40.9.3,35.8.3s-9.2,4.1-9.2,9.2,2.1,6.8,5.2,8.3c-7.2,1.8-12.6,8.3-12.6,16h2c0-8,6.5-14.5,14.5-14.5s14.5,6.5,14.5,14.5h2c0-7.8-5.4-14.3-12.6-16h.1ZM28.6,9.5c0-4,3.2-7.2,7.2-7.2s7.2,3.2,7.2,7.2-3.2,7.2-7.2,7.2-7.2-3.2-7.2-7.2Z'
        />
        <g>
          <rect fill={color} x='25' y='42.8' width='38.4' height='2' rx='.5' ry='.5' />
          <path
            fill={color}
            d='M12.5,47.5l-4.7-4.6c0,0,0-.4,0-.5l.9-.9c.1,0,.4,0,.5,0l3.3,3.3h.3l5-5c.1,0,.4,0,.5,0l.9.9c.1,0,.1.4,0,.5l-6.3,6.3c0,0-.4,0-.5,0h.1Z'
          />
        </g>
        <g>
          <rect fill={color} x='25' y='54.4' width='38.4' height='2' rx='.5' ry='.5' />
          <path
            fill={color}
            d='M12.5,59.1l-4.7-4.6c0,0,0-.4,0-.5l.9-.9c.1,0,.4,0,.5,0l3.3,3.3h.3l5-5c.1,0,.4,0,.5,0l.9.9c.1,0,.1.4,0,.5l-6.3,6.3c0,0-.4,0-.5,0h.1Z'
          />
        </g>
        <g>
          <rect fill={color} x='25' y='66' width='38.4' height='2' rx='.5' ry='.5' />
          <path
            fill={color}
            d='M12.5,70.8l-4.6-4.6c0,0,0-.4,0-.5l.9-.9c.1,0,.4,0,.5,0l3.3,3.3h.3l5-5c.1,0,.4,0,.5,0l.9.9c.1,0,.1.4,0,.5l-6.3,6.3c0,0-.4,0-.5,0Z'
          />
        </g>
      </g>
    </svg>
  );
}
