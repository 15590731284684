import type { Pharmacy } from '../../../../types/ninox.types';
import { Button, Image, Text, View } from '../../../atoms';
import classes from './PremiumPharmacy.module.scss';

type PremiumPharmacyProps = {
  pharmacy: Pharmacy;
  setConfirmationModal: (value: boolean) => void;
  setChosenPharmacy: (pharmacy: Pharmacy) => void;
};

export const PremiumPharmacy = ({
  pharmacy,
  setConfirmationModal,
  setChosenPharmacy,
}: PremiumPharmacyProps): JSX.Element => {
  const Details = () => (
    <View className={classes.Details} key={pharmacy.id}>
      <Text className={classes.description} children={pharmacy.descriptionShort} />
      <View>
        <View className={`row justify-center ${classes['button-row']}`}>
          <Button
            className={`${classes.button} ${classes['button-choose-pharmacy']}`}
            onClick={() => {
              setConfirmationModal(true);
              setChosenPharmacy(pharmacy);
            }}
          >
            <Text>Diese Apotheke wählen</Text>
          </Button>
        </View>
      </View>
    </View>
  );

  return (
    <View className={classes.PremiumPharmacy}>
      <div className='clickable'>
        <Image
          className={classes.image}
          source={{
            height: 60,
            width: 450,
            uri: `data:image/jpg;base64,${pharmacy.image}`,
          }}
        />
      </div>
      <Details />
    </View>
  );
};
