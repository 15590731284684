import { useLocation, useNavigate } from 'react-router-dom';
import { signOff } from '../../../helper/UtilityFunctions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectAppData, selectAuth, setAppData, setAuth, setUserInformation } from '../../../store/reducers';
import { initialUserInformation } from '../../../store/reducers/initialState';
import { Icon } from '../../atoms';
import { DrawerItem } from '../DrawerItem/DrawerItem';
import { styles as inlineStyles } from './NavigationDrawer.style';
import styles from './NavigationDrawer.module.scss';

export function NavigationDrawer(props: any) {
  const auth = useAppSelector(selectAuth);
  const appData = useAppSelector(selectAppData);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const currentRoute = location.pathname;

  const navigate = useNavigate();

  const navigateTo = (route: string, navigationParams?: string) => {
    const reduxData = {
      ...appData,
      // TODO: warum brauchen wir diese Zeile??
      // navigationDrawerOpen: !appData.navigationDrawerOpen,
      navigationDrawerOpen: false,
    };

    dispatch(setAppData(reduxData));

    // props.setToggleBarUp(false);
    if (navigationParams) {
      navigate({
        pathname: route,
        search: navigationParams,
      });

      return;
    }
    navigate(route);
  };

  const onClickClose = () => {
    const reduxData = {
      navigationDrawerOpen: !appData.navigationDrawerOpen,
    };

    dispatch(setAppData(reduxData));
  };

  const drawerClasses = [styles.NavigationDrawer];

  if (appData.navigationDrawerOpen) {
    drawerClasses.push(styles.open);
  }

  const drawerClassesString = drawerClasses.join(' ');

  return (
    <div className={drawerClassesString} style={inlineStyles.container} {...props}>
      <div className={styles.header}>
        <div style={inlineStyles.logo}>
          <Icon icon='nowomed' size={200} />
        </div>
        <div onClick={onClickClose} className={styles.close}>
          <Icon icon='cross' size={20} />
        </div>
      </div>
      <div className={styles.spacer} />
      {auth.isAuthenticated && (
        <>
          <div className={styles.item} onClick={() => navigateTo('/dashboard')}>
            <DrawerItem
              label='Dashboard'
              icon={() => <Icon icon='dashboard' color={currentRoute === '/dashboard' ? '#3db384' : 'grey'} />}
              labelStyle={currentRoute === '/dashboard' ? inlineStyles.labelGreen : inlineStyles.label}
            />
          </div>
        </>
      )}
      <div className={styles.divider}></div>
      {auth.isAuthenticated ? (
        <div
          className={styles.item}
          onClick={() => {
            const authRedux = {
              isAuthenticated: false,
              isTermsAgree: false,
              name: '',
              isPrivacyPolicyAgree: false,
            };
            dispatch(setAuth(authRedux));
            dispatch(setUserInformation(initialUserInformation));
            // props.resetState();
            signOff({
              auth,
              nav: () => navigateTo('/login'),
            });
          }}
        >
          <DrawerItem
            label='Abmelden'
            icon={() => <Icon icon='logout' color='gray' />}
            labelStyle={inlineStyles.label}
          />
        </div>
      ) : (
        <div
          className={styles.item}
          onClick={() => {
            navigateTo('/login');
          }}
        >
          <DrawerItem
            label='Anmelden'
            icon={() => <Icon icon='logout' color='gray' />}
            labelStyle={inlineStyles.label}
          />
        </div>
      )}
    </div>
  );
}
