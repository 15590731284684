import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import UserService from '../../../services/User.service';
import { useAppDispatch } from '../../../store/hooks';
import { setAuth } from '../../../store/reducers';
import type { DoctorAbsence, DoctorSlot } from '../../../types/user.types';
import { CalendarDay, Headline, Icon } from '../../atoms';
import classes from './Calendar.module.scss';

const cx = classNames.bind(classes);

type CalendarProps = {
  slots?: DoctorSlot[];
  onChange?: (selectedDay: Date) => void;
  mainDoctor?: number;
  selectedDate: Date;
};

const absenceInfoClasses = cx({ AbsenceInfo: true });
const absenceInfoHeaderClasses = cx({ AbsenceInfoHeader: true });
const absenceInfoDescriptionClasses = cx({ AbsenceInfoDescription: true });

export function Calendar({ slots = [], onChange, mainDoctor, selectedDate }: CalendarProps): JSX.Element {
  const navigate = useNavigate();

  const [calendarMonth, setCalendarMonth] = useState(new Date().getMonth());
  const [calendarYear, setCalendarYear] = useState(new Date().getFullYear());
  const [displayDays, setDisplayDays] = useState<JSX.Element[]>([]);
  const [doctorAbsence, setDoctorAbsence] = useState<any>({});

  const dispatch = useAppDispatch();

  const calculateRecentAbsence = (absences: Array<DoctorAbsence>) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const cleanAbsences = absences.map((absence) => {
      const startDate = new Date(absence.startDate);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(absence.endDate);
      endDate.setHours(0, 0, 0, 0);

      return {
        startDate,
        endDate,
      };
    });

    const nextAbsence = cleanAbsences.find((cleanAbsence) => cleanAbsence.endDate >= today);

    if (nextAbsence) {
      const finalAbsence = { startDate: '', endDate: '' };
      finalAbsence.startDate = `${nextAbsence.startDate.getDate()}.${nextAbsence.startDate.getMonth() + 1}.${nextAbsence.startDate.getFullYear()}`;
      finalAbsence.endDate = `${nextAbsence.endDate.getDate()}.${nextAbsence.endDate.getMonth() + 1}.${nextAbsence.endDate.getFullYear()}`;
      setDoctorAbsence(finalAbsence);
    }
  };

  useEffect(() => {
    if (!mainDoctor) {
      return;
    }

    UserService.getDoctorAbsenceCurrentMonth(mainDoctor)
      .then((res) => {
        if (res.error) {
          if (res.message.response.status === 401) {
            dispatch(setAuth({ isAuthenticated: false }));
            navigate('/login');
          }
          console.error(res.message);
        }

        calculateRecentAbsence(res.data);
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, [mainDoctor]);

  const setFirstDateWithSlots = (): void => {
    // Deal with no slots situation
    if (!slots || slots.length === 0) {
      return;
    }

    const minDate = new Date(Math.min(...slots.map((slot) => new Date(slot.start).getTime())));
    setCalendarYear(minDate.getFullYear());
    setCalendarMonth(minDate.getMonth());
    const parsedMindate = new Date(minDate.setHours(0, 0, 0, 0));
    if (selectedDate < parsedMindate && onChange) {
      onChange(parsedMindate);
    }
  };

  useEffect(() => {
    setFirstDateWithSlots();
  }, [slots]);

  const changeDays = () => {
    const firstDay = new Date(calendarYear, calendarMonth, 1);
    const lastDay = new Date(calendarYear, calendarMonth + 1, 0).getDate();
    const actualDay = new Date(new Date().setHours(0, 0, 0, 0));
    const days = [];
    let daysRow = [];
    if (firstDay.getDay() === 0) {
      for (let i = 0; i < 6; i += 1) {
        daysRow.push(
          <div className={classes['day-container']} key={`empty-container-${i}`}>
            <div className={classes.day} key={`empty-${i}`}>
              {' '}
            </div>
          </div>
        );
      }
    }
    if (firstDay.getDay() > 1) {
      for (let i = 0; i < firstDay.getDay() - 1; i += 1) {
        daysRow.push(
          <div className={classes['day-container']} key={`empty-container-${i}`}>
            <div className={classes.day} key={`empty-${i}`}>
              {' '}
            </div>
          </div>
        );
      }
    }
    for (let i = 0; i < lastDay; i += 1) {
      const calendarDay = new Date(calendarYear, calendarMonth, i + 1);
      const isDatePassed = calendarDay < actualDay;
      const hasSlot = slots?.find((slot) => {
        const slotDate = new Date(slot.start);

        return (
          slotDate.getFullYear() === calendarDay.getFullYear() &&
          slotDate.getMonth() === calendarDay.getMonth() &&
          slotDate.getDate() === calendarDay.getDate()
        );
      });
      const isDoctorAbsent = slots?.find((slot) => {
        const slotDate = new Date(slot.start);

        return (
          slot?.isDoctorAbsent &&
          slotDate.getFullYear() === calendarDay.getFullYear() &&
          slotDate.getMonth() === calendarDay.getMonth() &&
          slotDate.getDate() === calendarDay.getDate()
        );
      });
      daysRow.push(
        <CalendarDay
          isDatePassed={isDatePassed}
          hasSlot={!!hasSlot}
          selectedDate={selectedDate}
          calendarDate={calendarDay}
          isSelected={selectedDate.getTime() === calendarDay.getTime()}
          onClick={() => (onChange ? onChange(calendarDay) : null)}
          isDoctorAbsent={!!isDoctorAbsent}
          key={i}
        >
          {i + 1}
        </CalendarDay>
      );
      if (daysRow.length === 7 || i === lastDay - 1) {
        if (daysRow.length < 7) {
          for (let j = daysRow.length; j < 7; j += 1) {
            daysRow.push(
              <div className={classes['day-container']} key={`empty-last-container-${i + j}`}>
                <div className={classes.day} key={`empty-last-${i + j}`}>
                  {' '}
                </div>
              </div>
            );
          }
        }
        days.push(
          <div className={classes['days-row']} key={`row-${i}`}>
            {daysRow}
          </div>
        );
        daysRow = [];
      }
    }
    setDisplayDays(days);
  };

  useEffect(() => {
    changeDays();
    if (selectedDate) {
      setCalendarMonth(selectedDate.getMonth());
    }
  }, [selectedDate]);

  const changeMonth = (month: number): void => {
    if (month === -1) {
      setCalendarYear(calendarYear - 1);
      setCalendarMonth(11);

      return;
    }
    if (month === 12) {
      setCalendarYear(calendarYear + 1);
      setCalendarMonth(0);

      return;
    }
    setCalendarMonth(month);
  };

  useEffect(() => {
    changeDays();
  }, [calendarMonth, calendarYear]);

  const monthNames = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ];

  return (
    <div className={classes.Calendar}>
      {doctorAbsence && Object.entries(doctorAbsence).length !== 0 && (
        <div className={absenceInfoClasses}>
          <div className={absenceInfoHeaderClasses}>
            <Icon icon='attention' />
            <Headline level={4}>Bitte beachten Sie:</Headline>
          </div>
          <div className={absenceInfoDescriptionClasses}>
            Ihr behandelnder Arzt ist in der Zeit vom {doctorAbsence.startDate} bis {doctorAbsence.endDate} abwesend.
            Neu gebuchte Termine werden in diesem Zeitraum vertreten.
          </div>
        </div>
      )}
      <div className={classes['month-year']}>
        <div className={cx('change-month')} onClick={() => changeMonth(calendarMonth - 1)}>
          <div className={cx('change-month-icon', 'decrease')}>
            <Icon icon={'chevron'} />
          </div>
        </div>
        <div className={classes.text}>
          {monthNames[calendarMonth]} {calendarYear}
        </div>
        <div className={cx('change-month')} onClick={() => changeMonth(calendarMonth + 1)}>
          <div className={cx('change-month-icon', 'increase')}>
            <Icon icon={'chevron'} />
          </div>
        </div>
      </div>
      <div className={classes['week-days']}>
        <div className={classes['week-day-container']}>
          <div className={classes['week-day']}>Mo</div>
        </div>
        <div className={classes['week-day-container']}>
          <div className={classes['week-day']}>Di</div>
        </div>
        <div className={classes['week-day-container']}>
          <div className={classes['week-day']}>Mi</div>
        </div>
        <div className={classes['week-day-container']}>
          <div className={classes['week-day']}>Do</div>
        </div>
        <div className={classes['week-day-container']}>
          <div className={classes['week-day']}>Fr</div>
        </div>
        <div className={classes['week-day-container']}>
          <div className={classes['week-day']}>Sa</div>
        </div>
        <div className={classes['week-day-container']}>
          <div className={classes['week-day']}>So</div>
        </div>
      </div>
      <div className={classes['days-rows']}>{displayDays}</div>
    </div>
  );
}
