import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppointmentService from '../../../services/Appointment.service';
import { Button, InputField } from '../../atoms';
import { Checkbox } from '../../molecules/Checkbox/Checkbox';
import { BasicHeader } from '../../organisms/Header/BasicHeader';
import styles from './ProductSelection.module.scss';

interface Product {
  name: string;
  availability: string;
  price: string;
  kultivar: string;
  genetik: string;
}

export function ProductSelection(): JSX.Element {
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [customProductText, setCustomProductText] = useState('');
  const [products, setProducts] = useState<Product[]>([]);
  const [showProductList, setShowProductList] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const dummyProducts: Product[] = JSON.parse(sessionStorage.getItem('products') ?? '[]').filter(
      (product: Product) => product.name !== ''
    );

    // Here we checkin the pharmacy category
    // When it's 0 (only A list pharmacys) we can show a list of pharmacys.
    const pharmacyCategory = sessionStorage.getItem('pharmacyCategory');
    setShowProductList(String(pharmacyCategory) === '0');
    setProducts(dummyProducts);
  }, []);

  const handleProductSelect = (productName: string) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(productName)
        ? prevSelected.filter((name) => name !== productName)
        : [...prevSelected, productName]
    );
  };

  const handleSave = async () => {
    const selectedProductsString = selectedProducts
      .map((productName) => {
        const product = products.find((p) => p.name === productName);
        // when there is a valid product, create a string with cultiva
        if (product) {
          return `${product.name} (${product.kultivar})`;
        }
        // otherwise just give the name back

        return productName;
      })
      .join(', \n');

    const infoText =
      selectedProducts.length !== 0 ? `${selectedProductsString}\n${customProductText}` : customProductText;
    const infoTextBody = infoText.toString();
    const appointmentId = Number(sessionStorage.getItem('appointmentId'));
    const res = await AppointmentService.updatePatientInfo(appointmentId, infoTextBody);
    if (res.error === false) {
      navigate('/finish-checkin/step-1', { replace: true });
    }
  };

  return (
    <div className={styles.productSelection}>
      <BasicHeader />
      <div className={styles['heading-wrapper']}>
        <div className={styles.heading3}>Bitte wählen Sie Ihre Wunschpräparate</div>
        <div className={styles.heading4}>
          Sollten Sie keine Wunschpräparate haben, klicken Sie unten einfach auf “Weiter”
        </div>
      </div>
      {showProductList && (
        <div>
          <ul className={styles.productlist}>
            {products.map((product) => (
              <li
                className={styles.productlistitem}
                key={product.name}
                onClick={() => handleProductSelect(product.name)}
              >
                <Checkbox onClick={() => {}} checked={selectedProducts.includes(product.name)} label='' />
                <div className={styles['product-details']}>
                  <span className={styles['product-name']}>{product.name}</span>
                  <span className={styles['product-cultivar']}>{product.kultivar}</span>
                </div>
                <span className={styles['product-genetic']}>{product.genetik}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className={styles['input-field-wrapper']}>
        <div className={styles.heading4}>
          {showProductList === true
            ? 'Falls ihr gewünschtes Produkt nicht in der Liste ist, können Sie dieses hier eingeben:'
            : 'Bitte geben Sie hier Ihre Wunschpräparate ein.'}
        </div>
        <div className={styles['input-field']}>
          <InputField
            value={customProductText}
            onChange={setCustomProductText}
            multiline={true}
            placeholder='Geben Sie hier Ihren Text ein'
          />
        </div>
        <Button onClick={handleSave} className={styles['input-button']}>
          Weiter
        </Button>
      </div>
    </div>
  );
}

// eslint-disable-next-line import/no-default-export
export default ProductSelection;
