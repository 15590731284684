import type { CSSProperties } from 'react';
import { mergeStyles } from '../../../helper/UtilityFunctions';

type ImageProps = {
  source: {
    width?: number;
    height?: number;
    uri?: string;
  };
  style?: CSSProperties[] | CSSProperties;
  tintColor?: string;
  className?: string;
};

export function Image(props: ImageProps) {
  let className;

  if (props.className) {
    className = props.className;
  }

  if (props.tintColor) {
    let effectiveStyles: CSSProperties = {
      width: props.source?.width,
      height: props.source?.height,
      maskImage: `url('${props.source?.uri}')`,
      maskRepeat: 'no-repeat',
      maskPosition: 'center',
      maskSize: 'contain',
      WebkitMaskImage: `url('${props.source?.uri}')`,
      WebkitMaskRepeat: 'no-repeat',
      WebkitMaskPosition: 'center',
      WebkitMaskSize: 'contain',
      background: props.tintColor,
    };

    if (props.style) {
      if (Array.isArray(props.style)) {
        effectiveStyles = mergeStyles([effectiveStyles, ...props.style]);
      } else {
        effectiveStyles = mergeStyles([effectiveStyles, props.style]);
      }
    }

    return (
      <div className={className} style={effectiveStyles}>
        <img src={props.source?.uri} style={{ opacity: 0, width: props.source?.width, height: props.source?.height }} />
      </div>
    );
  }

  let imgStyles: CSSProperties = {
    width: props.source?.width,
    height: props.source?.height,
    objectFit: 'contain',
  };

  if (props.style) {
    if (Array.isArray(props.style)) {
      imgStyles = mergeStyles([imgStyles, ...props.style]);
    } else {
      imgStyles = mergeStyles([imgStyles, props.style]);
    }
  }

  return <img className={className} src={props.source?.uri} style={imgStyles} />;
}
