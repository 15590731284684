import * as React from 'react';

type MarkerProps = {
  color?: string;
  size?: number;
};

export function Marker({ size = 64, color = '#3DB384' }: MarkerProps): JSX.Element {
  return (
    <svg width={size} height={size + 27} viewBox='0 0 56 83'>
      <defs>
        <polygon id='path-1' points='0 0.113066327 55.9084211 0.113066327 55.9084211 83 0 83' />
      </defs>
      <g id='03-Termin-buchen' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='01-Step-1-Copy-12' transform='translate(-179.000000, -112.000000)'>
          <g id='Group-6' transform='translate(179.000000, 112.000000)'>
            <g id='Group-3'>
              <g id='Clip-2' />
              <path
                d='M27.9496421,77.0114441 C28.1490105,76.7385181 28.2951158,76.5551559 28.4237474,76.3599365 C33.7386947,68.2900921 38.7275368,60.0212171 43.2325895,51.466712 C46.2671158,45.7047758 49.1008,39.8479824 50.8957474,33.5408293 C52.1163789,29.2517196 51.9260632,25.013638 50.5416421,20.8208676 C48.6003789,14.9420538 44.9317474,10.4297758 39.5643789,7.41001556 C33.5469053,4.02437781 27.1610105,3.38663291 20.6193263,5.53595179 C13.9879579,7.71491352 9.17364211,12.0993038 6.29511579,18.498138 C4.10627368,23.364013 3.57237895,28.4111329 5.06543158,33.6255232 C6.44311579,38.4367707 8.43806316,42.9890666 10.6528,47.448411 C15.2195368,56.6434135 20.4719579,65.4420487 26.0142737,74.0696023 C26.6340632,75.0344773 27.2742737,75.986013 27.9496421,77.0114441 L27.9496421,77.0114441 Z M29.5772211,0.113002806 C31.4180632,0.433780867 33.2976421,0.610579337 35.0930105,1.10010995 C43.1938526,3.30871454 49.1563789,8.23429872 52.9692211,15.7426227 C55.2746947,20.2824263 56.3130105,25.1372911 55.7645895,30.2132069 C55.5151158,32.5211151 54.9195368,34.8256355 54.2054316,37.0420742 C51.9334316,44.093263 48.5153263,50.6297247 44.9728,57.0965258 C40.3285895,65.5748064 35.2239579,73.7714798 29.8662737,81.8097758 C28.8300632,83.3645436 27.1294316,83.4062554 26.1174316,81.8752018 C18.1622737,69.8397783 10.5751158,57.5864798 4.58690526,44.4104416 C2.6368,40.1192145 0.945221053,35.7242375 0.238905263,31.0171635 C-0.273936842,27.6006125 0.0652210526,24.2270436 1.00332632,20.9383804 C3.6048,11.8183319 9.33027368,5.49360485 18.0866947,1.96419923 C20.5643789,0.965658418 23.1521684,0.414089541 25.8170105,0.233056378 C25.9993263,0.220564031 26.1780632,0.154291071 26.3584842,0.113002806 L29.5772211,0.113002806 Z'
                id='Fill-1'
                fill={color}
              />
            </g>
            <path
              d='M27.6039354,17.1926884 C21.5333939,17.0382182 16.3617704,22.0483975 16.2001506,28.240463 C16.0385308,34.4291613 20.9690942,39.6348484 27.1536335,39.8051024 C33.4437419,39.9783026 38.6341192,35.0600899 38.7988997,28.7705861 C38.9636803,22.4810823 34.0076202,17.3555766 27.6039354,17.1926884 M43,28.4887938 C43.0189259,37.0242182 36.0427235,44.0031557 27.4952055,44 C18.9708663,43.9966317 12.0578789,37.1083982 12.0003532,28.5605572 C11.9430382,20.0402851 18.8682472,13.0716597 27.4635979,13.0005277 C35.9561188,12.930027 42.9807862,19.9331662 43,28.4887938'
              id='Fill-4'
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
