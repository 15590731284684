import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface Appointment {
  doctorName: string;
  doctorId: string;
  startDate: string;
  endDate: string;
  type: string;
  appointmentType: string;
  practiceId: number;
  comment: string;
  location: {
    name: string;
    address: string;
  };
}

const initialState: Appointment = {
  doctorName: '',
  doctorId: '',
  startDate: '',
  endDate: '',
  type: '',
  appointmentType: '',
  practiceId: -1,
  comment: '',
  location: {
    name: '',
    address: '',
  },
};

export const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    setAppointmentData: (state, action: PayloadAction<Appointment>) => {
      return action.payload;
    },
  },
});

export const { setAppointmentData } = appointmentSlice.actions;
export const selectAppointmentData = (state: RootState) => state.appointment;
