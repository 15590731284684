import React, { useState } from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { selectUserInformation } from '../../../../store/reducers';
import type { Pharmacy } from '../../../../types/ninox.types';
import { Button, Text, View } from '../../../atoms';
import classes from './StandardPharmacy.module.scss';

type StandardPharmacyProps = {
  pharmacy: Pharmacy;
  setConfirmationModal: (value: boolean) => void;
  setChosenPharmacy: (pharmacy: Pharmacy) => void;
};

export const StandardPharmacy = ({ pharmacy, setConfirmationModal, setChosenPharmacy }: StandardPharmacyProps) => {
  const userInformation = useAppSelector(selectUserInformation);
  const [showDetails, setShowDetails] = useState(false);
  const disable = pharmacy.id === userInformation.patientPharmacyRelation?.id;
  // const navigate = useNavigate();

  const Details = () => (
    <View key={pharmacy.id} className={`${classes.Details}`}>
      <View className={classes['button-row']}>
        <Button
          className={`${classes.button} ${classes['pharmacy-button']}`}
          onClick={() => {
            setConfirmationModal(true);
            setChosenPharmacy(pharmacy);
          }}
          disabled={disable}
        >
          <Text className={classes.text}>{disable ? 'Ausgewählte Apotheke' : 'Apotheke wählen'}</Text>
        </Button>
      </View>
    </View>
  );

  return (
    <View className={classes.StandardPharmacy}>
      <div className={classes.headline} onClick={() => setShowDetails(!showDetails)}>
        <Text className={classes['title-left']}>{pharmacy.name}</Text>
        <Text className={classes['title-right']}>{pharmacy.city}</Text>
      </div>
      {showDetails && <Details />}
    </View>
  );
};
