import React, { useState } from 'react';
import UserService from '../../../services/User.service';
import { Icon, Image, Modal } from '../../atoms';
import classes from './DocumentBox.module.scss';

type DocumentBoxProps = {
  id: number;
  thumb: string;
  filename: string;
  openDocument: (uri: string, name: string) => void;
  reload: () => void;
  canDelete: boolean;
  filetype: string;
  fileCreated?: string;
  setLoading: (value: boolean) => void;
};

export function DocumentBox(props: DocumentBoxProps) {
  const [showDelete, setShowDelete] = useState(false);

  const deleteDocument = async () => {
    props.setLoading(true);

    const response = await UserService.deleteFile(props.id!);
    if (response.error) {
      console.error(response.message);

      return;
    }
    props.reload();
    setShowDelete(false);
  };

  const openDocument = async () => {
    const fileResponse = await UserService.getFile(props.id!);
    if (fileResponse.error) {
      // eslint-disable-next-line no-alert
      alert('Es ist ein Fehler aufgetreten - Versuchen sie es später erneut');

      return;
    }
    const fileData = fileResponse.data;

    props.openDocument(fileData.file, props.filename!);
  };

  const onClickDelete = (event: any) => {
    event.stopPropagation();
    setShowDelete(true);
  };

  return (
    <React.Fragment>
      <Modal
        modalVisible={showDelete}
        setModalVisible={setShowDelete}
        cb={deleteDocument}
        primaryButtonText='Löschen'
        secondaryButtonText='Abbrechen'
        className={classes['delete-modal']}
      >
        <div className={classes.text}>Wollen Sie das Dokument</div>
        <div className={classes.filename}>{props.filename}</div>
        <div className={classes.text}>wirklich löschen?</div>
      </Modal>
      <div className={classes.DocumentBox} onClick={openDocument}>
        {props.thumb && (
          <Image
            className={classes.thumb}
            source={{ width: 90, height: 90, uri: `data:image/jpg;base64,${props.thumb}` }}
          />
        )}
        <div className={classes.filename}>{props.filename}</div>
        <div className={classes.icons}>
          {props.canDelete && (
            <div onClick={onClickDelete} className={classes.delete}>
              <Icon icon={'garbagecan'} size={18} color={'#dc0000'} />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
