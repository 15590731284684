export const genderOptions = [
  { label: 'männlich', value: 'male' },
  { label: 'weiblich', value: 'female' },
  { label: 'divers', value: 'other' },
];

export const nationalityOptions = [
  { label: 'Deutsch', value: 'german' },
  { label: 'Österreichisch', value: 'austrian' },
  { label: 'Schweizerisch', value: 'swiss' },
  { label: 'Belgisch', value: 'belgian' },
  { label: 'Britisch', value: 'british' },
  { label: 'Dänisch', value: 'danish' },
  { label: 'Finnisch', value: 'finnish' },
  { label: 'Französisch', value: 'french' },
  { label: 'Griechisch', value: 'greek' },
  { label: 'Italienisch ', value: 'italian' },
  { label: 'Niederländisch', value: 'dutch' },
  { label: 'Norwegisch', value: 'norwegian' },
  { label: 'Polnisch', value: 'polish' },
  { label: 'Portugiesisch', value: 'portuguese' },
  { label: 'Schwedisch', value: 'swedish' },
  { label: 'Spanisch', value: 'spanish' },
  { label: 'Tschechisch', value: 'czech' },
  { label: 'Türkisch', value: 'turkish' },
  { label: 'US-amerikanisch', value: 'american' },
  { label: 'Andere', value: 'other' },
];

export const preferredLanguageOptions = [
  { label: 'Deutsch', value: 'german' },
  { label: 'Englisch', value: 'english' },
];

export const insuranceOptions = [
  { label: 'privat', value: 'private' },
  { label: 'gesetzlich', value: 'statutory' },
  { label: 'keine', value: 'none' },
];

export const countryOptions = [
  { label: 'Deutschland', value: 'germany' },
  { label: 'Österreich', value: 'austria' },
  { label: 'Schweiz', value: 'switzerland' },
  { label: 'Belgien', value: 'belgium' },
  { label: 'Dänemark', value: 'denmark' },
  { label: 'Finnland', value: 'finland' },
  { label: 'Frankreich', value: 'france' },
  { label: 'Griechenland', value: 'greece' },
  { label: 'Italien', value: 'italy' },
  { label: 'Niederlande', value: 'netherlands' },
  { label: 'Norwegen', value: 'norway' },
  { label: 'Polen', value: 'poland' },
  { label: 'Portugal', value: 'portugal' },
  { label: 'Schweden', value: 'sweden' },
  { label: 'Spanien', value: 'spain' },
  { label: 'Tschechien', value: 'czechRepublic' },
  { label: 'Türkei', value: 'turkey' },
  { label: 'USA', value: 'unitedStates' },
  {
    label: 'Vereinigtes Königreich',
    value: 'unitedKingdom',
  },
];
