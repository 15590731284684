import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface AppDataState {
  isSelectingDocument?: boolean;
  navigationDrawerOpen?: boolean;
  isMobile?: boolean;
  isTablet?: boolean;
  isPhablet?: boolean;
  isDesktop?: boolean;
  isLaptopScreen?: boolean;
  isLargeScreen?: boolean;
  isMobileOrTablet?: boolean;
}

const initialState: AppDataState = {
  isSelectingDocument: false,
  navigationDrawerOpen: false,
  isMobile: false,
  isTablet: false,
  isPhablet: false,
  isDesktop: false,
  isLaptopScreen: false,
  isLargeScreen: false,
  isMobileOrTablet: false,
};

export const appDataSlice = createSlice({
  name: 'appData',
  initialState,
  reducers: {
    setAppData: (state, action: PayloadAction<AppDataState>) => {
      return action.payload;
    },
  },
});

export const { setAppData } = appDataSlice.actions;
export const selectAppData = (state: RootState) => state.appData;
