import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserService from '../../../services/User.service';
import { useAppSelector } from '../../../store/hooks';
import { selectAuth } from '../../../store/reducers';
import { Button, InputField, Label } from '../../atoms';
import { BaseHeader } from '../../organisms/BaseHeader/BaseHeader';
import classes from './ForgotPassword.module.scss';

export function ForgotPassword() {
  const navigate = useNavigate();
  const auth = useAppSelector(selectAuth);

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate('/dashboard');
    }

    return () => {};
  }, [auth.isAuthenticated]);

  const forgotPassword = async () => {
    setError('');
    const forgotData = {
      email,
    };

    const response = await UserService.forgotPassword(forgotData);

    if (!response.error) {
      setEmail('');
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        navigate('/login');
      }, 3000);
    }
    if (response.error) {
      setError('error');
      if (response.message.response?.data?.error) {
        setError('email');
      }
      console.error(response.message);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const emailFieldOnChange = (fieldText: string) => {
    setEmail(fieldText);
    setError('');
  };

  return (
    <React.Fragment>
      <BaseHeader />
      <div className={classes.ForgotPassword}>
        <div className={classes.container}>
          <div className={classes.info}>Bitte geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort neu zu setzen.</div>
          <div className={classes['email-field']}>
            <Label>E-Mail-Adresse</Label>
            <InputField
              onChange={setEmail}
              isEmail={true}
              value={email}
              onKeyPress={(event) => (event.nativeEvent.key === 'Enter' ? forgotPassword() : null)}
            />
            {error === 'email' && <div className={classes.error}>Die E-Mail-Adresse ist falsch.</div>}
            {error === 'error' && (
              <div className={classes.error}>Es ist ein Fehler aufgetreten, versuchen Sie es bitte erneut.</div>
            )}
          </div>
          <div className={classes.buttons}>
            {success && <div className={classes.success}>Erfolgreich</div>}
            <div className={classes['button-container']}>
              <Button onClick={forgotPassword}>Neues Passwort anfordern</Button>
            </div>
            <div className={classes['back-button']} onClick={() => navigate(-1)}>
              &#8592; Zurück
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
