// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Button, Headline, Icon } from '../../atoms';
import { Header } from '../../organisms/Header/Header';
import classes from './OnlineAppointmentSuccess.module.scss';

const cx = classNames.bind(classes);

export function OnlineAppointmentSuccess() {
  const navigate = useNavigate();
  const location = useLocation();

  if (!location.state) {
    return (
      <React.Fragment>
        <Header />
        <Headline level={3}>Diese Seite ist nicht mehr gültig.</Headline>
        <div className={classes['button-container']}>
          <Button onClick={() => navigate('/dashboard')}>Zum Dashboard</Button>
        </div>
      </React.Fragment>
    );
  }

  const onlineAppointmentClasses = cx({ OnlineAppointment: true });
  const stepsOnlineAppointmentClasses = cx({ OnlineAppointmentSuccess: true, StepsContainer: true });
  const infoTextClasses = cx({ InfoText: true });
  const appointmentSuccessContainerClasses = cx({ AppointmentSuccessContainer: true });
  const appointmentSuccessMainClasses = cx({ AppointmentSuccessMain: true });
  const appointmentSuccessInfoClasses = cx({ AppointmentSuccessInfo: true });
  const buttonConatinerClasses = cx({ ButtonContainer: true });

  const { appointmentData } = location.state;

  return (
    <React.Fragment>
      <Header barTitle='Videogespräch vereinbaren' showBackButton={true} />
      <div className={onlineAppointmentClasses}>
        <div className={stepsOnlineAppointmentClasses}>
          <Icon icon={'checkCircleFilled'} size={80} color={'#3db384'} />
          <div className={infoTextClasses}>Ihre Videosprechstunde wurde gebucht!</div>
          <div className={appointmentSuccessContainerClasses}>
            <div className={appointmentSuccessMainClasses}>
              Ihr Termin am{' '}
              {appointmentData &&
                new Date(appointmentData.startDate).toLocaleString('de-DE', {
                  month: 'long',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })}{' '}
              Uhr wurde erfolgreich gebucht. Eine Bestätigung wurde Ihnen soeben per E-Mail übermittelt.
            </div>
          </div>
          <div className={appointmentSuccessContainerClasses}>
            <div className={appointmentSuccessInfoClasses}>
              Auf Ihrem Dashboard werden Ihnen alle weiteren Informationen angezeigt. Bitte treten Sie der
              Videosprechstunde 5 Minuten vor dem Termin bei.
            </div>
          </div>
          <div className={buttonConatinerClasses}>
            <Button onClick={() => navigate('/dashboard')}>Zum Dashboard</Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
