/* eslint-disable eslint-comments/no-unlimited-disable */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

export const initialState: any = {
  generalSideEffects: [],
  preparationQuestion: [],
  psychologicalSideEffects: [],
};

export const followUpPrescriptionSlice = createSlice({
  name: 'prescriptionInfo',
  initialState,
  reducers: {
    setPrescriptionInfo: (state, action: PayloadAction<any[]>) => {
      /* eslint-disable */
      return action.payload;
      /* eslint-enable */
    },
  },
});

export const { setPrescriptionInfo } = followUpPrescriptionSlice.actions;
export const selectPrescriptionInfo = (state: RootState) => state.followUpPrescription;
