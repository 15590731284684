import React from 'react';

type MediaProps = {
  size?: number;
};

export function Media({ size = 15 }: MediaProps): JSX.Element {
  return (
    <svg width={size} height={size} viewBox='0 0 60 58'>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Anamnese-Splash-Screen' transform='translate(0.000000, -0.000013)' fill='#1D1D1B' fillRule='nonzero'>
          <g id='Element-2' transform='translate(0.000000, -0.000013)'>
            <path
              fill='#3db384'
              stroke='#3db384'
              strokeLinecap='round'
              strokeWidth='0.4'
              d='M58,16.0000126 C59.0543618,16.0000126 59.9181651,16.8158904 59.9945143,17.8507503 L60,18.0000126 L60,56.0000126 C60,57.0543744 59.1841222,57.9181777 58.1492623,57.9945269 L58,58.0000126 L2,58.0000126 C0.945638205,58.0000126 0.0818348781,57.1841348 0.00548573643,56.149275 L0,56.0000126 L0,18.0000126 C0,16.8954431 0.8954305,16.0000126 2,16.0000126 C3.0543618,16.0000126 3.91816512,16.8158904 3.99451426,17.8507503 L4,18.0000126 L4,54.0000126 L56,54.0000126 L56,18.0000126 C56,16.9456508 56.8158778,16.0818475 57.8507377,16.0054983 L58,16.0000126 Z'
              id='Line-2-Copy-2'
            ></path>
            <path
              fill='#3db384'
              stroke='#3db384'
              strokeLinecap='round'
              strokeWidth='0.4'
              d='M31.6834709,0.388129825 L31.820535,0.498536683 L44.3212271,11.4985367 C45.1504624,12.2282233 45.2311625,13.4919793 44.5014759,14.3212146 C43.8101938,15.1068059 42.6395808,15.2205817 41.8131997,14.6099515 L41.678798,14.5014633 L32.4999874,6.424 L32.5,27.6470714 C32.5,28.7516409 31.6045695,29.6470714 30.5,29.6470714 C29.4456382,29.6470714 28.5818349,28.8311936 28.5054857,27.7963338 L28.5,27.6470714 L28.4999874,6.424 L19.3213096,14.5013907 C18.5357562,15.192716 17.3601776,15.1567349 16.6181394,14.4459928 L16.4986218,14.321297 C15.8072966,13.5357437 15.8432777,12.3601651 16.5540198,11.6181268 L16.6787155,11.4986093 L29.1780234,0.498609253 C29.8889837,-0.127070466 30.9332658,-0.163902686 31.6834709,0.388129825 Z'
              id='Combined-Shape'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
