// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { globalStyles } from '../../../helper/GlobalStyles';
import { mergeStyles, todayStripped } from '../../../helper/UtilityFunctions';
import UserService from '../../../services/User.service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectAuth, selectUserInformation, selectWindowData, setUserInformation } from '../../../store/reducers';
import type { LifeQuality } from '../../../types/ninox.types';
import type { Tracking } from '../../../types/tracking.types';
import { Button, Icon, Label, Loading, Modal, Slider } from '../../atoms';
import InputLoader from '../../atoms/InputLoader/InputLoader';
import { BaseHeader } from '../../organisms/BaseHeader/BaseHeader';
import { Header } from '../../organisms/Header/Header';
import { styles as stylesFn } from './PatientenTracking.style';
import classes from './PatientenTracking.module.scss';

export function PatientenTracking() {
  const { isMobile, isTablet, isPhablet, isDesktop } = useAppSelector(selectWindowData);
  const styles = stylesFn(isMobile, isPhablet, isDesktop);
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSaving, setSaving] = useState<boolean>(false);
  const [mood, setMood] = useState(0);
  const [joyOfLife, setJoyOfLife] = useState(0);
  const [generalActivity, setGeneralActivity] = useState(0);
  const [appetite, setAppetite] = useState(0);
  const [sexuality, setSexuality] = useState(0);
  const [everydayWork, setEverydayWork] = useState(0);
  const [concentration, setConcentration] = useState(0);
  const [sleep, setSleep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');

  const [data, setData] = useState<Tracking>();

  const auth = useAppSelector(selectAuth);
  const userInformation = useAppSelector(selectUserInformation);
  const dispatch = useAppDispatch();

  const navigateTo = (routeName: string) => {
    navigate(routeName);
  };

  const getDataForTracking = () => {
    const token = searchParams?.get('token');

    if (token) {
      UserService.getDataForTracking(token).then((res) => {
        if (res.error) {
          setIsError(true);
          setError('error');
        }
        if (!res.error) {
          setData(res.data);
          setIsLoading(false);
        }
      });
    }
  };

  const resetData = () => {
    setMood(0);
    setJoyOfLife(0);
    setGeneralActivity(0);
    setAppetite(0);
    setSexuality(0);
    setEverydayWork(0);
    setConcentration(0);
    setSleep(0);
  };

  useEffect(() => {
    const token = searchParams?.get('token');

    if (token) {
      // for access via token
      UserService.checkTrackingToken(token).then((res) => {
        if (res.error) {
          setHasAccess(false);
          setIsLoading(false);
        }
        if (!res.error) {
          getDataForTracking();
        }
      });

      return;
    }

    if (auth.isAuthenticated) {
      // for acces via login
      setData({ lifeQuality: userInformation.lifeQuality });
      setIsLoading(false);
      setHasAccess(true);

      return;
    }
    setHasAccess(false);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (auth.isAuthenticated) {
      // for acces via login
      setData({ lifeQuality: userInformation.lifeQuality });
      setIsLoading(false);
      setHasAccess(true);
    }
  }, [auth.isAuthenticated, userInformation.lifeQuality]);

  const save = async () => {
    const token = searchParams?.get('token');

    setSaving(true);
    setError('');

    // for the backend, 0 has to be converted to 11
    const lifeQualityData: LifeQuality = {
      mood: mood === 0 ? 11 : mood,
      joyOfLife: joyOfLife === 0 ? 11 : joyOfLife,
      generalActivity: generalActivity === 0 ? 11 : generalActivity,
      appetite: appetite === 0 ? 11 : appetite,
      sexuality: sexuality === 0 ? 11 : sexuality,
      everydayWork: everydayWork === 0 ? 11 : everydayWork,
      concentration: concentration === 0 ? 11 : concentration,
      sleep: sleep === 0 ? 11 : sleep,
    };

    if (token) {
      // for access via token
      const requestData = {
        lifeQuality: lifeQualityData,
        token,
      };

      UserService.saveTrackingData(requestData).then((res) => {
        setSaving(false);
        if (res.error) {
          setIsError(true);
          if (res.message.response.status === 401) {
            setError('access');
          } else {
            setError('error');
          }
        }
        if (!res.error) {
          resetData();
          const redirectPage = searchParams?.get('redirectPage');

          if (redirectPage) {
            navigate({
              pathname: '/patienten-tracking/success',
              search: `?redirectPage=${redirectPage}`,
            });

            return;
          }

          navigate('/patienten-tracking/success');
        }
      });
    } else if (auth.isAuthenticated) {
      // for acces via login
      const requestData = {
        lifeQuality: [lifeQualityData],
      };

      const response = await UserService.update(requestData);
      if (response.error) {
        console.error(response.message);
      }
      if (!response.error) {
        requestData.lifeQuality[0].queryDate = todayStripped();
        requestData.lifeQuality[0].patientId = parseInt(userInformation.id, 10);

        const unserInfoPossiblyEmpty = userInformation || { lifeQuality: [] };

        const newUserInformation = {
          ...unserInfoPossiblyEmpty,
          lifeQuality: [...unserInfoPossiblyEmpty.lifeQuality, ...requestData.lifeQuality],
        };

        dispatch(setUserInformation(newUserInformation));

        setData({ lifeQuality: newUserInformation.lifeQuality });

        resetData();
        setSaving(false);

        const redirectPage = searchParams?.get('redirectPage');

        if (redirectPage) {
          navigate({
            pathname: '/patienten-tracking/success',
            search: `?redirectPage=${redirectPage}`,
          });

          return;
        }
        navigate('/patienten-tracking/success');
      }
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!hasAccess) {
    return (
      <React.Fragment>
        <BaseHeader />
        <div className={`${classes.PatientenTracking} view`} style={styles.container}>
          <div className='view' style={mergeStyles([styles.row, styles.justifyCenter, styles.marginBottom15])}>
            <div
              className='text'
              style={mergeStyles([styles.textAlignCenter, styles.fontSize120, styles.fontWeight100])}
            >
              403
            </div>
          </div>
          <div className='view' style={mergeStyles([styles.row, styles.justifyCenter, styles.marginBottom25])}>
            <div className='view' style={mergeStyles([styles.textAlignCenter, styles.fontBold, styles.fontSize24])}>
              Auf diese Seite darf nicht zugeriffen werden!
            </div>
          </div>
          <div className='view' style={mergeStyles([styles.row, styles.buttonContainer, styles.marginBottom0])}>
            <Button style={styles.button} onClick={() => navigateTo('/login')}>
              <div className='text' style={styles.buttonText}>
                Zum Login
              </div>
            </Button>
          </div>
          <div className='view' style={mergeStyles([styles.row, styles.buttonContainer])}>
            <Link to='/register'>
              <div style={styles.backButtonText}>Jetzt registrieren</div>
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  }

  const token = searchParams?.get('token');

  return (
    <React.Fragment>
      {token && !auth.isAuthenticated && <BaseHeader />}
      {auth.isAuthenticated && <Header />}
      <div className={`${classes.PatientenTracking} view`} style={styles.container}>
        <div className='view' style={styles.row}>
          <Modal
            modalVisible={isError && error === 'error'}
            setModalVisible={setIsError}
            disableSecondaryButton={true}
            cb={() => {
              setIsError(false);
            }}
            primaryButtonText='Schließen'
          >
            <div className='text' style={styles.textAlignCenter}>
              Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut. Wenn das Problem weiterhin besteht, wenden
              Sie sich bitte an den Support.
            </div>
          </Modal>
          <Modal
            modalVisible={isError && error === 'access'}
            setModalVisible={setIsError}
            disableSecondaryButton={true}
            cb={() => {
              setIsError(false);
              setHasAccess(false);
            }}
            primaryButtonText='Schließen'
          >
            <div className='text' style={styles.textAlignCenter}>
              Der Link ist in der Zeit abgelaufen.
            </div>
          </Modal>
          {(isPhablet || isDesktop) && <Icon icon={'healthTracking'} size={150} />}

          <div
            className='view'
            style={mergeStyles([
              isPhablet || isDesktop ? styles.marginLeft40 : {},
              !isPhablet && !isDesktop ? { width: '100%', marginBottom: 20 } : { marginBottom: 40 },
              isMobile || isTablet ? { marginBottom: 0 } : {},
            ])}
          >
            <div className='text' style={mergeStyles([styles.heading3, styles.marginBottom0])}>
              Therapie Monitoring
            </div>
            <div className='text' style={styles.heading2}>
              Wie geht es Ihnen heute?
            </div>
            <Label style={{ width: 'auto' }}>
              Wie schätzen Sie die folgenden Bereiche Ihres täglichen Lebens auf einer Skala von 0 (sehr schlecht) bis
              10 (sehr gut) ein?
            </Label>
          </div>
        </div>
        <div className='view'>
          <div
            className='view'
            style={mergeStyles([isPhablet || isDesktop ? styles.row : styles.column, styles.marginRow])}
          >
            <Label style={[styles.questionLabel, styles.marginTopText]}>Stimmung</Label>
            <Slider
              style={[styles.slider, isTablet ? { marginTop: 20 } : {}]}
              value={mood}
              minimumValue={0}
              maximumValue={10}
              showScale={true}
              step={1}
              onValueChange={(value) => setMood(value)}
            />
            {data?.lifeQuality && data.lifeQuality?.length > 0 && (
              <div
                className='view'
                style={mergeStyles([
                  styles.row,
                  styles.marginTopText,
                  styles.marginLeftText,
                  styles.flex025,
                  isPhablet || isDesktop ? {} : styles.marginTop25,
                ])}
              >
                <div className='text'>Zuletzt: </div>
                <div className='text' style={styles.fontBold}>
                  {data?.lifeQuality[data?.lifeQuality.length - 1]?.mood === 11
                    ? 0
                    : data?.lifeQuality[data?.lifeQuality.length - 1]?.mood}
                </div>
              </div>
            )}
          </div>

          <div
            className='view'
            style={mergeStyles([isPhablet || isDesktop ? styles.row : styles.column, styles.marginRow])}
          >
            <Label style={[styles.questionLabel, styles.marginTopText]}>Lebensfreude</Label>
            <Slider
              style={[styles.slider, isTablet ? { marginTop: 20 } : {}]}
              value={joyOfLife}
              minimumValue={0}
              maximumValue={10}
              showScale={true}
              step={1}
              onValueChange={(value) => setJoyOfLife(value)}
            />
            {data?.lifeQuality && data.lifeQuality?.length > 0 && (
              <div
                className='view'
                style={mergeStyles([
                  styles.row,
                  styles.marginTopText,
                  styles.marginLeftText,
                  styles.flex025,
                  isPhablet || isDesktop ? {} : styles.marginTop25,
                ])}
              >
                <div className='text'>Zuletzt: </div>
                <div className='text' style={styles.fontBold}>
                  {data?.lifeQuality[data?.lifeQuality.length - 1]?.joyOfLife === 11
                    ? 0
                    : data?.lifeQuality[data?.lifeQuality.length - 1]?.joyOfLife}
                </div>
              </div>
            )}
          </div>

          <div
            className='view'
            style={mergeStyles([isPhablet || isDesktop ? styles.row : styles.column, styles.marginRow])}
          >
            <Label style={[styles.questionLabel, styles.marginTopText]}>
              Allgemeine Aktivität / körperliche Belastbarkeit
            </Label>
            <Slider
              style={[styles.slider, isTablet ? { marginTop: 20 } : {}]}
              value={generalActivity}
              minimumValue={0}
              maximumValue={10}
              showScale={true}
              step={1}
              thumbTintColor='#3db384'
              minimumTrackTintColor='#c2c2c2'
              maximumTrackTintColor='#c2c2c2'
              onValueChange={(value) => setGeneralActivity(value)}
            />
            {data?.lifeQuality && data.lifeQuality?.length > 0 && (
              <div
                className='view'
                style={mergeStyles([
                  styles.row,
                  styles.marginTopText,
                  styles.marginLeftText,
                  styles.flex025,
                  isPhablet || isDesktop ? {} : styles.marginTop25,
                ])}
              >
                <div className='text'>Zuletzt: </div>
                <div className='text' style={styles.fontBold}>
                  {data?.lifeQuality[data?.lifeQuality.length - 1]?.generalActivity === 11
                    ? 0
                    : data?.lifeQuality[data?.lifeQuality.length - 1]?.generalActivity}
                </div>
              </div>
            )}
          </div>

          <div
            className='view'
            style={mergeStyles([isPhablet || isDesktop ? styles.row : styles.column, styles.marginRow])}
          >
            <Label style={[styles.questionLabel, styles.marginTopText]}>Appetit</Label>
            <Slider
              style={[styles.slider, isTablet ? { marginTop: 20 } : {}]}
              value={appetite}
              minimumValue={0}
              maximumValue={10}
              showScale={true}
              step={1}
              onValueChange={(value) => setAppetite(value)}
            />
            {data?.lifeQuality && data.lifeQuality?.length > 0 && (
              <div
                className='view'
                style={mergeStyles([
                  styles.row,
                  styles.marginTopText,
                  styles.marginLeftText,
                  styles.flex025,
                  isPhablet || isDesktop ? {} : styles.marginTop25,
                ])}
              >
                <div className='text'>Zuletzt: </div>
                <div className='text' style={styles.fontBold}>
                  {data?.lifeQuality[data?.lifeQuality.length - 1]?.appetite === 11
                    ? 0
                    : data?.lifeQuality[data?.lifeQuality.length - 1]?.appetite}
                </div>
              </div>
            )}
          </div>

          <div
            className='view'
            style={mergeStyles([isPhablet || isDesktop ? styles.row : styles.column, styles.marginRow])}
          >
            <Label style={[styles.questionLabel, styles.marginTopText]}>Sexualität</Label>
            <Slider
              style={[styles.slider, isTablet ? { marginTop: 20 } : {}]}
              value={sexuality}
              minimumValue={0}
              maximumValue={10}
              showScale={true}
              step={1}
              onValueChange={(value) => setSexuality(value)}
            />
            {data?.lifeQuality && data.lifeQuality?.length > 0 && (
              <div
                className='view'
                style={mergeStyles([
                  styles.row,
                  styles.marginTopText,
                  styles.marginLeftText,
                  styles.flex025,
                  isPhablet || isDesktop ? {} : styles.marginTop25,
                ])}
              >
                <div className='text'>Zuletzt: </div>
                <div className='text' style={styles.fontBold}>
                  {data?.lifeQuality[data?.lifeQuality.length - 1]?.sexuality === 11
                    ? 0
                    : data?.lifeQuality[data?.lifeQuality.length - 1]?.sexuality}
                </div>
              </div>
            )}
          </div>

          <div
            className='view'
            style={mergeStyles([isPhablet || isDesktop ? styles.row : styles.column, styles.marginRow])}
          >
            <Label style={[styles.questionLabel, styles.marginTopText]}>Beruf / Alltägliche Arbeit</Label>
            <Slider
              style={[styles.slider, isTablet ? { marginTop: 20 } : {}]}
              value={everydayWork}
              minimumValue={0}
              maximumValue={10}
              showScale={true}
              step={1}
              thumbTintColor='#3db384'
              minimumTrackTintColor='#c2c2c2'
              maximumTrackTintColor='#c2c2c2'
              onValueChange={(value) => setEverydayWork(value)}
            />
            {data?.lifeQuality && data.lifeQuality?.length > 0 && (
              <div
                className='view'
                style={mergeStyles([
                  styles.row,
                  styles.marginTopText,
                  styles.marginLeftText,
                  styles.flex025,
                  isPhablet || isDesktop ? {} : styles.marginTop25,
                ])}
              >
                <div className='text'>Zuletzt: </div>
                <div className='text' style={styles.fontBold}>
                  {data?.lifeQuality[data?.lifeQuality.length - 1]?.everydayWork === 11
                    ? 0
                    : data?.lifeQuality[data?.lifeQuality.length - 1]?.everydayWork}
                </div>
              </div>
            )}
          </div>

          <div
            className='view'
            style={mergeStyles([isPhablet || isDesktop ? styles.row : styles.column, styles.marginRow])}
          >
            <Label style={[styles.questionLabel, styles.marginTopText]}>Geistige Tätigkeiten / Konzentration</Label>
            <Slider
              style={[styles.slider, isTablet ? { marginTop: 20 } : {}]}
              value={concentration}
              minimumValue={0}
              maximumValue={10}
              showScale={true}
              step={1}
              thumbTintColor='#3db384'
              minimumTrackTintColor='#c2c2c2'
              maximumTrackTintColor='#c2c2c2'
              onValueChange={(value) => setConcentration(value)}
            />
            {data?.lifeQuality && data.lifeQuality?.length > 0 && (
              <div
                className='view'
                style={mergeStyles([
                  styles.row,
                  styles.marginTopText,
                  styles.marginLeftText,
                  styles.flex025,
                  isPhablet || isDesktop ? {} : styles.marginTop25,
                ])}
              >
                <div className='text'>Zuletzt: </div>
                <div className='text' style={styles.fontBold}>
                  {data?.lifeQuality[data?.lifeQuality.length - 1]?.concentration === 11
                    ? 0
                    : data?.lifeQuality[data?.lifeQuality.length - 1]?.concentration}
                </div>
              </div>
            )}
          </div>

          <div
            className='view'
            style={mergeStyles([isPhablet || isDesktop ? styles.row : styles.column, styles.marginRow])}
          >
            <Label style={[styles.questionLabel, styles.marginTopText]}>Schlaf</Label>
            <Slider
              style={[styles.slider, isTablet ? { marginTop: 20 } : {}]}
              value={sleep}
              minimumValue={0}
              maximumValue={10}
              showScale={true}
              step={1}
              thumbTintColor='#3db384'
              minimumTrackTintColor='#c2c2c2'
              maximumTrackTintColor='#c2c2c2'
              onValueChange={(value) => setSleep(value)}
            />
            {data?.lifeQuality && data.lifeQuality?.length > 0 && (
              <div
                className='view'
                style={mergeStyles([
                  styles.row,
                  styles.marginTopText,
                  styles.marginLeftText,
                  styles.flex025,
                  isPhablet || isDesktop ? {} : styles.marginTop25,
                ])}
              >
                <div className='text'>Zuletzt: </div>
                <div className='text' style={styles.fontBold}>
                  {data?.lifeQuality[data?.lifeQuality.length - 1]?.sleep === 11
                    ? 0
                    : data?.lifeQuality[data?.lifeQuality.length - 1]?.sleep}
                </div>
              </div>
            )}
          </div>

          <div className='view' style={mergeStyles([styles.row, styles.marginRow, styles.buttonContainer])}>
            <Button
              style={[isSaving ? styles.buttonLoading : styles.button, globalStyles.saveButtonContainer]}
              onClick={save}
            >
              {isSaving ? <InputLoader /> : null}
              <div className='text' style={styles.buttonText}>
                Absenden
              </div>
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
