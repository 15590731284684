/* eslint-disable eslint-comments/no-unlimited-disable */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { Pharmacy } from '../../types/ninox.types';
import type { RootState } from '../store';

const initialState: Pharmacy[] = [];

export const pharmacySlice = createSlice({
  name: 'pharmacy',
  initialState,
  reducers: {
    setPharmacies: (state, action: PayloadAction<Pharmacy[]>) => {
      /* eslint-disable */
      return action.payload;
      /* eslint-enable */
    },
  },
});

export const { setPharmacies } = pharmacySlice.actions;
export const selectPharmacies = (state: RootState) => state.pharmacy;
