import { configureStore } from '@reduxjs/toolkit';
import { appointmentSlice } from './reducers/appointmentReducer';
import { followUpPrescriptionSlice } from './reducers/followUpPrescriptionSlice';
import { pharmacySlice } from './reducers/pharmacyReducer';
import { appDataSlice, authSlice, fileSlice, termsSlice, userSlice, windowDataSlice } from './reducers';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    file: fileSlice.reducer,
    user: userSlice.reducer,
    appData: appDataSlice.reducer,
    pharmacy: pharmacySlice.reducer,
    appointment: appointmentSlice.reducer,
    followUpPrescription: followUpPrescriptionSlice.reducer,
    windowData: windowDataSlice.reducer,
    terms: termsSlice.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
