import React from 'react';
import classNames from 'classnames/bind';
import { Icon } from '../../atoms';
import { styles as inlineStyles } from './Checkbox.style';
import styles from './Checkbox.module.scss';

const cx = classNames.bind(styles);

type CheckboxProps = {
  checked: boolean;
  label: string;
  disabled?: boolean;
  onClick: () => void;
  extraClasses?: string;
};

export function Checkbox(props: CheckboxProps): JSX.Element {
  let icon;
  if (props.checked) {
    icon = <Icon icon={'checked'} />;
  } else {
    icon = <Icon icon={'unchecked'} />;
  }

  const onClick = () => {
    if (!props.disabled) {
      props.onClick();
    }
  };

  const inlineStylesForDiv = inlineStyles.row;

  if (props.disabled) {
    inlineStylesForDiv.opacity = 0.75;
  }

  let divClasses = cx('Checkbox');

  if (props.extraClasses) {
    divClasses += ` ${props.extraClasses}`;
  }

  return (
    <div className={divClasses} style={inlineStylesForDiv} onClick={onClick}>
      {icon}
      <div style={inlineStyles.text}>{props.label}</div>
    </div>
  );
}
