import type { CSSProperties } from 'react';

export const styles = (isDesktop: boolean) => {
  return {
    container: {
      flex: 1,
      height: '100%',
      marginTop: isDesktop ? 10 : 25,
      marginBottom: isDesktop ? 10 : 50,
    },
    whiteBackground: {
      backgroundColor: '#fff',
    },
    scrollview: {
      height: '100%',
      width: '100%',
      backgroundColor: '#fff',
    },
    heading1: {
      fontSize: 22,
      fontWeight: 'bold',
      marginTop: 45,
      color: '#000',
    },
    heading2: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 20,
      marginTop: isDesktop ? 65 : 45,
      color: '#000',
    },
    input: {
      marginRight: 0,
      width: '100%',
    },
    label: {
      marginBottom: 10,
    },
    streetField: {
      minWidth: 350,
      maxWidth: 350,
    },
    emailField: {
      minWidth: 450,
      maxWidth: 450,
    },
    emailFieldNotAllowed: {
      minWidth: 450,
      maxWidth: 450,
      cursor: 'not-allowed',
    },
    houseNumberInputField: {
      minWidth: 170,
      maxWidth: 170,
    },
    phoneNumberField: {
      minWidth: 350,
      maxWidth: 350,
    },
    countryInputField: {
      minWidth: 250,
      maxWidth: 250,
      maxHeight: 20,
    },
    picker: {
      width: '100%',
      paddingVertical: 10,
    },
    descriptionText: {
      color: '#000',
      fontSize: 16,
      lineHeight: '25px',
      marginBottom: 25,
    },
    marginRow: {
      marginTop: isDesktop ? 25 : 10,
      marginBottom: isDesktop ? 25 : 10,
    },
    bottomMinus38: {
      bottom: -38,
    },
    width250: {
      width: '250px',
    },
    buttonContainer: {
      justifyContent: isDesktop ? 'flex-start' : 'center',
      marginTop: 40,
      position: 'relative',
    },
    button: {
      backgroundColor: '#3db384',
      borderRadius: 24,
      paddingLeft: isDesktop ? 30 : 40,
      paddingRight: isDesktop ? 30 : 40,
      paddingTop: 10,
      paddingBottom: 10,
    },
    buttonText: {
      color: '#fff',
    },
    buttonLoading: {
      backgroundColor: 'rgba(61, 179, 132, .5)',
      borderRadius: 24,
      paddingLeft: isDesktop ? 30 : 40,
      paddingRight: isDesktop ? 30 : 40,
      paddingTop: 10,
      paddingBottom: 10,
    },
    saveMessageContainer: {
      marginHorizontal: 'auto',
      position: 'relative',
      minWidth: 151,
    },
    saveMessageText: {
      color: '#3db384',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 10,
    },
    errorText: {
      position: 'absolute',
      color: '#ff0000',
      bottom: -20,
      fontSize: isDesktop ? 14 : 12,
    },
  } as { [key: string]: CSSProperties };
};
