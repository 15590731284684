import type { CSSProperties } from 'react';

type ReturnType = {
  [key: string]: CSSProperties;
};

export const styles = (isDesktop: boolean): ReturnType => {
  return {
    container: {
      flex: 1,
      marginTop: 80,
      alignItems: 'center',
      marginBottom: 55,
    },
    containerMobile: {
      flex: 1,
      height: '100%',
      flexDirection: 'column',
      marginLeft: 10,
      marginRight: 10,
      width: '95%',
      marginTop: 50,
      alignItems: 'center',
    },
    alignCenter: {
      alignItems: 'center',
    },
    scrollview: {
      backgroundColor: '#fff',
      height: '100%',
      width: '100%',
    },
    heading3Green: {
      textAlign: 'center',
      fontSize: isDesktop ? 28 : 20,
      fontWeight: 'bold',
      marginTop: 20,
      marginBottom: 20,
      color: '#3db384',
    },
    heading3Black: {
      textAlign: 'center',
      fontSize: isDesktop ? 25 : 20,
      fontWeight: 'bold',
      marginTop: 20,
      marginBottom: 20,
      color: '#000',
    },
    heading4: {
      textAlign: 'center',
      fontSize: isDesktop ? 20 : 18,
      color: '#000',
      fontFamily: 'Montserrat-Regular',
    },
    textBold: {
      fontWeight: 'bold',
    },
    buttonContainer: {
      alignItems: 'center',
      justifyContent: 'space-around',
      marginTop: 60,
      marginBottom: 30,
      flexDirection: 'column',
    },
    button: {
      backgroundColor: '#3db384',
      borderRadius: 24,
      paddingLeft: 50,
      paddingRight: 50,
      paddingTop: 10,
      paddingBottom: 10,
    },
    buttonText: {
      fontSize: isDesktop ? 20 : 15,
      fontWeight: 'bold',
      color: '#fff',
    },
    morePharmaciesText: {
      color: '#3db384',
      textDecorationStyle: 'solid',
      textDecorationLine: 'underline',
      marginTop: '1%',
      fontFamily: 'Montserrat-Regular',
    },
  };
};
