import React from 'react';
import { format, setDefaultOptions } from 'date-fns';
import { de } from 'date-fns/locale';
import { useAppSelector } from '../../../store/hooks';
import { selectAuth } from '../../../store/reducers';
import { Icon } from '../../atoms';
import classes from './Header.module.scss';

export function BasicHeader(): JSX.Element {
  const auth = useAppSelector(selectAuth);

  const headerClassName = `${classes.BasicHeader} BasicHeader`;
  const patientInfoClassName = `${classes.PatientInfo} PatientInfo`;

  // set locale for date-fns
  setDefaultOptions({ locale: de });

  let patientName = '';
  let patientDob = '';
  let patient;
  const patientInfo = sessionStorage.getItem('patient');
  if (patientInfo !== null) {
    patient = JSON.parse(patientInfo);
    patientName = `${String(patient.firstname).trim()} ${String(patient.lastname).trim()}`;
    patientDob = patient.birthday;
  }

  return (
    <>
      {auth.isAuthenticated && (
        <div className={headerClassName}>
          <Icon icon='nowomed' size={200} />
          <div className={patientInfoClassName}>
            <b>{patientName}</b>
            <br />
            geb. {format(new Date(patientDob), 'PPP')}
          </div>
        </div>
      )}
    </>
  );
}
