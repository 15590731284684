import React from 'react';

type MailProps = {
  size?: number;
};

export function Mail({ size = 20 }: MailProps): JSX.Element {
  return (
    <svg width={size} height={size} viewBox='0 0 19 16'>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Anamnese-Splash-Screen' transform='translate(-51.000000, -532.000000)' fill='#1D1D1B' fillRule='nonzero'>
          <g id='Element-2' transform='translate(51.000000, 532.000000)'>
            <path
              fill='white'
              stroke='white'
              strokeLinecap='round'
              strokeWidth='0.4'
              d='M16.8095382,0 L2.19060548,0 C0.982718981,0 0,0.998184688 0,2.2250805 L0,13.7749195 C0,15.0018153 0.982718981,16 2.19060548,16 L16.8095382,16 C18.017281,16 19,15.0018153 19,13.7749195 L19,2.2250805 C19,0.998184688 18.017281,0 16.8095382,0 Z M11.8341552,7.55155396 L17.6553174,1.38394315 C17.8646787,1.60112385 17.9942901,1.8978499 17.9942901,2.2250805 L17.9942901,13.4672468 L11.8342989,7.55155396 L11.8341552,7.55155396 Z M1.34496998,1.38394315 L7.16584484,7.55155396 L1.00585361,13.4672468 L1.00585361,2.2250805 C1.00585361,1.8978499 1.13546504,1.60112385 1.34496998,1.38394315 Z M2.39637439,1.02168341 L16.6037693,1.02168341 L9.5,8.54842506 L2.39637439,1.02168341 Z M16.8095382,14.9783166 L2.19060548,14.9783166 C1.84200535,14.9783166 1.52904119,14.8236045 1.31206419,14.5792762 L7.86189554,8.28920938 L9.5,10.0249035 L11.1381045,8.28920938 L17.6879358,14.5794222 C17.4709588,14.8236045 17.1579946,14.9783166 16.8093945,14.9783166 L16.8095382,14.9783166 Z'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
