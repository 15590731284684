export const styles = {
  input: {
    backgroundColor: '#EFEFEE',
    color: '#000',
    borderRadius: 24,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 12,
    paddingRight: 12,
    minHeight: 36,
    marginTop: 6,
    flex: 1,
    webkitAppearance: 'none',
  },
  textarea: {
    paddingTop: 12,
    paddingBottom: 12,
    width: '100%',
    height: '6rem',
  },
  error: {
    borderColor: '#ff0000',
    borderWidth: 1,
  },
};
