import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { Term } from '../../types/terms.type';
import type { RootState } from '../store';

const initialState: Term[] = [];

export const termsSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {
    setTerms: (state, action: PayloadAction<Term[]>) => {
      return action.payload;
    },
  },
});

export const { setTerms } = termsSlice.actions;
export const selectTerms = (state: RootState) => state.terms;
