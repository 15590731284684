import React from 'react';

type CalendarProps = {
  size?: number;
  color?: string;
};

export function CalendarWithDays({ size = 20, color = '#fff' }: CalendarProps): JSX.Element {
  return (
    <svg width={size} height={size + 7} viewBox='0 0 78 85'>
      <g id='03-Termin-buchen' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='01-Step-1-Copy-14' transform='translate(-168.000000, -106.000000)' fill={color}>
          <g id='Group-10-Copy' transform='translate(168.000000, 106.000000)'>
            <g id='Group-9'>
              <path
                d='M17.7973902,0.173419101 C18.5770864,0.173419101 19.2178389,0.768307184 19.2905237,1.52895909 L19.2973902,1.6734191 L19.2965366,6.8794191 L56.6135366,6.8794191 L56.6144634,2.13971124 C56.6144634,1.31128411 57.2860363,0.639711236 58.1144634,0.639711236 C58.8941595,0.639711236 59.5349121,1.23459932 59.6075968,1.99525123 L59.6144634,2.13971124 L59.6135366,6.8794191 L69.3837805,6.87967416 C73.8661897,6.87967416 77.513704,10.4656822 77.6472599,14.9371078 L77.6510488,15.1911348 L77.6510488,76.6876067 C77.6510488,81.2772305 73.9509267,85 69.3837805,85 L69.3837805,85 L8.26680488,85 C3.6996587,85 -0.000463414634,81.2772305 -0.000463414634,76.6876067 L-0.000463414634,76.6876067 L-0.000463414634,15.1911348 C-0.000463414634,10.6022798 3.69982188,6.87967416 8.26680488,6.87967416 L8.26680488,6.87967416 L16.2965366,6.8794191 L16.2973902,1.6734191 C16.2973902,0.844991976 16.9689631,0.173419101 17.7973902,0.173419101 Z M75.6505366,22.2574191 L1.99953659,22.2574191 L1.99953659,76.6876067 C1.99953659,80.0978776 4.68358096,82.874134 8.03713935,82.9958391 L8.26680488,83 L69.3837805,83 C72.843616,83 75.6510488,80.1753838 75.6510488,76.6876067 L75.6510488,76.6876067 L75.6505366,22.2574191 Z M16.2965366,8.8794191 L8.26680488,8.87967416 C4.80709283,8.87967416 1.99953659,11.7041658 1.99953659,15.1911348 L1.99953659,15.1911348 L1.99953659,20.2574191 L75.6505366,20.2574191 L75.6510488,15.1911348 C75.6510488,11.781654 72.9668864,9.00553427 69.6134381,8.88383486 L69.3837805,8.87967416 L59.6135366,8.8794191 L59.6144634,15.195891 C59.6144634,16.0243181 58.9428905,16.695891 58.1144634,16.695891 C57.3347673,16.695891 56.6940147,16.1010029 56.62133,15.340351 L56.6144634,15.195891 L56.6135366,8.8794191 L19.2965366,8.8794191 L19.2973902,14.7295989 C19.2973902,15.558026 18.6258174,16.2295989 17.7973902,16.2295989 C17.0176941,16.2295989 16.3769416,15.6347108 16.3042568,14.8740589 L16.2973902,14.7295989 L16.2965366,8.8794191 Z'
                id='Combined-Shape'
                fillRule='nonzero'
              />
              <rect id='Rectangle' x='17' y='30.4516129' width='9.33333333' height='9.97554631' />
              <rect id='Rectangle-Copy-9' x='17' y='47.5525494' width='9.33333333' height='9.97554631' />
              <rect id='Rectangle-Copy-12' x='17' y='64.653486' width='9.33333333' height='9.97554631' />
              <rect id='Rectangle-Copy-2' x='34.3333333' y='30.4516129' width='9.33333333' height='9.97554631' />
              <rect id='Rectangle-Copy-10' x='34.3333333' y='47.5525494' width='9.33333333' height='9.97554631' />
              <rect id='Rectangle-Copy-13' x='34.3333333' y='64.653486' width='9.33333333' height='9.97554631' />
              <rect id='Rectangle-Copy-3' x='51.6666667' y='30.4516129' width='9.33333333' height='9.97554631' />
              <rect id='Rectangle-Copy-11' x='51.6666667' y='47.5525494' width='9.33333333' height='9.97554631' />
              <rect id='Rectangle-Copy-14' x='51.6666667' y='64.653486' width='9.33333333' height='9.97554631' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
