import React from 'react';

export function Nowomed({ size = 220 }): JSX.Element {
  return (
    <svg viewBox='0 0 610 140' height={size / 4.3137254902} width={size}>
      <path
        fill='#000'
        d='M7.9,60.2c0-20.3,12.1-30.8,30.2-30.8c18.1,0,30.2,10.5,30.2,30.8v31.9H60V60.2c0-15.1-8.7-23.1-21.9-23.1
	c-13.2,0-21.9,8-21.9,23.1v31.9H7.9V60.2z'
      />
      <path
        fill='#000'
        d='M77.9,61.6c0-18.6,13.6-32.2,32.9-32.2c19.3,0,32.9,13.6,32.9,32.2s-13.6,32.2-32.9,32.2C91.5,93.7,77.9,80.1,77.9,61.6z
	 M135.3,61.6c0-14.1-9.9-24.5-24.5-24.5S86.3,47.5,86.3,61.6s9.9,24.5,24.5,24.5S135.3,75.7,135.3,61.6z'
      />
      <path
        fill='#000'
        d='M153.4,66.8V31h8.3v35.7c0,13,7.2,19.3,17.1,19.3c9.9,0,17.3-6.6,17.3-19.3V31h8.3v35.7c0,12.7,7.3,19.3,17.2,19.3
	c9.9,0,17.1-6.3,17.1-19.3V31h8.3v35.7c0,18.2-10.6,27-24.7,27c-10,0-18.2-4.7-22-13.7c-4,9-12.1,13.7-22,13.7
	C164,93.7,153.4,84.9,153.4,66.8z'
      />
      <path
        fill='#000'
        d='M256.6,61.6c0-18.6,13.6-32.2,32.9-32.2c19.3,0,32.9,13.6,32.9,32.2s-13.6,32.2-32.9,32.2C270.2,93.7,256.6,80.1,256.6,61.6
	z M314,61.6c0-14.1-9.9-24.5-24.5-24.5c-14.6,0-24.5,10.4-24.5,24.5s9.9,24.5,24.5,24.5C304.1,86.1,314,75.7,314,61.6z'
      />
      <path
        fill='#3DB384'
        d='M332,57.2c0-19.2,11.1-27.8,25.6-27.8c9.2,0,16.7,3.8,21.2,11c4.3-7.2,11.9-11,21.2-11
	c14.5,0,25.6,8.7,25.6,27.8v34.9h-14.7V57.2c0-10-5.2-14.1-12.4-14.1c-7.1,0-12.2,4.2-12.2,14.1v34.9h-14.7V57.2
	c0-9.9-5.3-14.1-12.4-14.1c-7.2,0-12.4,4.1-12.4,14.1v34.9H332V57.2z'
      />
      <path
        fill='#3DB384'
        d='M435.2,61.6c0-18.6,13.6-32.2,32.9-32.2c19.3,0,32.7,13.6,32.7,32.2v5.2h-50.1c2,8.9,8.8,13.6,17.4,13.6
	c6.6,0,11.4-1.9,14.5-5.8h15.7c-4.6,11.6-15.7,19.2-30.2,19.2C448.8,93.7,435.2,80.1,435.2,61.6z M485.2,55
	c-2.3-8.2-9-12.2-17.1-12.2c-8.3,0-14.7,4.2-17.1,12.2H485.2z'
      />
      <path
        fill='#3CB482'
        d='M510.3,60.9c0-20.9,15.6-31.5,30.4-31.5c8.8,0,16.1,3.7,20.4,10.5V4.3h14.7v56.8c0,19.8-13.4,32.7-32.5,32.7
	C523.9,93.7,510.3,80.4,510.3,60.9z M561.1,61.6c0-10.8-7.2-18.4-17.9-18.4s-18.1,7.7-18.1,18.4S532.4,80,543.2,80
	S561.1,72.3,561.1,61.6z'
      />
      <g>
        <path
          fill='#000'
          d='M12,128.4l-2.2,4.9H6l9.5-21.6h3.9l9.5,21.6H25l-2.2-4.9H12z M21.4,125l-3.9-8.9l-3.9,8.9H21.4z'
        />
        <path fill='#000' d='M35.1,133.3v-21.6h3.6v18.1h9.8v3.5H35.1z' />
        <path fill='#000' d='M61.6,115.1v18.2H58v-18.2h-6.5v-3.3h16.7v3.3H61.6z' />
        <path fill='#000' d='M90.4,111.8v3.4H78.9v5.7h10.3v3.2H78.9v5.8h11.9v3.4H75.3v-21.6H90.4z' />
        <path
          fill='#000'
          d='M115.9,118.8c0,3.6-1.6,5.8-4.7,6.7l5.6,7.8h-4.6l-5.2-7.3h-4.8v7.3h-3.6v-21.6h8c3.3,0,5.6,0.6,7.1,1.7
		C115.1,114.5,115.9,116.3,115.9,118.8z M111,121.8c0.7-0.6,1.1-1.7,1.1-3.1c0-1.4-0.4-2.4-1.1-2.9c-0.8-0.5-2.1-0.8-4.1-0.8h-4.6
		v7.7h4.5C108.9,122.8,110.3,122.4,111,121.8z'
        />
        <path fill='#000' d='M140.4,111.8h3.6v21.6h-3.9l-12.2-15.7v15.7h-3.6v-21.6h3.6l12.5,16.1V111.8z' />
        <path
          fill='#000'
          d='M156.3,128.4l-2.2,4.9h-3.9l9.5-21.6h3.9l9.5,21.6h-3.9l-2.2-4.9H156.3z M165.6,125l-3.9-8.9l-3.9,8.9H165.6z'
        />
        <path fill='#000' d='M185.8,115.1v18.2h-3.6v-18.2h-6.5v-3.3h16.7v3.3H185.8z' />
        <path fill='#000' d='M199.4,111.8h3.6v21.6h-3.6V111.8z' />
        <path fill='#000' d='M222.1,133.3H218l-8.7-21.6h4.1l6.6,16.1l6.6-16.1h4.1L222.1,133.3z' />
        <path fill='#000' d='M252.1,111.8v3.4h-11.5v5.7H251v3.2h-10.3v5.8h11.9v3.4H237v-21.6H252.1z' />
        <path
          fill='#000'
          d='M275.6,133.3H272v-21.6h5.7l6.5,13.5l6.5-13.5h5.6v21.6h-3.6v-16.8l-7.5,15h-2l-7.5-15V133.3z'
        />
        <path fill='#000' d='M320.6,111.8v3.4h-11.5v5.7h10.3v3.2h-10.3v5.8H321v3.4h-15.5v-21.6H320.6z' />
        <path
          fill='#000'
          d='M345.2,114.6c2.1,1.9,3.1,4.5,3.1,7.9s-1,6-3,8c-2,2-5.1,2.9-9.3,2.9h-7.2v-21.6h7.4C340.1,111.8,343.1,112.7,345.2,114.6z
		 M344.6,122.5c0-4.9-2.8-7.4-8.5-7.4h-3.6v14.8h4c2.6,0,4.6-0.6,6-1.9C343.9,126.8,344.6,124.9,344.6,122.5z'
        />
        <path fill='#000' d='M356.1,111.8h3.6v21.6h-3.6V111.8z' />
        <path fill='#000' d='M380,115.1h-12.2v-3.3H385v2.8l-12.5,15.3v0.1h12.7v3.4h-17.8v-2.8L380,115.1L380,115.1z' />
        <path fill='#000' d='M392.9,111.8h3.6v21.6h-3.6V111.8z' />
        <path fill='#000' d='M421.9,111.8h3.6v21.6h-3.9l-12.2-15.7v15.7h-3.6v-21.6h3.6l12.5,16.1V111.8z' />
      </g>
      <g>
        <path
          fill='#000'
          d='M601.9,5.7c1.7,1,3,2.3,4,4c1,1.7,1.5,3.5,1.5,5.5c0,2-0.5,3.8-1.5,5.5c-1,1.7-2.3,3-4,4c-1.7,1-3.5,1.5-5.6,1.5
		c-2,0-3.8-0.5-5.5-1.5c-1.7-1-3-2.3-4-4c-1-1.7-1.4-3.5-1.4-5.5c0-2,0.5-3.8,1.5-5.5c1-1.7,2.3-3,4-4c1.7-1,3.5-1.5,5.6-1.5
		C598.4,4.3,600.2,4.8,601.9,5.7z M600.8,23c1.4-0.8,2.4-1.9,3.2-3.3c0.8-1.4,1.2-2.9,1.2-4.5s-0.4-3.1-1.2-4.4
		c-0.8-1.3-1.9-2.4-3.2-3.2c-1.4-0.8-2.9-1.2-4.5-1.2s-3.1,0.4-4.5,1.2c-1.4,0.8-2.5,1.9-3.2,3.3c-0.8,1.4-1.2,2.8-1.2,4.4
		c0,1.6,0.4,3.1,1.2,4.4c0.8,1.3,1.8,2.4,3.2,3.2s2.9,1.2,4.5,1.2C598,24.2,599.5,23.8,600.8,23z M600.5,15.4
		c-0.4,0.6-0.9,1-1.6,1.3l2.4,3.6h-2.3l-1.9-3.2h-0.4h-2.4v3.2h-2V9.6h4.4c1.4,0,2.5,0.3,3.3,1c0.8,0.6,1.1,1.5,1.1,2.7
		C601,14.1,600.8,14.8,600.5,15.4z M594.2,15.4h2.4c0.8,0,1.4-0.2,1.8-0.6c0.4-0.4,0.6-0.9,0.6-1.6c0-0.7-0.2-1.2-0.6-1.5
		c-0.4-0.3-1-0.5-1.8-0.5h-2.4V15.4z'
        />
      </g>
    </svg>
  );
}
