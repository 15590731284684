type CrossProps = {
  color?: string;
  size?: number;
};

export function Cross({ size = 12, color = '#10b981' }: CrossProps) {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' stroke='' role='img'>
      <g>
        <path
          fill={color}
          d='M12 9.77778L4.22222 2L2 4.22222L9.77778 12L2 19.7778L4.22222 22L12 14.2222L19.7778 22L22 19.7778L14.2222 12L22 4.22222L19.7778 2L12 9.77778Z'
          stroke=''
        ></path>
      </g>
    </svg>
  );
}
