import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { File } from '../../types/ninox.types';
import type { RootState } from '../store';

const initialState: File[] = [];

export const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    setFiles: (state, action: PayloadAction<File[]>) => {
      return action.payload;
    },
  },
});

export const { setFiles } = fileSlice.actions;
export const selectFiles = (state: RootState) => state.file;
