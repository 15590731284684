import type { CSSProperties } from 'react';

export const styles = {
  logo: {
    display: 'flex',
    marginTop: 30,
  } as CSSProperties,
  header: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 20,
    marginRight: 20,
    marginTop: 5,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
  } as CSSProperties,
  container: {
    flex: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
  } as CSSProperties,
  heading3: {
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 20,
    marginBottom: 20,
    color: '#000000',
  } as CSSProperties,
  diseaseBoxContainer: {
    marginTop: 30,
    marginBottom: 50,
  } as CSSProperties,
  row: {
    marginTop: 10,
  } as CSSProperties,
  marginRight0: {
    marginRight: 0,
  } as CSSProperties,
  alignSelfCenter: {
    alignSelf: 'center',
  } as CSSProperties,
  spaceBetween: {
    justifyContent: 'space-between',
  } as CSSProperties,
  buttonContainer: {
    paddingBottom: 50,
  } as CSSProperties,
  buttonWebContainer: {
    justifyContent: 'flex-start',
    paddingBottom: 50,
  } as CSSProperties,
  button: {} as CSSProperties,
  buttonText: {
    color: '#fff',
  } as CSSProperties,
  errorText: {
    position: 'absolute',
    color: '#ff0000',
  } as CSSProperties,
  errorTextPassword2: {
    position: 'absolute',
    color: '#ff0000',
  } as CSSProperties,
  errorTextCheckbox: {
    position: 'absolute',
    color: '#ff0000',
    bottom: -10,
  } as CSSProperties,
  errorTextBottom25: {
    bottom: 25,
  } as CSSProperties,
  emailField: {
    minWidth: 350,
  } as CSSProperties,
  phoneField: {
    minWidth: 250,
  } as CSSProperties,
  callContainer: {
    width: '100%',
    backgroundColor: '#f8f8f8',
    marginTop: 50,
    marginBottom: 20,
    paddingLeft: 20,
  } as CSSProperties,
  callLabel: {
    marginTop: 30,
    fontSize: 16,
  } as CSSProperties,
  passwordLabel: {
    fontSize: 18,
  } as CSSProperties,
  callCheckLabel: {
    marginTop: -15,
  } as CSSProperties,
  picker: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 12,
    paddingRight: 12,
    marginTop: 15,
  } as CSSProperties,
  input: {
    marginRight: 0,
  } as CSSProperties,
  passWebInput: {
    marginRight: 50,
    minWidth: 300,
  } as CSSProperties,
  label: {
    marginBottom: 10,
  } as CSSProperties,
  marginNegativeTop40: {
    marginTop: -40,
  } as CSSProperties,
  box: {
    display: 'flex',
    height: 290,
  } as CSSProperties,
  textJustify: {
    textAlign: 'justify',
  } as CSSProperties,
  buttonLoading: {
    cursor: 'not-allowed',
  } as CSSProperties,
};
