export enum ErrorMessages {
  PASSWORD_REQUIREMENTS = 'Mind. 8 Zeichen, 1 Großbuchstabe, 1 Kleinbuchstabe, 1 Ziffer',
  PASSWORD_EQUAL = 'Die Passwörter stimmen nicht überein.',
  EMPTY_FIELD = 'Bitte füllen Sie dieses Feld aus.',
  EMPTY_LEIDEN = 'Wählen Sie mindestens eine Erkrankung aus.',
  PHONE_EMPTY = 'Eine Nummer muss eingetragen werden.',
  PHONE_LONG = 'Rufnummer ist zu lang.',
  PHONE_SHORT = 'Rufnummer ist zu kurz.',
  EMAIL_REQUIREMENTS = 'Keine gültige E-Mail-Adresse',
  EMAIL_TAKEN = 'Diese E-Mail-Adresse wird schon verwendet.',
  REQUIRE_FIELD = 'Muss akzeptiert werden',
  ERROR = 'Bitte kontrollieren Sie Ihre Eingaben.',
  SHORT_DESCRIPTION = 'Bitte ausführlicher beschreiben',
}
