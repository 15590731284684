import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '..';
import classes from './BarTitle.module.scss';

type BarTitleProps = {
  title?: string;
};

export function BarTitle({ title }: BarTitleProps): JSX.Element {
  const navigate = useNavigate();

  const onClickIcon = () => {
    navigate('/dashboard');
  };

  return (
    <>
      {!title || title === '' ? (
        <div className={classes.BarTitleIcon}>
          <div className='clickable' onClick={onClickIcon}>
            <Icon icon='nowomed' size={200} />
          </div>
        </div>
      ) : (
        <div>{title}</div>
      )}
    </>
  );
}
