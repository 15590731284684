import type { CSSProperties } from 'react';

export const styles = {
  row: {
    flexDirection: 'row',
  } as CSSProperties,
  text: {
    alignSelf: 'center',
    marginLeft: 10,
    maxWidth: '80%',
  } as CSSProperties,
};
