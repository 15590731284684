import React from 'react';

type MedicationProps = {
  size?: number;
  color?: string;
};

export function Medication({ size = 20, color = '#fff' }: MedicationProps): JSX.Element {
  return (
    <svg width={size} height={size} viewBox='0 0 80 80'>
      <path
        d='M21.4,14.7h37.2V3.2H21.4v11.5ZM23.1,18c0,1.7,0,3.2,0,4.8,0,.8-.3,1.3-1.1,1.7-5.4,2.5-8.1,6.8-8.1,12.6,0,11.2,0,22.5,0,33.7,0,3.6,2.4,6,6,6,13.3,0,26.6,0,39.9,0,3.7,0,6.2-2.4,6.2-6,0-11.3,0-22.6,0-33.9,0-5.5-2.5-9.6-7.6-12.1-1.2-.6-1.7-1.2-1.6-2.5,0-1.4,0-2.9,0-4.3H23.1ZM10.8,34.7c.2-.7.3-1.4.5-2.2,1.2-4.5,3.9-7.9,8-10.2.5-.3.7-.5.7-1.1,0-1.1,0-2.1,0-3.3-1.7-.2-1.8-1.3-1.8-2.7,0-4.4,0-8.9,0-13.3,0-1.5.5-2,2-2,13.2,0,26.4,0,39.6,0,1.5,0,2.1.5,2.1,2,0,4.6,0,9.1,0,13.7,0,1.5-.3,1.8-1.8,2.3,0,1.2,0,2.4,0,3.6,0,.2.3.5.5.6,4.8,2.7,7.6,6.7,8.4,12,.2,1,.2,1.9.2,2.9,0,11.1,0,22.2,0,33.2,0,5.8-3.8,9.5-9.6,9.5-13,0-25.9,0-38.9,0-5.4,0-8.5-2.4-9.8-7.6,0,0,0,0-.1-.1v-37.6Z'
        fill={color}
        strokeWidth='0'
      />
      <path
        d='M43.2,62.9c0-3.1,0-6.1,0-9.1,0-1.8.5-2.3,2.3-2.3,2.7,0,5.3,0,8,0,.3,0,.7,0,1,0v-6h-1.1c-2.6,0-5.2,0-7.9,0-1.8,0-2.3-.5-2.3-2.3,0-2,0-4,0-6v-3.1h-6.6v1.1c0,2.8,0,5.6,0,8.3,0,1.4-.6,2-2,2-2.7,0-5.4,0-8.1,0h-1v6h1.1c2.6,0,5.2,0,7.9,0,1.6,0,2.1.5,2.1,2.1,0,2.7,0,5.5,0,8.2v1h6.6ZM33.4,42.2v-1.1c0-2.8,0-5.6,0-8.4,0-1.3.6-1.9,1.9-1.9,3.1,0,6.1,0,9.2,0,1.3,0,1.9.6,1.9,2,0,2.8,0,5.6,0,8.3v1.1h3c2.1,0,4.2,0,6.3,0,1.3,0,2,.6,2,1.9,0,2.9,0,5.8,0,8.7,0,1.3-.6,1.8-1.9,1.8-2.7,0-5.5,0-8.2,0h-1.1c0,.4,0,.7,0,1.1,0,2.7,0,5.5,0,8.2,0,1.6-.5,2.2-2.2,2.2-2.9,0-5.7,0-8.6,0-1.6,0-2.1-.5-2.1-2.2,0-2.7,0-5.4,0-8.1v-1.1h-1.1c-2.7,0-5.4,0-8.1,0-1.3,0-1.9-.6-1.9-1.9,0-2.9,0-5.8,0-8.7,0-1.3.6-1.9,1.9-1.9,2.7,0,5.4,0,8.1,0h1.1Z'
        fill={color}
        strokeWidth='0'
      />
    </svg>
  );
}
