import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { TERM } from '../../../../enums';
import { globalStyles } from '../../../../helper/GlobalStyles';
import { signOff } from '../../../../helper/UtilityFunctions';
import TermsService from '../../../../services/Terms.service';
import { Button, Headline, Icon, Label, Modal } from '../../../atoms';
import { Checkbox } from '../../../molecules/Checkbox/Checkbox';
import { ContractCheckbox } from '../../../organisms/ContractCheckbox/ContractCheckbox';
import { BasicHeader } from '../../../organisms/Header/BasicHeader';
import classes from './Step1.module.scss';

const cx = classNames.bind(classes);

const containerClasses = cx({ Container: true });
const iconContainerClasses = cx({ IconContainer: true });
const buttonConatinerClasses = cx({ ButtonContainer: true });
const agreementSectionClasses = cx({ AgreementSection: true });
const agreementContainerClasses = cx({ AgreementContainer: true });
const agreementClasses = cx({ Agreement: true });
const modalHeadlineClasses = cx({ ModalHeadline: true });
const modalContentClasses = cx({ ModalContent: true });

export function Step1() {
  const navigation = useNavigate();
  const [contractAccepted, setContractAccepted] = useState(false);
  const [agbs, setAgbs] = useState(false);
  // const [newsletter, setNewsletter] = useState(false);
  const [dataProtection, setDataProtection] = useState(false);
  const [modalHeadline, setModalHeadline] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  const doctorStamp = String(sessionStorage.getItem('doctorStamp'));
  const termsObject = sessionStorage.getItem('terms');
  const patientIdString = sessionStorage.getItem('patientId');
  const patientId = patientIdString !== null ? parseInt(patientIdString, 10) : -1;
  const terms = JSON.parse(termsObject === null ? '' : termsObject);

  useEffect(() => {
    const step1Data = sessionStorage.getItem('finishCheckIn-step1');
    if (step1Data !== null) {
      const parsedData = JSON.parse(step1Data);
      setContractAccepted(parsedData.contractAccepted);
    }
  }, []);

  const nextStep = async () => {
    const res = await TermsService.confirmTerms(patientId);
    signOff({ nav: () => navigation('/finish-checkin/step-2', { replace: true }) });
    console.log(res);
  };

  const openModal = (term: TERM) => {
    const content = terms.find((t: { type: TERM }) => t.type === term)?.termText || '';
    const headline = terms.find((t: { type: TERM }) => t.type === term)?.headline || '';
    setModalContent(content);
    setModalHeadline(headline);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalHeadline('');
    setModalContent('');
    setModalVisible(false);
  };

  return (
    <>
      <BasicHeader />
      <div className={containerClasses}>
        <Modal
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          cb={closeModal}
          disableSecondaryButton={true}
          primaryButtonText='Schließen'
        >
          <Headline className={modalHeadlineClasses} level={2}>
            {modalHeadline}
          </Headline>
          <div className={modalContentClasses} dangerouslySetInnerHTML={{ __html: modalContent }}></div>
        </Modal>

        <div className={iconContainerClasses}>
          <Icon icon='questionnaire' size={140} />
        </div>

        <div className={agreementSectionClasses}>
          <Headline level={3}>Bitte lesen und akzeptieren Sie den Behandlungsvertrag</Headline>
          <div className={agreementContainerClasses}>
            <ContractCheckbox checked={contractAccepted} setChecked={setContractAccepted} doctorStamp={doctorStamp} />
          </div>
        </div>

        <div className={agreementSectionClasses}>
          <Headline level={3}>AGB und DSB</Headline>
          <div className={agreementContainerClasses}>
            <Checkbox checked={agbs} label='' onClick={() => setAgbs(!agbs)} />
            <Label style={[globalStyles.marginBottom15]}>
              Ich stimme den{' '}
              <div className={agreementClasses} onClick={() => openModal(TERM.TERMS)}>
                allgemeinen Geschäftsbedingungen
              </div>{' '}
              zu.
            </Label>
          </div>

          <div className={agreementContainerClasses}>
            <Checkbox checked={dataProtection} label='' onClick={() => setDataProtection(!dataProtection)} />
            <Label style={[globalStyles.marginBottom15]}>
              Ich stimme den{' '}
              <div className={agreementClasses} onClick={() => openModal(TERM.PRIVACY_POLICY)}>
                Angaben zum Datenschutz
              </div>{' '}
              zu.
            </Label>
          </div>
        </div>

        <div className={buttonConatinerClasses}>
          <Button onClick={nextStep} disabled={!contractAccepted || !agbs || !dataProtection}>
            Weiter
          </Button>
        </div>
      </div>
    </>
  );
}
