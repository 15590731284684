import React from 'react';

export function HealthTracking({ size = 20, color = '#40b284' }): JSX.Element {
  return (
    <svg viewBox='0 0 160.725 139.784' width={size} height={size}>
      <path
        fill={color}
        d='M121.77,6.405c-10.824,0-21.534,6.179-32.741,18.892L87.9,26.583l-1.124-1.294C75.89,12.758,64.934,6.405,54.207,6.405A46.629,46.629,0,0,0,7.546,52.9c0,11.681,2.781,20.257,9.016,27.806l.064.083a3.5,3.5,0,0,0,2.541,1.256l.42.053.33.264a3.264,3.264,0,0,0,2.126.692h28.25a3.85,3.85,0,0,0,3.551-2.481l6.783-16.96,8.646,32.812c1.646,6.133,4.809,7.05,6.548,7.05h.34c2.068,0,4.95-1.1,6.937-6.349l7.848-21.05h26.571a3.776,3.776,0,0,0,0-7.551h-29.1A3.675,3.675,0,0,0,84.9,70.9L76.459,93.494l-8.89-32.2c-1.651-6.151-4.814-7.068-6.552-7.068l-.115,0c-1.712-.134-4.919.521-7.165,6.36L47.748,76.01H22.3l-.449-.622C17.117,68.836,15.1,62.11,15.1,52.9A38.985,38.985,0,0,1,54.04,13.955c9.538,0,20.188,7.263,30.8,21.005a4,4,0,0,0,5.981-.021C101.552,21.216,112.2,13.955,121.6,13.955A38.935,38.935,0,0,1,149.75,79.8L90.508,138.869,48.437,100.463a3.722,3.722,0,0,0-2.725-1.035,3.864,3.864,0,0,0-2.649,1.284,3.743,3.743,0,0,0-1.029,2.72,3.862,3.862,0,0,0,1.284,2.648L84.67,144.03a8.348,8.348,0,0,0,5.622,2.158,9.123,9.123,0,0,0,6.135-2.5l58.842-58.334A46.592,46.592,0,0,0,121.777,6.4Z'
        transform='translate(-7.546 -6.404)'
      />
    </svg>
  );
}
