import type { CSSProperties } from 'react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mergeStyles, signOff } from '../../../helper/UtilityFunctions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectAuth, setAuth, setUserInformation } from '../../../store/reducers';
import { initialUserInformation } from '../../../store/reducers/initialState';
import { Icon } from '../../atoms';
import { styles as inlineStyles } from './UserHeader.style';
import styles from './UserHeader.module.scss';

type UserHeaderProps = {
  doNotShowProfileLink?: boolean;
};

export function UserHeader(props: UserHeaderProps): JSX.Element {
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [isMyAccountHovered, setIsMyAccountHovered] = useState(false);
  const [isLogoutHovered, setIsLogoutHovered] = useState(false);
  const navigate = useNavigate();

  const chevronStyles: CSSProperties[] = [inlineStyles.chevron];

  if (showUserMenu) {
    chevronStyles.push(inlineStyles.chevronRotate);
  }

  const userMenuStyles: CSSProperties[] = [inlineStyles.userMenu as CSSProperties];

  if (showUserMenu) {
    userMenuStyles.push(inlineStyles.userMenuDisplay);
  }

  return (
    <div
      className={styles.UserHeader}
      style={mergeStyles([inlineStyles.row as CSSProperties, inlineStyles.user as CSSProperties])}
    >
      <div className={styles['menu-header']} onClick={() => setShowUserMenu(!showUserMenu)}>
        <Icon icon='user' />
        <div style={inlineStyles.userText}>{auth.name}</div>
        <div style={mergeStyles(chevronStyles)}>
          <Icon icon='chevron' />
        </div>
      </div>
      <div className={styles.menu} style={mergeStyles(userMenuStyles)}>
        {!props.doNotShowProfileLink && (
          <div
            onClick={() => {
              setShowUserMenu(false);
              navigate('/my-account');
            }}
            className={styles['menu-item']}
            style={{
              backgroundColor: isMyAccountHovered ? 'gainsboro' : 'white',
            }}
            onMouseEnter={() => {
              setIsMyAccountHovered(true);
            }}
            onMouseLeave={() => {
              setIsMyAccountHovered(false);
            }}
          >
            <div>Mein Profil</div>
          </div>
        )}
        <div
          onClick={() => {
            setShowUserMenu(false);
            const reduxState = {
              isAuthenticated: false,
              isTermsAgree: false,
              name: '',
              isPrivacyPolicyAgree: false,
            };
            dispatch(setAuth(reduxState));
            dispatch(setUserInformation(initialUserInformation));
            signOff({
              auth,
              nav: () => navigate('/login'),
            });
          }}
          className={styles['menu-item']}
          style={{
            backgroundColor: isLogoutHovered ? 'gainsboro' : 'white',
          }}
          onMouseEnter={() => {
            setIsLogoutHovered(true);
          }}
          onMouseLeave={() => {
            setIsLogoutHovered(false);
          }}
        >
          <div>Abmelden</div>
        </div>
      </div>
    </div>
  );
}
