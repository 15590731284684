import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { globalStyles } from '../../../../helper/GlobalStyles';
import { mergeStyles } from '../../../../helper/UtilityFunctions';
import { useAppSelector } from '../../../../store/hooks';
import { selectAuth, selectWindowData } from '../../../../store/reducers';
import { Button, Label } from '../../../atoms';
import { BaseHeader } from '../../../organisms/BaseHeader/BaseHeader';
import { styles } from './RegisterSuccess.style';
import classes from './RegisterSuccess.module.scss';

export function RegisterSuccess() {
  const navigate = useNavigate();

  const { isPhablet, isDesktop } = useAppSelector(selectWindowData);

  const auth = useAppSelector(selectAuth);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate('/dashboard');
    }

    return () => {};
  }, [auth.isAuthenticated]);

  const toLogin = () => navigate('/login');

  return (
    <div className={classes.RegisterSuccess} style={{ backgroundColor: 'white' }}>
      <BaseHeader />
      <div style={mergeStyles([styles.container, isDesktop ? { width: 1080 } : { width: '100%' }])}>
        <div className='text' style={mergeStyles([styles.heading3, { marginTop: 60 }])}>
          Vielen Dank für Ihre Registrierung
        </div>
        <Label style={[styles.introText, globalStyles.textBold, isPhablet || isDesktop ? { width: '70%' } : {}]}>
          Überprüfen Sie Ihren Posteingang, um sich einzuloggen.
        </Label>
        <Label style={[styles.introText, isPhablet || isDesktop ? { width: '70%' } : {}]}>
          Um die Einrichtung abzuschließen und sich einzuloggen, klicken Sie bitte auf den Verifizierungslink in der
          E-Mail, die wir an folgende Adresse gesendet haben: {searchParams.get('email')}
        </Label>

        <div style={mergeStyles([styles.row, styles.flexRowAlignLeft, styles.marginRow])}>
          <Button style={styles.button} onClick={toLogin}>
            <div className='text' style={styles.buttonText}>
              Zum Login
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
}
