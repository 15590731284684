import type { CSSProperties } from 'react';

type DrawerItemProps = {
  label: string;
  icon?: () => JSX.Element;
  labelStyle?: CSSProperties[] | CSSProperties;
};
// PressColor: #efefee
export function DrawerItem(props: DrawerItemProps) {
  let labelStyle;

  if (props.labelStyle) {
    if (Array.isArray(props.labelStyle)) {
      labelStyle = Object.assign({}, ...props.labelStyle);
    } else {
      labelStyle = props.labelStyle;
    }
  }

  return (
    <div>
      {props.icon && props.icon()}
      <div style={labelStyle}>{props.label}</div>
    </div>
  );
}
