import { styles } from './Loading.style';
import classes from './Loading.module.scss';

export function Loading(): JSX.Element {
  return (
    <div style={styles.container}>
      <div style={styles.loadingContainer}>
        <div className={`${classes['loading-dot']} ${classes['loading-dot-1']}`} style={styles.loadingDot}></div>
        <div className={`${classes['loading-dot']} ${classes['loading-dot-2']}`} style={styles.loadingDot}></div>
        <div className={`${classes['loading-dot']} ${classes['loading-dot-3']}`} style={styles.loadingDot}></div>
      </div>
    </div>
  );
}
