import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useAppSelector } from '../../../store/hooks';
import { selectUserInformation, selectWindowData } from '../../../store/reducers';
import { Button, Headline, Icon, Loading } from '../../atoms';
import { Header } from '../../organisms/Header/Header';
import { styles as stylesFn } from './MyPharmacy.styles';
import style from './MyPharmacy.module.scss';

const cx = classNames.bind(style);

export function MyPharmacy() {
  const { isDesktop } = useAppSelector(selectWindowData);
  const styles = stylesFn(isDesktop);

  const navigate = useNavigate();

  const userInformation = useAppSelector(selectUserInformation);

  // const myPharmacyClasses = cx({ MyPharmacy: true });
  const containerClasses = cx({ Container: true });
  const pharmacyNameClasses = cx({ PharmacyName: true });
  const pharmacyAddressClasses = cx({ PharmacyAddress: true });

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [street, setStreet] = useState('');
  const [houseNumber, setHouseNumber] = useState('');
  const [postcode, setPostcode] = useState('');
  const [id, setId] = useState<number | undefined>(undefined);

  const getUserData = async () => {
    setLoading(true);
    if (userInformation.patientPharmacyRelation !== undefined) {
      setName(userInformation.patientPharmacyRelation.name);
      setCity(userInformation.patientPharmacyRelation.city);
      setPostcode(userInformation.patientPharmacyRelation.postcode);
      setStreet(userInformation.patientPharmacyRelation.street);
      setHouseNumber(userInformation.patientPharmacyRelation.houseNumber || '');
      setId(userInformation.patientPharmacyRelation.id);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => getUserData())();
  }, [userInformation]);

  if (loading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Header />
      <div className={containerClasses}>
        <Icon icon='pharmacy' size={80} />
        <Headline level={3}>
          {id !== undefined ? 'Ihre aktuelle Apotheke ist' : 'Sie haben noch keine Apotheke gewählt.'}
        </Headline>
        {id !== undefined && (
          <>
            <div className={pharmacyNameClasses}>{name}</div>
            <div className={pharmacyAddressClasses}>
              <div>{(street ? `${street} ` : '') + (houseNumber ?? '')}</div>
              <div>{(postcode ? `${postcode} ` : '') + (city ?? '')}</div>
            </div>
          </>
        )}

        <div style={styles.buttonContainer}>
          <Button
            onClick={() => {
              navigate('/pharmacy-list');
            }}
          >
            {id !== undefined ? 'Apotheke ändern' : 'Apotheke wählen'}
          </Button>
        </div>
        <div>
          {id !== undefined
            ? 'Hinweis: Bevor Sie eine neue Apotheke wählen, stellen Sie bitte sicher, dass Ihre aktuelle Medikation lieferbar ist.'
            : 'Hinweis: Stellen Sie bitte sicher, dass Ihre aktuelle Medikation in der Apotheke lieferbar ist.'}
        </div>
      </div>
    </React.Fragment>
  );
}
