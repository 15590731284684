import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { isInObject } from '../../../../helper/UtilityFunctions';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectPrescriptionInfo, setPrescriptionInfo } from '../../../../store/reducers';
import type { ReducerAction } from '../../../../types/navigationIndex.types';
import { Button, Headline, InputField, RadioButton } from '../../../atoms';
import classes from './MedicalNews.module.scss';

const cx = classNames.bind(classes);

type MedicalNewsProps = {
  setCurrentPage: React.Dispatch<ReducerAction>;
};

export function MedicalNews({ setCurrentPage }: MedicalNewsProps) {
  const prescriptionInfo = useAppSelector(selectPrescriptionInfo);
  const [prescriptionTemp, setPrescriptionTemp] = useState(prescriptionInfo);

  const dispatch = useAppDispatch();

  const [newMedicines, setNewMedicines] = useState<boolean | undefined>();
  const [medicalNews, setMedicalNews] = useState<boolean | undefined>();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const areFieldUndefined =
      !isInObject(prescriptionTemp, 'medicalNews') || !isInObject(prescriptionTemp, 'newMedicines');
    const isNewMediciensTrueAndNoTextGiven = newMedicines === true && prescriptionTemp?.newMedicines?.length === 0;
    const isMedicalNewsTrueAndNoTextGiven = medicalNews === true && prescriptionTemp?.medicalNews?.length === 0;
    const hasError = areFieldUndefined || isNewMediciensTrueAndNoTextGiven || isMedicalNewsTrueAndNoTextGiven;
    if (hasError) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [prescriptionTemp, newMedicines, medicalNews]);

  useEffect(() => {
    if (isInObject(prescriptionInfo, 'medicalNews') && prescriptionInfo?.medicalNews?.length === 0) {
      setMedicalNews(false);
    }
    if (prescriptionInfo.medicalNews?.length > 0) {
      setMedicalNews(true);
    }
    if (isInObject(prescriptionInfo, 'newMedicines') && prescriptionInfo?.newMedicines?.length === 0) {
      setNewMedicines(false);
    }
    if (prescriptionInfo?.newMedicines?.length > 0) {
      setNewMedicines(true);
    }
  }, []);

  const handleBackButton = () => {
    const reducerType = {
      type: '[UPDATE]',
      payload: {
        followUpPrescriptionsIndex: 4,
      },
    };
    setCurrentPage(reducerType);
  };

  const next = () => {
    dispatch(setPrescriptionInfo(prescriptionTemp));

    const reducerType = {
      type: '[UPDATE]',
      payload: {
        followUpPrescriptionsIndex: 6,
      },
    };
    setCurrentPage(reducerType);
  };

  return (
    <div className={cx('MedicalNews')}>
      <Headline level={3}>Medizinische Neuigkeiten</Headline>
      <div className={cx('question', 'question-medical-news')}>
        <div className={cx('label')}>
          Gibt es medizinische Neuigkeiten bzw. waren Sie seit der letzten Sprechstunde in medizinischer Behandlung?
          (Neue Diagnosen, alternative Therapien, Schwangerschaft?)
        </div>
        <div className={cx('choices-and-textarea')}>
          <div className={cx('choices')}>
            <RadioButton
              extraClasses={cx('radio-button')}
              checked={medicalNews === true}
              value='yes'
              name='medical-news'
              id='medical-news-yes'
              onClick={() => setMedicalNews(true)}
            >
              Ja
            </RadioButton>
            <RadioButton
              extraClasses={cx('radio-button')}
              checked={medicalNews === false}
              value='no'
              name='medical-news'
              id='medical-news-no'
              onClick={() => {
                setMedicalNews(false);
                setPrescriptionTemp({
                  ...prescriptionTemp,
                  medicalNews: '',
                });
              }}
            >
              Nein
            </RadioButton>
          </div>
          {medicalNews && (
            <div className={cx('textarea-wrapper')}>
              <InputField
                multiline={true}
                onChange={(value) => {
                  setPrescriptionTemp({ ...prescriptionTemp, medicalNews: value });
                }}
                value={prescriptionTemp.medicalNews}
                placeholder='Beschreiben Sie diese bitte...'
              />
            </div>
          )}
        </div>
      </div>
      <div className={cx('question', 'question-new-medicines')}>
        <div className={cx('label')}>
          Haben Sie seit der letzten Sprechstunde neben der Cannabinoid-Therapie neue Medikamente gestartet oder Ihren
          Medikationsplan verändert?
        </div>
        <div className={cx('choices-and-textarea')}>
          <div className={cx('choices')}>
            <RadioButton
              extraClasses={cx('radio-button')}
              checked={newMedicines === true}
              value='yes'
              name='new-medicines'
              id='new-medicines-yes'
              onClick={() => setNewMedicines(true)}
            >
              Ja
            </RadioButton>
            <RadioButton
              extraClasses={cx('radio-button')}
              checked={newMedicines === false}
              value='no'
              name='new-medicines'
              id='new-medicines-no'
              onClick={() => {
                setNewMedicines(false);
                setPrescriptionTemp({
                  ...prescriptionTemp,
                  newMedicines: '',
                });
              }}
            >
              Nein
            </RadioButton>
          </div>
          {newMedicines && (
            <div className={cx('textarea-wrapper')}>
              <InputField
                multiline={true}
                onChange={(value) => {
                  setPrescriptionTemp({ ...prescriptionTemp, newMedicines: value });
                }}
                value={prescriptionTemp.newMedicines}
                placeholder='Beschreiben Sie diese bitte...'
              />
            </div>
          )}
        </div>
      </div>
      <div className={cx('button-container')}>
        <Button secondary onClick={handleBackButton}>
          Zurück
        </Button>
        <Button disabled={isButtonDisabled} onClick={next}>
          Weiter
        </Button>
      </div>
    </div>
  );
}
