import React from 'react';

type AttentionProps = {
  size?: number;
  color?: string;
};

export function Attention({ size = 25, color = 'orange' }: AttentionProps): JSX.Element {
  return (
    <svg width={size} height={size} viewBox='0 0 106 106'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g fill={color} fillRule='nonzero'>
          <path
            d='M53,0 C82.2710917,0 106,23.7289083 106,53 C106,82.2710917 82.2710917,106 53,106 C23.7289083,106 0,82.2710917 0,53 C0,23.7289083 23.7289083,0 53,0 Z M53,7 C27.5949015,7 7,27.5949015 7,53 C7,78.4050985 27.5949015,99 53,99 C78.4050985,99 99,78.4050985 99,53 C99,27.5949015 78.4050985,7 53,7 Z'
            id='Oval'
          />
          <path
            d='M53.5,34 C55.0512821,34 56.3557692,33.4554455 57.4134615,32.3663366 C58.4711538,31.2772277 59,29.9339934 59,28.3366337 C59,26.8844884 58.4711538,25.6320132 57.4134615,24.5792079 C56.3557692,23.5264026 55.0512821,23 53.5,23 C51.9487179,23 50.6442308,23.5445545 49.5865385,24.6336634 C48.5288462,25.7227723 48,26.9933993 48,28.4455446 C48,29.970297 48.5288462,31.2772277 49.5865385,32.3663366 C50.6442308,33.4554455 51.9487179,34 53.5,34 Z M57.7955,79 L57.7955,41 L49.2045,41 L49.2045,79 L57.7955,79 Z'
            id='i'
          />
        </g>
      </g>
    </svg>
  );
}
