import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BACKEND_URL;
axios.defaults.headers.common = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};

type Response = {
  error: boolean;
  message?: any;
  data?: any;
  status?: number;
};
export class QuestionnaireService {
  static async createQuestionnaire(data: any): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .post(
          '/questionnaire',
          { ...data.patientId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async saveOfflineQuestionnaire(data: any): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .post(
          '/questionnaire/checkin',
          { ...data },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }
}
