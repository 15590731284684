import React from 'react';

type CalendarProps = {
  size?: number;
  color?: string;
};

export function Calendar({ size = 20, color = '#fff' }: CalendarProps): JSX.Element {
  return (
    <svg viewBox='0 0 67.6 73.45' width={size} height={size}>
      <g id='Ebene_2' data-name='Ebene 2'>
        <g id='Ebene_1-2' data-name='Ebene 1'>
          <path
            fill={color}
            d='M58.76,5.85H52V1.9a1.5,1.5,0,1,0-3,0v4h-30V1.5a1.5,1.5,0,0,0-3,0V5.85H8.84A8.85,8.85,0,0,0,0,14.69V64.61a8.86,8.86,0,0,0,8.84,8.84H58.76a8.86,8.86,0,0,0,8.84-8.84V14.69A8.85,8.85,0,0,0,58.76,5.85Zm-49.92,2h7.23v4.9a1.5,1.5,0,0,0,3,0V7.85H49v5.3a1.5,1.5,0,0,0,3,0V7.85h6.73a6.85,6.85,0,0,1,6.84,6.84v2.69H2V14.69A6.85,6.85,0,0,1,8.84,7.85Zm49.92,63.6H8.84A6.85,6.85,0,0,1,2,64.61V19.38H65.6V64.61A6.85,6.85,0,0,1,58.76,71.45Z'
          />
        </g>
      </g>
    </svg>
  );
}
