import React from 'react';

type UserProps = {
  color?: string;
  size?: number; // string |
};

export function User({ color, size = 20 }: UserProps): JSX.Element {
  return (
    <svg viewBox='0 0 14.02 15.05' width={size} height={size}>
      <path
        d='M9.36 8.2a4.39 4.39 0 10-4.46-.08A7 7 0 000 14.77v.28h1v-.28a6 6 0 0112.06 0v.28h1a2.46 2.46 0 000-.28 7 7 0 00-4.7-6.57zM3.78 4.39a3.43 3.43 0 113.43 3.4 3.42 3.42 0 01-3.43-3.4z'
        fill={color}
      />
    </svg>
  );
}
