import type { CSSProperties } from 'react';
import React from 'react';
import { COLORS } from '../../../helper/GlobalStyles';
import { mergeStyles } from '../../../helper/UtilityFunctions';
import { Button } from '../Button/Button';
import classes from './Modal.module.scss';

type ModalProps = {
  className?: string;
  modalVisible: boolean;
  setModalVisible: (state: boolean) => void;
  cb: () => void;
  children: React.ReactNode | React.ReactNode[];
  primaryButtonText?: string;
  secondaryButtonText?: string;
  disableSecondaryButton?: boolean;
  disablePrimaryButton?: boolean;
  primaryButtonStyle?: CSSProperties[] | CSSProperties;
  secondaryButtonStyle?: CSSProperties[] | CSSProperties;
};

export function Modal(props: ModalProps) {
  let primaryButtonStyle: CSSProperties = {
    background: COLORS.green,
    border: `1px solid ${COLORS.green}`,
    color: '#ffffff',
    minWidth: 0,
  };

  if (props.primaryButtonStyle) {
    if (Array.isArray(props.primaryButtonStyle)) {
      primaryButtonStyle = mergeStyles([primaryButtonStyle, ...props.primaryButtonStyle]);
    } else {
      primaryButtonStyle = mergeStyles([primaryButtonStyle, props.primaryButtonStyle]);
    }
  }

  let secondaryButtonStyle: CSSProperties = {
    border: `1px solid ${COLORS.green}`,
    background: '#ffffff',
    color: COLORS.green,
    minWidth: 0,
  };

  if (props.secondaryButtonStyle) {
    if (Array.isArray(props.secondaryButtonStyle)) {
      secondaryButtonStyle = mergeStyles([secondaryButtonStyle, ...props.secondaryButtonStyle]);
    } else {
      secondaryButtonStyle = mergeStyles([secondaryButtonStyle, props.secondaryButtonStyle]);
    }
  }

  let modalClasses = classes.Modal;

  if (props.className) {
    modalClasses += ` ${props.className}`;
  }

  return (
    <React.Fragment>
      {props.modalVisible && (
        <div className={classes['modal-background']}>
          <div className={modalClasses}>
            <div className={classes.Content}>{props.children}</div>
            <div className={classes['button-container']}>
              {!props.disablePrimaryButton && (
                <Button style={primaryButtonStyle} onClick={props.cb}>
                  {props.primaryButtonText}
                </Button>
              )}
              {!props.disableSecondaryButton && (
                <Button style={secondaryButtonStyle} onClick={() => props.setModalVisible(false)}>
                  {props.secondaryButtonText}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
