import React from 'react';

type MigraineProps = {
  active: boolean;
};

export function Migraine({ active }: MigraineProps): JSX.Element {
  return (
    <svg viewBox='0 0 48.26 52.52'>
      <path
        stroke={active ? '#fff' : '#3db384'}
        fill='none'
        d='M24 43.51s-.09 5.86 4.79 5.86 9.57-.09 9.57-.09 2 .26 2.4-2.75.44-2.93.44-2.93l1.24-6.38 5.06-1-4.5-10.7s.36-8.6-1.59-13.12A18.62 18.62 0 0027.2 1.22 22.68 22.68 0 005.93 7.87C.7 13.55-.37 23 1.85 28.53S5.93 35 5.93 38.64v13.12M26.32 11.72a8.65 8.65 0 11-7.89.27'
      />
      <path stroke={active ? '#fff' : '#3db384'} fill='#fff' d='M22.64 21.53a2 2 0 112-2 2 2 0 01-2 2' />
    </svg>
  );
}
