import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorMessages } from '../../../enums';
import type { ReducerAction } from '../../../types/navigationIndex.types';
import type { Pharmacy } from '../../../types/ninox.types';
import { Button, Headline, Icon, InputField, Label } from '../../atoms';
import { Header } from '../../organisms/Header/Header';
import { PharmacyConfirmationModal } from './PharmacyConfirmation';
import classes from './PharmacyCreation.module.scss';

type PharmacyCreationProps = {
  setCurrentPage: React.Dispatch<ReducerAction>;
};

export const PharmacyCreation = (props: PharmacyCreationProps) => {
  const navigate = useNavigate();

  const { setCurrentPage } = props;
  const [confirmationModal, setConfirmationModal] = useState(false);

  const [name, setName] = useState('');
  const [street, setStreet] = useState('');
  const [houseNumber, setHouseNumber] = useState('');
  const [postcode, setPostcode] = useState('');
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('');
  const [error, setError] = useState({
    name: false,
    street: false,
    houseNumber: false,
    postcode: false,
    phone: false,
    phoneShort: false,
    phoneLong: false,
    city: false,
  });

  const pharmacy = {
    name,
    street,
    houseNumber,
    postcode,
    phone,
    city,
  };

  const validate = async () => {
    const newError = {
      name: name === '',
      street: street === '',
      houseNumber: houseNumber === '',
      postcode: postcode === '',
      phone: phone === '',
      phoneShort: phone.length < 6 && phone !== '',
      phoneLong: phone.length > 17,
      city: city === '',
    };
    setError(newError);

    return newError;
  };

  const clickNext = async () => {
    const newError = await validate();

    if (Object.values(newError).every((value) => value === false)) {
      setConfirmationModal(true);
    }
  };

  return (
    <React.Fragment>
      <Header />
      <PharmacyConfirmationModal
        pharmacy={pharmacy as Pharmacy}
        confirmationModal={confirmationModal}
        setCurrentPage={setCurrentPage}
        setConfirmationModal={setConfirmationModal}
      />
      <div className={classes.PharmacyCreation}>
        <div className={classes.container}>
          <div className={classes.header}>
            <Icon icon='pharmacy' size={90} />
            <Headline level={3}>Eigene Apotheke eingeben</Headline>
            <Headline level={4}>Bitte geben Sie die Details Ihrer Apotheke ein.</Headline>
          </div>
          <div className={classes['pharmacy-name']}>
            <Headline level={3}>Name der Apotheke</Headline>
            <Headline level={4}>Bitte geben Sie den Namen der Apotheke an.</Headline>
            <div className={classes['input-and-error']}>
              <InputField onChange={setName} value={name} />
              {error.name && <div className={classes.error}>{ErrorMessages.EMPTY_FIELD}</div>}
            </div>
          </div>
          <div className={classes['contact-fields']}>
            <Headline level={3}>Adresse / Kontakt</Headline>
            <Headline level={4}>Bitte geben Sie die Anschrift und Telefonnummer der Apotheke an.</Headline>
            <div className={classes['fields-grid']}>
              <div className={classes.street}>
                <Label>Straße</Label>
                <InputField onChange={setStreet} value={street} />
                {error.street && <div className={classes.error}>{ErrorMessages.EMPTY_FIELD}</div>}
              </div>
              <div className={classes['house-number']}>
                <Label>Hausnummer</Label>
                <InputField onChange={setHouseNumber} value={houseNumber} />
                {error.houseNumber && <div className={classes.error}>{ErrorMessages.EMPTY_FIELD}</div>}
              </div>
              <div className={classes.postcode}>
                <Label>PLZ</Label>
                <InputField onChange={setPostcode} value={postcode} />
                {error.postcode && <div className={classes.error}>{ErrorMessages.EMPTY_FIELD}</div>}
              </div>
              <div className={classes.city}>
                <Label>Ort</Label>
                <InputField onChange={setCity} value={city} />
                {error.city && <div className={classes.error}>{ErrorMessages.EMPTY_FIELD}</div>}
              </div>
              <div className={classes.phone}>
                <Label>Telefonnummer</Label>
                <InputField onChange={setPhone} value={phone} />
                {(error.phone || error.phoneShort || error.phoneLong) && (
                  <div className={classes.error}>
                    {error.phone && ErrorMessages.PHONE_EMPTY}
                    {error.phoneShort && ErrorMessages.PHONE_SHORT}
                    {error.phoneLong && ErrorMessages.PHONE_LONG}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={classes.buttons}>
            <Button onClick={clickNext}>Weiter</Button>
            <div className={classes['back-button']} onClick={() => navigate('/pharmacy-list')}>
              &#8592; Zurück
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
