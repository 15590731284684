import type { CSSProperties } from 'react';

export const styles = {
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'white',
    zIndex: 1000,
  },
  loadingContainer: {
    display: 'flex',
    width: 140,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  loadingDot: {
    height: 22,
    width: 22,
    borderRadius: 25,
    backgroundColor: '#3db384',
  },
} as { [key: string]: CSSProperties };
