import { Adhs } from './Icons/Adhs';
import { AnswerQuestion } from './Icons/AnswerQuestion';
import { Attention } from './Icons/Attention';
import { Calendar } from './Icons/Calendar';
import { CalendarWithDays } from './Icons/CalendarWithDays';
import { CheckCircle } from './Icons/CheckCircle';
import { CheckCircleFilled } from './Icons/CheckCircleFilled';
import { Checked } from './Icons/Checked';
import { Chevron } from './Icons/Chevron';
import { ChronicPains } from './Icons/ChronicPains';
import { Clock } from './Icons/Clock';
import { Cross } from './Icons/Cross';
import { Dashboard } from './Icons/Dashboard';
import { Depression } from './Icons/Depression';
import { Document } from './Icons/Document';
import { DocumentPlus } from './Icons/DocumentPlus';
import { Download } from './Icons/Download';
import { Eye } from './Icons/Eye';
import { GarbageCan } from './Icons/GarbageCan';
import { HealthTracking } from './Icons/HealthTracking';
import { Kontakt } from './Icons/Kontakt';
import { Logout } from './Icons/Logout';
import { Mail } from './Icons/Mail';
import { Marker } from './Icons/Marker';
import { Media } from './Icons/Media';
import { Medication } from './Icons/Medication';
import { MedikalKan } from './Icons/MedikalKan';
import { Menu } from './Icons/Menu';
import { Migraine } from './Icons/Migraine';
import { Nowomed } from './Icons/Nowomed';
import { Pharmacy } from './Icons/Pharmacy';
import { PharmacySmall } from './Icons/PharmacySmall';
import { Plus } from './Icons/Plus';
import { PremiumCheck } from './Icons/PremiumCheck';
import { Questionnaire } from './Icons/Questionnaire';
import { Register } from './Icons/Register';
import { SleepDisorders } from './Icons/SleepDisorders';
import { Social } from './Icons/Social';
import { StartTherapie } from './Icons/StartTherapie';
import { Telemedizin } from './Icons/Telemedizin';
import { TimelineChecked } from './Icons/TimelineChecked';
import { Unchecked } from './Icons/Unchecked';
import { User } from './Icons/User';
import { Verified } from './Icons/Verified';
import { Waiting } from './Icons/Waiting';

type IconProps = {
  active?: boolean;
  size?: number;
  color?: string;
  background?: boolean;
  icon:
    | 'none'
    | 'unchecked'
    | 'checked'
    | 'document'
    | 'document-plus'
    | 'download'
    | 'mail'
    | 'chronicPains'
    | 'sleepDisorders'
    | 'migraine'
    | 'adhs'
    | 'depression'
    | 'nowomed'
    | 'user'
    | 'social'
    | 'plus'
    | 'premiumCheck'
    | 'eye'
    | 'garbagecan'
    | 'timelineChecked'
    | 'chevron'
    | 'calendar'
    | 'calendarWithDays'
    | 'medikalKan'
    | 'telemedizin'
    | 'healthTracking'
    | 'dashboard'
    | 'kontakt'
    | 'pharmacy'
    | 'pharmacysmall'
    | 'logout'
    | 'menu'
    | 'startTherapie'
    | 'register'
    | 'answerQuestion'
    | 'marker'
    | 'clock'
    | 'checkCircleFilled'
    | 'checkCircle'
    | 'media'
    | 'verified'
    | 'attention'
    | 'cross'
    | 'questionnaire'
    | 'medication'
    | 'waiting';
};

export function Icon({ active = false, icon, size, color, background }: IconProps): JSX.Element {
  let iconComp;
  switch (icon) {
    case 'unchecked':
      iconComp = <Unchecked size={size} />;
      break;
    case 'checked':
      iconComp = <Checked size={size} color={color} background={background} />;
      break;
    case 'document':
      iconComp = <Document size={size} color={color} />;
      break;
    case 'document-plus':
      iconComp = <DocumentPlus size={size} color={color} />;
      break;
    case 'download':
      iconComp = <Download size={size} />;
      break;
    case 'mail':
      iconComp = <Mail size={size} />;
      break;
    case 'chronicPains':
      iconComp = <ChronicPains active={active} />;
      break;
    case 'sleepDisorders':
      iconComp = <SleepDisorders active={active} />;
      break;
    case 'migraine':
      iconComp = <Migraine active={active} />;
      break;
    case 'adhs':
      iconComp = <Adhs active={active} />;
      break;
    case 'depression':
      iconComp = <Depression active={active} />;
      break;
    case 'nowomed':
      iconComp = <Nowomed size={size} />;
      break;
    case 'media':
      iconComp = <Media size={size} />;
      break;
    case 'user':
      iconComp = <User size={size} color={color} />;
      break;
    case 'social':
      iconComp = <Social size={size} color={color} />;
      break;
    case 'plus':
      iconComp = <Plus size={size} />;
      break;
    case 'premiumCheck':
      iconComp = <PremiumCheck size={size} color={color} />;
      break;
    case 'eye':
      iconComp = <Eye size={size} />;
      break;
    case 'garbagecan':
      iconComp = <GarbageCan size={size} color={color} />;
      break;
    case 'timelineChecked':
      iconComp = <TimelineChecked size={size} />;
      break;
    case 'chevron':
      iconComp = <Chevron size={size} color={color} />;
      break;
    case 'calendar':
      iconComp = <Calendar size={size} color={color} />;
      break;
    case 'calendarWithDays':
      iconComp = <CalendarWithDays size={size} color={color} />;
      break;
    case 'medikalKan':
      iconComp = <MedikalKan size={size} />;
      break;
    case 'telemedizin':
      iconComp = <Telemedizin size={size} />;
      break;
    case 'healthTracking':
      iconComp = <HealthTracking size={size} color={color} />;
      break;
    case 'dashboard':
      iconComp = <Dashboard color={color} />;
      break;
    case 'kontakt':
      iconComp = <Kontakt color={color} size={size} />;
      break;
    case 'pharmacy':
      iconComp = <Pharmacy color={color} size={size} />;
      break;
    case 'pharmacysmall':
      iconComp = <PharmacySmall color={color} size={size} />;
      break;
    case 'logout':
      iconComp = <Logout color={color} />;
      break;
    case 'menu':
      iconComp = <Menu size={size} />;
      break;
    case 'startTherapie':
      iconComp = <StartTherapie size={size} />;
      break;
    case 'register':
      iconComp = <Register size={size} />;
      break;
    case 'answerQuestion':
      iconComp = <AnswerQuestion size={size} />;
      break;
    case 'marker':
      iconComp = <Marker color={color} size={size} />;
      break;
    case 'clock':
      iconComp = <Clock color={color} size={size} />;
      break;
    case 'checkCircleFilled':
      iconComp = <CheckCircleFilled color={color} size={size} />;
      break;
    case 'checkCircle':
      iconComp = <CheckCircle color={color} size={size} />;
      break;
    case 'verified':
      iconComp = <Verified color={color} size={size} />;
      break;
    case 'attention':
      iconComp = <Attention color={color} size={size} />;
      break;
    case 'cross':
      iconComp = <Cross size={size} color={color} />;
      break;
    case 'questionnaire':
      iconComp = <Questionnaire color={color} size={size} />;
      break;
    case 'waiting':
      iconComp = <Waiting color={color} size={size} />;
      break;
    case 'medication':
      iconComp = <Medication color={color} size={size} />;
      break;
    case 'none':
      iconComp = <div style={{ width: 50, height: 70 }} />;
      break;
    default:
      iconComp = <></>;
  }

  return iconComp;
}
