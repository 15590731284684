import type { CSSProperties } from 'react';
import React from 'react';
import ReactSlider from 'react-slider';
import { mergeStyles } from '../../../helper/UtilityFunctions';
import { styles } from './Slider.style';
import classes from './Slider.module.scss';

type SliderProps = {
  value: number;
  minimumValue: number;
  maximumValue: number;
  step: number;
  disabled?: boolean;
  thumbTintColor?: string;
  minimumTrackTintColor?: string;
  maximumTrackTintColor?: string;
  onValueChange: (value: number) => void;
  hasError?: boolean;
  disableFirstLabel?: boolean;
  style?: CSSProperties[] | CSSProperties;
  showScale?: boolean;
};

const range = (start: number, end: number) => Array.from({ length: end - start }, (v, k) => k + start);

const outputScale = (minimumValue: number, maximumValue: number) => {
  return range(minimumValue, maximumValue + 1).map((value, index) => {
    return (
      <div className='text' style={{ color: '#b3b3b3', width: 20, textAlign: 'center' }} key={index}>
        {value}
      </div>
    );
  });
};

export function Slider({
  value,
  minimumValue,
  maximumValue,
  step,
  disabled = false,
  // thumbTintColor = '#3db384',
  // minimumTrackTintColor = '#c2c2c2',
  // maximumTrackTintColor = '#c2c2c2',
  onValueChange,
  style,
  showScale = false,
}: SliderProps): JSX.Element {
  let effectiveStyles;

  if (style) {
    if (Array.isArray(style)) {
      effectiveStyles = mergeStyles(style);
    } else {
      effectiveStyles = style;
    }
  }

  return (
    <div style={effectiveStyles}>
      {showScale && <div style={styles.scale}>{outputScale(minimumValue, maximumValue)}</div>}
      <ReactSlider
        className={classes.Slider}
        marks={true}
        value={value}
        min={minimumValue}
        max={maximumValue}
        step={step}
        disabled={disabled}
        onChange={(newValue) => onValueChange(newValue)}
        markClassName={classes.mark}
        thumbClassName={classes.thumb}
        trackClassName={classes.track}
        renderThumb={(props) => <div {...props}></div>}
      />
    </div>
  );
}
