import React from 'react';

export function PharmacySmall({ size = 20, color = '#000' }): JSX.Element {
  return (
    <svg width={size} height={size} viewBox='0 0 31 31'>
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fill={color} fillRule='nonzero'>
          <path d='M15.98.238l.09.086 14.667 16a1 1 0 01-1.384 1.439l-.09-.087L27 15.207V30a1 1 0 01-.883.993L26 31H5a1 1 0 01-.993-.883L4 30V15.149l-2.255 2.518a1 1 0 01-1.314.156l-.098-.078a1 1 0 01-.156-1.314l.078-.098 14.334-16A1 1 0 0115.98.238zm-.638 2.251L5.995 12.922 6 13v16h19V13.025L15.342 2.489z' />
          <path d='M15.5 11.25a1 1 0 01.993.883l.007.117V17H21a1 1 0 01.117 1.993L21 19h-4.5v4.25a1 1 0 01-1.993.117l-.007-.117V19H10a1 1 0 01-.117-1.993L10 17h4.5v-4.75a1 1 0 011-1z' />
        </g>
      </g>
    </svg>
  );
}
