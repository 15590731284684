// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { signOff } from '../../../helper/UtilityFunctions';
import { useAppSelector } from '../../../store/hooks';
import { selectAuth, selectWindowData } from '../../../store/reducers';
import { Button, Icon, Text, View } from '../../atoms';
import { BaseHeader } from '../../organisms/BaseHeader/BaseHeader';
import { Header } from '../../organisms/Header/Header';
import { styles as stylesFn } from './TrackingSuccess.style';

export function TrackingSuccess() {
  const auth = useAppSelector(selectAuth);
  const { isMobile, isPhablet, isDesktop } = useAppSelector(selectWindowData);
  const styles = stylesFn(isMobile);

  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const navigateToLogin = () => {
    signOff({ nav: () => navigate('/login') });
  };

  const navigateAfterSubmission = () => {
    if (searchParams && searchParams.get('redirectPage')) {
      navigate(searchParams.get('redirectPage') as string);

      return;
    }
    navigate('/dashboard');
  };

  return (
    <React.Fragment>
      {!auth.isAuthenticated && <BaseHeader />}
      {auth.isAuthenticated && <Header />}
      <View style={[styles.container]}>
        <View style={[isPhablet || isDesktop ? styles.row : styles.alignCenter, { marginBottom: 40 }]}>
          <View>
            <Icon icon={'healthTracking'} size={100} />
          </View>
          <View style={[isPhablet || isDesktop ? styles.marginLeft20 : styles.alignCenter]}>
            <Text style={[styles.heading3]}>Therapie Monitoring</Text>
            <Text style={[styles.heading2]}>Vielen Dank für Ihre Angaben!</Text>
            <View style={[styles.row]}>
              {!auth.isAuthenticated && (
                <Button style={styles.button} onClick={() => navigateToLogin()}>
                  <Text style={[styles.buttonText]}>Zum Login</Text>
                </Button>
              )}
              {auth.isAuthenticated && (
                <Button style={styles.button} onClick={navigateAfterSubmission}>
                  <Text style={[styles.buttonText]}>
                    {searchParams?.get('redirectPage')
                      ? searchParams?.get('redirectPage') === '/profile/online-appointment'
                        ? 'Videosprechstunde buchen'
                        : searchParams?.get('redirectPage') === '/follow-prescription'
                          ? 'Folgerezept beantragen'
                          : ''
                      : 'Zum Dashboard'}
                  </Text>
                </Button>
              )}
            </View>
          </View>
        </View>
      </View>
    </React.Fragment>
  );
}
