import React from 'react';

type RegisterProps = {
  size?: number;
};

export function Register({ size = 20 }: RegisterProps): JSX.Element {
  return (
    <svg width={size} height={size} viewBox='0 0 234 234' enable-background='new 0 0 234 234'>
      <path
        fill='#3DB384'
        d='M186.5,114.5c0-11.579-2.862-22.497-7.892-32.109c-0.002,0.002-0.004,0.003-0.007,0.005
	c4.81-3.848,7.898-9.758,7.898-16.396c0-11.598-9.402-21-21-21c-6.636,0-12.543,3.086-16.391,7.892
	C139.497,47.862,128.579,45,117,45c-38.322,0-69.5,31.178-69.5,69.5S78.678,184,117,184c8.869,0,17.346-1.688,25.152-4.73
	l-0.764-2.911C133.833,179.35,125.607,181,117,181c-11.51,0-22.345-2.942-31.797-8.11c-1.09-7.316-1.227-12.388-1.228-12.442
	c-0.014-0.544-0.459-0.976-1-0.976c-0.008,0-0.016,0-0.024,0c-0.543,0.014-0.975,0.458-0.976,0.998c0,0.009,0,0.018,0,0.026
	c0.002,0.05,0.121,4.523,1.026,11.125c-6.255-3.737-11.841-8.475-16.551-13.986c-0.003-0.152-0.011-0.291-0.011-0.448
	c-0.001-4.845,1.402-12.196,8.932-16.769c1.213-0.735,2.271-1.289,3.231-1.69c1.938-0.811,3.131-0.824,4.781-0.842
	c2.095-0.023,4.702-0.052,9.783-1.657c11.231-3.546,13.431-10.095,13.519-10.372c0.031-0.098,0.047-0.2,0.047-0.303v-7.774
	c0-0.261-0.103-0.512-0.285-0.699c-3.006-3.076-5.149-6.921-6.381-11.458c-0.269-0.881-0.601-1.854-0.96-2.907
	c-1.385-4.054-3.264-9.553-3.262-15.595c0-0.22,0.002-0.441,0.008-0.663l-0.012-0.441c0-3.026,0.631-5.956,1.876-8.708
	c1.589-3.514,4.109-6.529,7.287-8.721c3.54-2.441,7.688-3.732,11.997-3.732c4.31,0,8.458,1.291,11.997,3.732
	c3.179,2.193,5.699,5.209,7.288,8.722c1.245,2.751,1.876,5.68,1.876,8.707l-0.012,0.488c0.006,0.226,0.008,0.452,0.008,0.676
	c-0.001,5.991-1.878,11.483-3.261,15.533c-0.36,1.054-0.692,2.027-0.97,2.938c-1.224,4.507-3.366,8.352-6.37,11.426
	c-0.01,0.01-0.02,0.02-0.028,0.03h-0.001c0,0,0,0.001-0.001,0.001c-0.08,0.089-0.142,0.188-0.184,0.294c0,0-0.001,0-0.001,0.001
	c0,0,0,0,0,0.001c-0.041,0.102-0.066,0.212-0.071,0.327c0,0,0,0.001,0,0.001c-0.001,0.015-0.001,0.03-0.001,0.044
	c0,0.001,0,0.003,0,0.004v7.771c0,0.103,0.016,0.205,0.047,0.303c0.088,0.277,2.287,6.826,13.519,10.372
	c5.081,1.605,7.688,1.634,9.782,1.657c1.65,0.018,2.843,0.031,4.781,0.842c0.964,0.403,2.021,0.956,3.231,1.69
	c7.526,4.57,8.932,11.916,8.933,16.761c0,0.16-0.009,0.301-0.012,0.456c-1.284,1.503-2.64,2.943-4.05,4.326l-27.267-15.271
	c-0.525-0.292-1.174-0.246-1.651,0.122c-0.476,0.368-0.686,0.985-0.533,1.567l14.25,54.323c0.151,0.575,0.628,1.007,1.215,1.101
	c0.079,0.013,0.158,0.019,0.236,0.019c0.504,0,0.981-0.254,1.261-0.687l6.915-10.716l4.488,5.802
	c0.508,0.654,1.448,0.774,2.104,0.269c0.655-0.507,0.775-1.449,0.269-2.104l-5.785-7.478c-0.298-0.385-0.794-0.604-1.25-0.581
	c-0.487,0.021-0.933,0.276-1.197,0.686l-6.218,9.636l-12.49-47.615l42.948,24.054l-11.267,3.723
	c-0.462,0.152-0.821,0.52-0.964,0.985s-0.05,0.972,0.248,1.356l5.867,7.584c0.508,0.655,1.448,0.774,2.104,0.269
	c0.655-0.507,0.775-1.449,0.269-2.104l-4.57-5.907l12.488-4.126c0.564-0.187,0.966-0.688,1.022-1.28s-0.241-1.162-0.76-1.452
	l-20.543-11.505l1.55,0.868C178.753,150.921,186.5,133.599,186.5,114.5z M155.5,65h9v-9c0-0.552,0.447-1,1-1s1,0.448,1,1v9h9
	c0.553,0,1,0.448,1,1s-0.447,1-1,1h-9v9c0,0.552-0.447,1-1,1s-1-0.448-1-1v-9h-9c-0.553,0-1-0.448-1-1S154.947,65,155.5,65z
	 M159.668,138.71c-1.297-0.787-2.44-1.385-3.498-1.827c-2.298-0.961-3.796-0.978-5.53-0.996c-1.963-0.021-4.404-0.049-9.203-1.563
	c-9.338-2.949-11.814-8.097-12.167-8.95v-7.189c3.097-3.271,5.312-7.307,6.578-11.978c0.264-0.862,0.589-1.814,0.941-2.845
	c1.43-4.187,3.37-9.865,3.369-16.151c-0.001-0.235-0.003-0.472-0.009-0.709l0.005-0.171c0.004-0.104,0.007-0.208,0.007-0.313
	c0-3.313-0.691-6.52-2.054-9.531c-1.738-3.844-4.496-7.144-7.975-9.544c-3.874-2.673-8.415-4.085-13.133-4.085
	c-4.717,0-9.258,1.413-13.132,4.085c-3.478,2.398-6.235,5.698-7.975,9.543c-1.362,3.012-2.054,6.219-2.054,9.532
	c0,0.116,0.004,0.23,0.008,0.344l0.004,0.099c-0.005,0.239-0.008,0.477-0.008,0.714c-0.001,6.324,1.938,12.002,3.369,16.189
	c0.352,1.03,0.677,1.981,0.932,2.813c1.273,4.698,3.489,8.735,6.587,12.007v7.188c-0.36,0.869-2.843,6.007-12.167,8.951
	c-4.799,1.515-7.241,1.542-9.203,1.563c-1.734,0.019-3.232,0.035-5.531,0.996c-1.055,0.441-2.198,1.038-3.497,1.827
	c-5.926,3.598-9.33,9.392-9.827,16.542C55.74,143.985,50.5,129.847,50.5,114.5C50.5,77.832,80.332,48,117,48
	c10.951,0,21.282,2.677,30.401,7.386c0,0,0-0.001,0.001-0.001C145.57,58.503,144.5,62.122,144.5,66c0,11.598,9.402,21,21,21
	c3.877,0,7.496-1.069,10.614-2.901c4.709,9.119,7.386,19.45,7.386,30.401c0,15.347-5.24,29.485-14.006,40.752
	C168.998,148.102,165.594,142.308,159.668,138.71z'
      />
    </svg>
  );
}
