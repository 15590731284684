import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { TERM } from '../../../enums/term.enum';
import { useAppSelector } from '../../../store/hooks';
import { selectTerms } from '../../../store/reducers';
import type { Term } from '../../../types/terms.type';
import { Headline } from '../../atoms';
import { Header } from '../../organisms/Header/Header';
import classes from './PrivacyPolicy.module.scss';

const cx = classNames.bind(classes);

export function PrivacyPolicy() {
  const terms = useAppSelector(selectTerms);
  const [termToDisplay, setTermToDisplay] = useState<Term>();

  useEffect(() => {
    const newTerm = terms.find((item) => item.type === TERM.PRIVACY_POLICY && item.active);
    setTermToDisplay(newTerm);
  }, [terms]);

  if (!termToDisplay) {
    return null;
  }

  return (
    <React.Fragment>
      <Header />
      <div className={cx('PrivacyPolicy')}>
        <Headline level={3}>{termToDisplay.headline}</Headline>
        <div className={cx('text')} dangerouslySetInnerHTML={{ __html: termToDisplay.termText }}></div>
      </div>
    </React.Fragment>
  );
}
