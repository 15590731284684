import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { Button } from '../../atoms';
import { TreatmentContract } from './TreatmentContract';
import classes from './ContractModal.module.scss';

const cx = classNames.bind(classes);

type ContractModalProps = {
  setChecked: (value: boolean) => void;
  modalVisible: boolean;
  setModalVisible: (value: boolean) => void;
  doctorStamp: string;
  setAlreadyAccepted: (value: boolean) => void;
};

export function ContractModal(props: ContractModalProps) {
  const [acceptAllowed, setAcceptAllowed] = useState(false);

  const accept = () => {
    props.setChecked(true);
    props.setAlreadyAccepted(true);
    props.setModalVisible(false);
  };

  const cancel = () => {
    props.setModalVisible(false);
  };

  const trackScrolling: React.UIEventHandler<HTMLDivElement> = (event) => {
    const { clientHeight, scrollHeight, scrollTop } = event.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      setAcceptAllowed(true);
    }
  };

  if (!props.modalVisible) {
    return null;
  }

  return (
    <div className={cx('ModalBackground')}>
      <div className={cx('ContractModal')}>
        <div className={cx('Content')} onScroll={trackScrolling}>
          <TreatmentContract doctorStamp={props.doctorStamp} />
        </div>
        <div className={cx('ButtonContainer')}>
          <div className={cx('Text')}>Lesen Sie sich den Text komplett durch, bevor Sie fortfahren.</div>
          <Button disabled={!acceptAllowed} onClick={accept}>
            Akzeptieren
          </Button>
          <div onClick={cancel} className={cx('Back')}>
            &#8592; Zurück
          </div>
        </div>
      </div>
    </div>
  );
}
