import React, { useEffect, useState } from 'react';
import { useAppDispatch } from './store/hooks';
import { setWindowData } from './store/reducers';
import { Router } from './router';
import './App.css';

function App() {
  const dispatch = useAppDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isFirstTime, setIsFirstTime] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userInfo, setUserInfo] = useState<unknown>(null);

  useEffect(() => {
    function handleResize() {
      const windowData = {
        isMobile: window.innerWidth < 480,
        isTablet: window.innerWidth >= 480 && window.innerWidth < 768,
        isPhablet: window.innerWidth >= 768 && window.innerWidth < 1280,
        isDesktop: window.innerWidth >= 1280,
        isLaptopScreen: window.innerWidth >= 1280 && window.innerWidth < 1800,
        isLargeScreen: window.innerWidth >= 1570,
        isMobileOrTablet: window.innerWidth < 768,
      };

      dispatch(setWindowData(windowData));
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const firstTime = localStorage.getItem('FirstTime');
    if (firstTime === null) {
      setIsFirstTime(true);
    }
  }, []);

  return (
    <div className='container'>
      <Router />
    </div>
  );
}

// eslint-disable-next-line import/no-default-export
export default App;
