import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ErrorMessages } from '../../../enums';
import UserService from '../../../services/User.service';
import { useAppSelector } from '../../../store/hooks';
import { selectAuth } from '../../../store/reducers';
import { Button, Headline, InputField, Label } from '../../atoms';
import { BaseHeader } from '../../organisms/BaseHeader/BaseHeader';
import classes from './ResetPassword.module.scss';

export function ResetPassword() {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const auth = useAppSelector(selectAuth);

  const [password, setPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const [error, setError] = useState({
    passwordReq: false,
    passwordEqual: false,
    emailTaken: false,
  });

  useEffect(() => {
    if (auth?.isAuthenticated) {
      navigate('/dashboard');
    }
    if (!searchParams?.get('token')) {
      navigate('/login');
    }
  }, []);

  const validate = () => {
    const regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/;
    const newError = {
      passwordReq: !regex.test(password) || password.length < 8,
      passwordEqual: password !== repeatedPassword,
    };
    setError({
      ...error,
      ...newError,
    });

    return newError;
  };

  const resetPassword = async () => {
    const newError = validate();
    if (Object.values(newError).find((e) => e)) {
      return;
    }

    const resp = await UserService.resetPassword({
      token: searchParams?.get('token'),
      password,
    });
    if (!resp.error) {
      navigate('/login');
    }
  };

  return (
    <React.Fragment>
      <BaseHeader />
      <div className={classes.ResetPassword}>
        <div className={classes.container}>
          <Headline level={3}>Passwort neu setzen</Headline>
          <div className={classes.info}>
            Bitte wählen Sie ein neues Passwort, mit dem Sie sich bei nowomed einloggen möchten.
          </div>
          <div className={classes['password-fields']}>
            <div className={classes['password-field']}>
              <Label>Passwort</Label>
              <InputField onChange={setPassword} secure={true} value={password} />
              {error.passwordReq && <div className={classes.error}>{ErrorMessages.PASSWORD_REQUIREMENTS}</div>}
            </div>
            <div className={classes['password-field']}>
              <Label>Passwort wiederholen</Label>
              <InputField onChange={setRepeatedPassword} secure={true} value={repeatedPassword} />
              {error.passwordEqual && <div className={classes.error}>{ErrorMessages.PASSWORD_EQUAL}</div>}
            </div>
          </div>
          <div className={classes['button-container']}>
            <Button onClick={resetPassword}>Passwort setzen</Button>
            {Object.values(error).find((e) => e) && <div className={classes.error}>{ErrorMessages.ERROR}</div>}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
