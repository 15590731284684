import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BACKEND_URL;
axios.defaults.headers.common = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};

type Response = {
  error: boolean;
  message?: any;
  data?: any;
};

// eslint-disable-next-line import/no-default-export
export default class AppointmentService {
  static async createAppointment(data: any): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .post('/appointment/patient', data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getAllAppointments(practiceId: number): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .get(`/appointment/checkinapp/${practiceId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  /**
   * Updates patient information for a specific appointment.
   *
   * @param appointmentId - The ID of the appointment for which the patient information should be updated.
   * @param infoText - The new information to be updated for the patient.
   * @returns A Promise that resolves to an object indicating whether an error occurred and containing the response data.
   */
  static async updatePatientInfo(appointmentId: number, infoText: string): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .put(`/appointment/update-patient-info/${appointmentId}`, infoText, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }
}
