import React from 'react';
import { useNavigate } from 'react-router-dom';
import PharmacyService from '../../../services/Pharmacy.service';
import type { ReducerAction } from '../../../types/navigationIndex.types';
import type { Pharmacy } from '../../../types/ninox.types';
import { Headline, Modal, Text, View } from '../../atoms';
import classes from './PharmacyConfirmation.module.scss';

type PharmacyConfirmationModalProps = {
  pharmacy: Pharmacy;
  confirmationModal: boolean;
  setConfirmationModal: (value: boolean) => void;
  setCurrentPage: React.Dispatch<ReducerAction>;
};

export const PharmacyConfirmationModal = ({
  pharmacy,
  confirmationModal,
  setConfirmationModal,
}: PharmacyConfirmationModalProps) => {
  const navigate = useNavigate();

  const chosePharmacy = async () => {
    const data = {
      pharmacyId: pharmacy.id,
      patientId: sessionStorage.getItem('patientId'),
    };
    await PharmacyService.savePatientPharmacyRelation(data);

    sessionStorage.setItem('pharmacyCategory', pharmacy.category.toString());

    setConfirmationModal(false);
    navigate('/product-selection', { replace: true });
  };

  return (
    <Modal
      className={classes.PharmacyConfirmationModal}
      modalVisible={confirmationModal}
      setModalVisible={setConfirmationModal}
      primaryButtonText='Diese Apotheke wählen'
      secondaryButtonText='Abbrechen'
      cb={chosePharmacy}
    >
      <Headline level={3}>Angaben prüfen</Headline>
      <Text className={classes.question}>Möchten Sie diese Apotheke wählen?</Text>
      <View>
        <Text className={classes['pharmacy-name']}>{pharmacy.name}</Text>
        <Text className={classes['pharmacy-address']}>
          {(pharmacy.street ? `${pharmacy.street} ` : '') + (pharmacy.houseNumber ?? '')}
        </Text>
        <Text className={classes['pharmacy-address']}>
          {(pharmacy.postcode ? `${pharmacy.postcode} ` : '') + (pharmacy.city ?? '')}
        </Text>
      </View>
    </Modal>
  );
};
