import type { CSSProperties } from 'react';
import React from 'react';
import { mergeStyles } from '../../../helper/UtilityFunctions';
import { styles as inlineStyles } from './Label.style';
import styles from './Label.module.scss';

type LabelProps = {
  children: React.ReactNode;
  style?: CSSProperties | CSSProperties[];
  className?: string;
};

export function Label({ children, className, style = [] }: LabelProps): JSX.Element {
  let effectiveStyles;

  if (Array.isArray(style)) {
    effectiveStyles = mergeStyles([inlineStyles.label, ...style]);
  } else {
    effectiveStyles = mergeStyles([inlineStyles.label, style]);
  }

  let effectiveClassName = styles.Label;

  if (className) {
    effectiveClassName += className;
  }

  return (
    <div className={effectiveClassName} style={effectiveStyles}>
      {children}
    </div>
  );
}
