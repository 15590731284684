import type { CSSProperties } from 'react';
import { isDesktop } from './Breakpoints';

export const COLORS = {
  black: '#000',
  white: '#fff',
  green: '#3db384',
};

export const globalStyles = {
  mobileToWebContainer: isDesktop
    ? ({
        maxWidth: '800px',
        flex: 1,
        alignSelf: 'center',
      } as CSSProperties)
    : ({} as CSSProperties),
  marginVertical0: {
    marginTop: 0,
    marginBottom: 0,
  } as CSSProperties,
  marginVertical15: {
    marginTop: 15,
    marginBottom: 15,
  } as CSSProperties,
  marginVertical25: {
    marginTop: 25,
    marginBottom: 25,
  } as CSSProperties,
  marginVertical35: {
    marginTop: 35,
    marginBottom: 35,
  } as CSSProperties,
  marginVertical40: {
    marginTop: 40,
    marginBottom: 40,
  } as CSSProperties,
  marginVertical50: {
    marginTop: 50,
    marginBottom: 50,
  } as CSSProperties,
  marginRow: {
    marginTop: 25,
    marginBottom: 25,
  } as CSSProperties,
  marginTop0: {
    marginTop: 0,
  } as CSSProperties,
  marginTop5: {
    marginTop: 5,
  } as CSSProperties,
  marginTop10: {
    marginTop: 10,
  } as CSSProperties,
  marginTop15: {
    marginTop: 15,
  } as CSSProperties,
  marginTop20: {
    marginTop: 20,
  } as CSSProperties,
  marginTop25: {
    marginTop: 25,
  } as CSSProperties,
  marginTop30: {
    marginTop: 30,
  } as CSSProperties,
  marginTop35: {
    marginTop: 35,
  } as CSSProperties,
  marginTop40: {
    marginTop: 40,
  } as CSSProperties,
  marginTop50: {
    marginTop: 50,
  } as CSSProperties,
  marginTop60: {
    marginTop: 60,
  } as CSSProperties,
  marginTop75: {
    marginTop: 75,
  } as CSSProperties,
  marginTop80: {
    marginTop: 80,
  } as CSSProperties,
  marginLeftMinus7: {
    marginLeft: -7,
  } as CSSProperties,
  marginLeft10: {
    marginLeft: 10,
  } as CSSProperties,
  marginLeft15: {
    marginLeft: 15,
  } as CSSProperties,
  marginLeft20: {
    marginLeft: 20,
  } as CSSProperties,
  marginLeft40: {
    marginLeft: 40,
  } as CSSProperties,
  marginRight10: {
    marginRight: 10,
  } as CSSProperties,
  marginLeft25: {
    marginLeft: 25,
  } as CSSProperties,
  marginRight0: {
    marginRight: 0,
  } as CSSProperties,
  marginRight25: {
    marginRight: 25,
  } as CSSProperties,
  marginRight50: {
    marginRight: 50,
  } as CSSProperties,
  marginBottom0: {
    marginBottom: 0,
  } as CSSProperties,
  marginBottom5: {
    marginBottom: 5,
  } as CSSProperties,
  marginBottom10: {
    marginBottom: 10,
  } as CSSProperties,
  marginBottom12: {
    marginBottom: 12,
  } as CSSProperties,
  marginBottom15: {
    marginBottom: 15,
  } as CSSProperties,
  marginBottom20: {
    marginBottom: 20,
  } as CSSProperties,
  marginBottom25: {
    marginBottom: 25,
  } as CSSProperties,
  marginBottom30: {
    marginBottom: 30,
  } as CSSProperties,
  marginBottom35: {
    marginBottom: 35,
  } as CSSProperties,
  marginBottom40: {
    marginBottom: 40,
  } as CSSProperties,
  marginBottom50: {
    marginBottom: 50,
  } as CSSProperties,
  marginBottom70: {
    marginBottom: 70,
  } as CSSProperties,
  paddingLeft60: {
    paddingLeft: 60,
  } as CSSProperties,
  paddingRight5: {
    paddingRight: 5,
  } as CSSProperties,
  paddingHorizontal4pct: {
    paddingLeft: '4%',
    paddingRight: '4%',
  } as CSSProperties,
  paddingHorizontal15: {
    paddingLeft: 15,
    paddingRight: 15,
  } as CSSProperties,
  paddingHorizontal25: {
    paddingLeft: 25,
    paddingRight: 25,
  } as CSSProperties,
  paddingHorizontal30: {
    paddingLeft: 30,
    paddingRight: 30,
  } as CSSProperties,
  paddingHorizontal100: {
    paddingLeft: 100,
    paddingRight: 100,
  } as CSSProperties,
  marginHorizontal15: {
    marginLeft: 15,
    marginRight: 15,
  } as CSSProperties,
  marginHorizontal25: {
    marginLeft: 25,
    marginRight: 25,
  } as CSSProperties,
  marginHorizontal35: {
    marginLeft: 35,
    marginRight: 35,
  } as CSSProperties,
  marginNegativeBottom20: {
    marginBottom: -20,
  } as CSSProperties,
  width100: {
    width: '100%',
  } as CSSProperties,
  width80: {
    width: '80%',
  } as CSSProperties,
  width50: {
    width: '50%',
  } as CSSProperties,
  width40: {
    width: '40%',
  } as CSSProperties,
  flex0: {
    flex: 0,
  } as CSSProperties,
  flex1: {
    flex: 1,
  } as CSSProperties,
  flex2: {
    flex: 2,
  } as CSSProperties,
  flex3: {
    flex: 3,
  } as CSSProperties,
  flex4: {
    flex: 4,
  } as CSSProperties,
  flex5: {
    flex: 5,
  } as CSSProperties,
  flex15: {
    flex: 1.5,
  } as CSSProperties,
  row: {
    flexDirection: 'row',
  } as CSSProperties,
  column: {
    flexDirection: 'column',
  } as CSSProperties,
  positionRelative: {
    position: 'relative',
  } as CSSProperties,
  positionAbsolute: {
    position: 'absolute',
  } as CSSProperties,
  justifyCenter: {
    justifyContent: 'center',
  } as CSSProperties,
  alignCenter: {
    alignItems: 'center',
  } as CSSProperties,
  alignTop: {
    alignItems: 'flex-start',
  } as CSSProperties,
  alignRight: {
    alignItems: 'flex-end',
  } as CSSProperties,
  modalContainer: {
    marginTop: 2,
    marginBottom: 2,
    marginLeft: 2,
    marginRight: 2,
  } as CSSProperties,
  modalContainerWeb: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
    margin: 'auto',
  } as CSSProperties,
  modalTitle: {
    fontSize: isDesktop ? 24 : 20,
    fontWeight: 'bold',
    marginBottom: isDesktop ? 24 : 16,
    color: '#000',
  } as CSSProperties,
  modalButtonsContainer: {
    flexDirection: isDesktop ? 'row' : 'column',
    marginTop: isDesktop ? 24 : 16,
    justifyContent: isDesktop ? 'flex-start' : 'space-around',
  } as CSSProperties,
  button: {
    backgroundColor: COLORS.green,
    borderRadius: 24,
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 10,
    paddingBottom: 10,
    marginRight: 10,
  } as CSSProperties,
  buttonSecondary: {
    backgroundColor: COLORS.white,
    borderColor: COLORS.green,
    borderWidth: 1,
    marginLeft: 10,
    marginRight: 0,
  } as CSSProperties,
  buttonTextStyle: {
    color: COLORS.white,
    textAlign: 'center',
  } as CSSProperties,
  buttonTextStyleSecondary: {
    color: COLORS.black,
    textAlign: 'center',
  } as CSSProperties,
  textBold: {
    fontWeight: 'bold',
  } as CSSProperties,
  greenText: {
    color: COLORS.green,
  } as CSSProperties,
  followUpRecipeButton: {
    borderRadius: 24,
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 15,
    height: 38,
    padding: isDesktop ? 12 : 0,
    marginRight: isDesktop ? 10 : 0,
  } as CSSProperties,
  followUpRecipeButtonPrimary: {
    backgroundColor: COLORS.green,
  } as CSSProperties,
  followUpRecipeButtonSecondary: {
    backgroundColor: COLORS.white,
    borderColor: COLORS.green,
    borderWidth: 1,
  } as CSSProperties,
  saveButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  } as CSSProperties,
  cookieText: {
    fontSize: 13,
  } as CSSProperties,
  underlined: {
    textDecorationLine: 'underline',
  } as CSSProperties,
};
