import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Button, Headline, InputField, Label, RadioButton } from '../../../atoms';
import { ProgressBar } from '../../../molecules';
import { BasicHeader } from '../../../organisms/Header/BasicHeader';
import style from './Step4.module.scss';

const cx = classNames.bind(style);
export function Step4(): JSX.Element {
  const navigation = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [takeOtherMedicines, setTakeOtherMedicines] = useState<undefined | boolean>();
  const [otherMedicines, setOtherMedicines] = useState('');

  useEffect(() => {
    const step3 = sessionStorage.getItem('questionnaire-step3');
    if (!step3) {
      navigation('/questionnaire/step-3', { replace: true });
    }
    const step4 = sessionStorage.getItem('questionnaire-step4');
    if (step4 !== null) {
      const parsedData = JSON.parse(step4);
      setTakeOtherMedicines(parsedData.takeOtherMedicines);
      setOtherMedicines(parsedData.otherMedicines);
    }
    setLoading(false);
  }, []);

  const isButtonDisabled = takeOtherMedicines === undefined || (takeOtherMedicines && otherMedicines === '');

  const nextStep = () => {
    const stepData = {
      takeOtherMedicines,
      otherMedicines: takeOtherMedicines ? otherMedicines : '',
    };
    sessionStorage.setItem('questionnaire-step4', JSON.stringify(stepData));
    navigation('/questionnaire/step-5', { replace: true });
  };

  const progressBarContainer = cx({ ProgressBarContainer: true });
  const questionContainerClasses = cx('flex', 'flex-column', 'mb-3');
  const questionLabelClasses = cx('mb-1');
  const radioContainerClasses = cx({ RadioContainer: true }, 'flex', 'mb-1');
  const buttonContainerClasses = cx('flex', 'flex-center', 'mt-5', 'mb-4');

  return (
    <>
      <BasicHeader />
      <div className={progressBarContainer}>
        <ProgressBar count={8} countActive={4} />
      </div>
      <div style={{ margin: '0 auto', maxWidth: '500px' }}>
        <div>
          <Headline level={3}>Ihre Medikation</Headline>
        </div>

        {!loading && (
          <>
            <div className={questionContainerClasses}>
              <div className={questionLabelClasses}>
                <Label>Nehmen Sie aktuell Medikamente ein?</Label>
              </div>
              <div className={radioContainerClasses}>
                <span>
                  <RadioButton
                    id={'takeOtherMedicinesTrue'}
                    name={'takeOtherMedicines'}
                    value={'true'}
                    onChange={($event) => setTakeOtherMedicines($event.target.value === 'true')}
                  >
                    Ja
                  </RadioButton>
                </span>
                <span>
                  <RadioButton
                    id={'takeOtherMedicinesFalse'}
                    name={'takeOtherMedicines'}
                    value={'false'}
                    onChange={($event) => setTakeOtherMedicines($event.target.value === 'true')}
                  >
                    Nein
                  </RadioButton>
                </span>
              </div>
              {takeOtherMedicines && (
                <div>
                  <InputField
                    type={'textarea'}
                    placeholder={'Bitte listen Sie hier alle Ihre Medikamente auf'}
                    onChange={setOtherMedicines}
                    value={otherMedicines}
                  />
                </div>
              )}
            </div>

            <div className={buttonContainerClasses}>
              <Button onClick={nextStep} disabled={isButtonDisabled}>
                Weiter
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
