import React from 'react';

export function DocumentPlus({ size = 80, color = '#3db384' }): JSX.Element {
  return (
    <svg width={size} height={size} viewBox='0 0 105.22 129.5'>
      <path
        fill={color}
        d='M104.51,35.42L69.79,.7c-.45-.45-1.06-.7-1.7-.7H17.24C7.73,0,0,7.73,0,17.23V112.27c0,9.5,7.73,17.23,17.24,17.23H87.97c9.51,0,17.24-7.74,17.24-17.24V37.12c0-.64-.25-1.25-.7-1.7h0ZM70.49,8.2l26.52,26.52h-14.08c-6.86,0-12.44-5.57-12.44-12.43V8.2Zm29.92,104.06c0,6.85-5.58,12.42-12.44,12.42H17.24c-6.86,0-12.43-5.57-12.43-12.42V17.23c0-6.85,5.58-12.43,12.43-12.43h48.45V22.29c0,9.5,7.74,17.23,17.25,17.23h17.48V112.26ZM36.62,45.82v-9.2h9.19c1.33,0,2.4-1.07,2.4-2.4s-1.07-2.4-2.4-2.4h-9.19v-9.18c0-1.33-1.07-2.4-2.4-2.4s-2.4,1.07-2.4,2.4v9.18h-9.18c-1.33,0-2.4,1.07-2.4,2.4s1.07,2.4,2.4,2.4h9.18v9.2c0,1.33,1.07,2.4,2.4,2.4s2.4-1.08,2.4-2.4Zm45.96,36.97H36.86c-1.33,0-2.4,1.08-2.4,2.4s1.07,2.4,2.4,2.4h45.72c1.33,0,2.4-1.08,2.4-2.4,0-1.33-1.08-2.4-2.4-2.4Zm-45.72-18.42c-1.33,0-2.4,1.07-2.4,2.4s1.07,2.4,2.4,2.4h45.72c1.33,0,2.4-1.08,2.4-2.4s-1.07-2.4-2.4-2.4H36.86Zm45.72,36.85H36.86c-1.33,0-2.4,1.07-2.4,2.4s1.07,2.4,2.4,2.4h45.72c1.33,0,2.4-1.07,2.4-2.4,0-1.33-1.08-2.4-2.4-2.4ZM22.63,63.57c-1.77,0-3.2,1.43-3.2,3.2s1.43,3.2,3.2,3.2,3.2-1.43,3.2-3.2-1.43-3.2-3.2-3.2Zm0,18.42c-1.77,0-3.2,1.43-3.2,3.2s1.43,3.2,3.2,3.2,3.2-1.43,3.2-3.2-1.43-3.2-3.2-3.2Zm0,18.42c-1.77,0-3.2,1.43-3.2,3.2s1.43,3.2,3.2,3.2,3.2-1.43,3.2-3.2-1.43-3.2-3.2-3.2Z'
      />
    </svg>
  );
}
