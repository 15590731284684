import React from 'react';
import classNames from 'classnames/bind';
import style from './CalendarDay.module.scss';

const cx = classNames.bind(style);

type CalendarDayProps = {
  children: React.ReactNode;
  isDatePassed: boolean;
  hasSlot: boolean;
  selectedDate: Date;
  calendarDate: Date;
  isSelected: boolean;
  isDoctorAbsent: boolean;
  onClick: () => void;
};

export function CalendarDay({
  children,
  isDatePassed,
  hasSlot,
  isSelected,
  isDoctorAbsent,
  onClick,
}: CalendarDayProps): JSX.Element {
  const dayStyle = {
    day: true,
    'passed-day': isDatePassed,
    'has-slot': hasSlot && !isDoctorAbsent,
    'is-selected': isSelected && !isDoctorAbsent,
    'is-selected-doctor-absent': isSelected && isDoctorAbsent,
    'doctor-absent': hasSlot && isDoctorAbsent,
  };

  const spanClasses = cx(dayStyle);

  const onClickDay = () => {
    if (hasSlot) {
      return onClick();
    }

    return null;
  };

  return (
    <div className={style.CalendarDay} onClick={onClickDay}>
      <div className={spanClasses}>{children}</div>
    </div>
  );
}
