import * as React from 'react';

type DashboardProps = {
  color?: string;
};

export function Dashboard({ color }: DashboardProps): JSX.Element {
  return (
    <svg width='25px' height='25px' viewBox='0 0 25 25'>
      <g stroke={color} strokeWidth={1.5} fill='none' fillRule='evenodd'>
        <path d='M0.75 0.75H10.25V13.25H0.75z' />
        <path d='M0.75 16.75H10.25V24.25H0.75z' />
        <path transform='rotate(-180 19.5 18)' d='M14.75 11.75H24.25V24.25H14.75z' />
        <path transform='rotate(-180 19.5 4.5)' d='M14.75 0.75H24.25V8.25H14.75z' />
      </g>
    </svg>
  );
}
