import React from 'react';

export function Eye({ size = 20 }): JSX.Element {
  return (
    <svg width={size} height={size} viewBox='0 0 19.86 13.57'>
      <path
        d='M.5 6.79S3.93.5 9.93.5s9.43 6.29 9.43 6.29-3.43 6.28-9.43 6.28S.5 6.79.5 6.79z'
        fill='none'
        stroke='#3db384'
      />
      <path
        d='M9.93 3.21a3.58 3.58 0 11-3.57 3.58 3.58 3.58 0 013.57-3.58m0-1a4.58 4.58 0 104.57 4.58 4.57 4.57 0 00-4.57-4.58z'
        fill='#3db384'
      />
    </svg>
  );
}
