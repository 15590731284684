import React from 'react';

type HeadlineProps = {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  children: string;
  center?: boolean;
  className?: string;
};

export function Headline({ level, children, center = false, className }: HeadlineProps): JSX.Element {
  if (level === 1) {
    return (
      <h1 className={className} style={center ? { textAlign: 'center' } : {}}>
        {children}
      </h1>
    );
  }
  if (level === 2) {
    return (
      <h2 className={className} style={center ? { textAlign: 'center' } : {}}>
        {children}
      </h2>
    );
  }
  if (level === 3) {
    return (
      <h3 className={className} style={center ? { textAlign: 'center' } : {}}>
        {children}
      </h3>
    );
  }
  if (level === 4) {
    return (
      <h4 className={className} style={center ? { textAlign: 'center' } : {}}>
        {children}
      </h4>
    );
  }
  if (level === 5) {
    return (
      <h4 className={className} style={center ? { textAlign: 'center' } : {}}>
        {children}
      </h4>
    );
  }
  if (level === 6) {
    return (
      <h4 className={className} style={center ? { textAlign: 'center' } : {}}>
        {children}
      </h4>
    );
  }

  return <></>;
}
