import type { CSSProperties } from 'react';
import { mergeStyles } from '../../../helper/UtilityFunctions';
import classes from './View.module.scss';

type ViewProps = {
  style?: CSSProperties[] | CSSProperties;
  className?: string;
  children?: React.ReactNode;
};

export function View(props: ViewProps) {
  let effectiveStyles;

  if (Array.isArray(props.style)) {
    effectiveStyles = mergeStyles(props.style);
  } else {
    effectiveStyles = props.style;
  }

  let effectiveClassName = classes.View;

  if (props.className) {
    effectiveClassName += ` ${props.className}`;
  }

  return (
    <div className={effectiveClassName} style={effectiveStyles}>
      {props.children}
    </div>
  );
}
