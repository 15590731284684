import type { CSSProperties } from 'react';

export const styles = {
  container: {
    flex: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,
    marginBottom: 20,
    paddingLeft: 25,
    paddingRight: 25,
  },
  row: {
    flexDirection: 'row',
  },
  marginRow: {
    marginTop: 25,
    marginBottom: 25,
  },
  heading: {
    fontSize: 32,
    fontWeight: 'bold',
    marginTop: 20,
    marginBottom: 20,
    color: '#000',
    textAlign: 'center',
  },
  heading3: {
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 20,
    marginBottom: 20,
    color: '#000',
  },
  introText: {
    marginBottom: 20,
    width: 'auto',
  },
  checkContainer: {
    width: 225,
    height: 225,
    marginTop: 50,
    marginBottom: 50,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  buttonContainer: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  button: {
    backgroundColor: '#3db384',
    borderRadius: 24,
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: 'center',
  },
  buttonText: {
    color: '#fff',
  },
  flexRowCenter: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexRowAlignLeft: {
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
} as { [key: string]: CSSProperties };
