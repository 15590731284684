export const initialUserInformation = {
  id: '',
  firstname: '',
  lastname: '',
  title: '',
  birthday: '',
  sex: '',
  nationality: '',
  language: '',
  mobile: '',
  landline: '',
  email: '',
  insurance: '',
  size: undefined,
  weight: undefined,
  hasAllergies: undefined,
  allergies: '',
  isSmoking: undefined,
  isAlreadyCannabisPatient: undefined,
  howOftenSmoking: '',
  smokingShisha: '',
  drinkingAlcohol: '',
  hasTakenNarcotics: undefined,
  narcotics: '',
  comment: '',
  createdAt: '',
  complaints: [],
  patientDataApproved: undefined,
  firstPresentation: {
    id: '',
    mainDisease: '',
    designation: '',
    sinceDisease: '',
    previousDoctors: '',
    previousDoctorsOther: '',
    hasMainDiagnosis: '',
    relatives: undefined,
    frequentComplaints: '',
    isUploadDocuments: false,
    patientId: '',
    locationOfPain: {
      id: '',
      head: '',
      neck: '',
      shoulder: '',
      chest: '',
      forearm: '',
      hand: '',
      belly: '',
      hipArea: '',
      knee: '',
      thigh: '',
      foot: '',
      backHead: '',
      cervicalSpine: '',
      thoracicSpine: '',
      backSpine: '',
      sacralJoint: '',
      firstPresentationId: '',
    },
  },
  secondaryDiagnosis: {
    id: '',
    hasOtherDiseases: undefined,
    movementDiseases: '',
    movementDiseasesOther: '',
    respiratoryDiseases: '',
    respiratoryDiseasesOther: '',
    cardiovascularDiseases: '',
    cardiovascularDiseasesOther: '',
    gastrointestinalDiseases: '',
    gastrointestinalDiseasesOther: '',
    neurologicalDiseases: '',
    neurologicalDiseasesOther: '',
    psychiatricDiseases: '',
    psychiatricDiseasesOther: '',
    infectiousDiseases: '',
    infectiousDiseasesOther: '',
    dermatology: '',
    dermatologyOther: '',
    gynecologicalAnamnesis: '',
    gynecologicalAnamnesisOther: '',
    cancerousDisease: undefined,
    cancerous: '',
    patientId: '',
  },
  therapy: {
    id: '',
    takeOtherMedicines: undefined,
    otherMedicines: '',
    takeOtherMedicinesOtherDiseases: undefined,
    otherMedicinesOtherDiseases: '',
    triedOtherDrugTherapies: undefined,
    otherDrugTherapies: '',
    hasInpatientTreatments: undefined,
    hasOperations: undefined,
    otherTreatmentMethods: '',
    otherTreatmentMethodsOther: '',
    patientId: '',
    medicationalEfficacy: [],
  },
  cannabisTherapy: {
    id: '',
    triedThcCannabis: undefined,
    hasRecommendationCannabisTherapy: undefined,
    hasMedicalCannabisTreatment: undefined,
    preparation: '',
    hasImprovedCondition: undefined,
    hasReductionGainfulEmployment: undefined,
    hasServereDisability: undefined,
    hasPensionApplication: undefined,
    isLongTermSickness: undefined,
    hasSupport: undefined,
    patientId: '',
  },
  additionalQuestions: {
    pains: undefined,
    hasSleepDisorder: undefined,
    previousTreatments: '',
    previousTreatmentsFurther: '',
    patientId: '',
  },
  file: [],
  lifeQuality: [],
  appointment: [],
  address: [],
  progress: {
    total: 0,
    firstPresentation: 0,
    secondaryDiagnosis: 0,
    documents: 0,
    medication: 0,
    lifeQuality: 0,
    furtherQuestion: 0,
    isDataConfirm: false,
    dataConfirmAt: '',
  },
  medication: [],
  availableFollowUpPrescription: undefined,
  followUpPrescriptionRequest: [],
  patientPharmacyRelation: {
    id: undefined,
    name: '',
    category: undefined,
    isPublic: undefined,
    image: '',
    isVerified: undefined,
    rating: undefined,
    city: '',
    postcode: '',
    street: '',
    houseNumber: '',
    phone: '',
    email: '',
    website: '',
  },
};
