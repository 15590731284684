import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface WindowDataState {
  isMobile: boolean;
  isTablet: boolean;
  isPhablet: boolean;
  isDesktop: boolean;
  isLaptopScreen: boolean;
  isLargeScreen: boolean;
  isMobileOrTablet: boolean;
}

const initialState: WindowDataState = {
  isMobile: false,
  isTablet: false,
  isPhablet: false,
  isDesktop: false,
  isLaptopScreen: false,
  isLargeScreen: false,
  isMobileOrTablet: false,
};

export const windowDataSlice = createSlice({
  name: 'windowData',
  initialState,
  reducers: {
    setWindowData: (state, action: PayloadAction<WindowDataState>) => {
      return action.payload;
    },
  },
});

export const { setWindowData } = windowDataSlice.actions;
export const selectWindowData = (state: RootState) => state.windowData;
