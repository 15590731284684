import React from 'react';

type DepressionProps = {
  active: boolean;
};

export function Depression({ active }: DepressionProps): JSX.Element {
  return (
    <svg viewBox='0 0 48.19 52.44'>
      <path
        stroke={active ? '#fff' : '#3db384'}
        strokeWidth={1.144}
        fill='none'
        d='M24 43.48s-.09 5.85 4.79 5.85 9.57-.09 9.57-.09 1.95.26 2.39-2.75.45-2.93.45-2.93l1.24-6.38 5-1L43 25.48s.35-8.6-1.6-13.12A18.6 18.6 0 0027.17 1.19 22.64 22.64 0 005.89 7.84C.66 13.51-.41 23 1.81 28.49S5.89 35 5.89 38.6v13.12M23.85 22.45L25.9 26M28.12 29.85l2.05 3.55M19.6 24.91l2.05 3.55M23.87 32.3l2.05 3.55M29.39 22.73l2.05 3.55M33.66 30.12l2.05 3.55M13.21 23.34l2.05 3.55M17.48 30.73l2.05 3.55'
      />
      <path
        stroke={active ? '#fff' : '#3db384'}
        strokeWidth={1.144}
        fill='none'
        d='M12.74 11.38a3.39 3.39 0 00-.45 0 4.13 4.13 0 00-1.63.33 4.39 4.39 0 00-1.34.92 4.3 4.3 0 00-.9 1.37 4.36 4.36 0 00.9 4.7 4.19 4.19 0 001.34.91 4.13 4.13 0 003.27 0 4 4 0 001.33-.91 4.46 4.46 0 00.9-1.37m12.63 0a4.46 4.46 0 00.9 1.37 4 4 0 001.33.91 4.13 4.13 0 003.27 0 4 4 0 001.33-.91 4.29 4.29 0 00.9-1.37 4.37 4.37 0 000-3.33 4.14 4.14 0 00-.9-1.36 4.23 4.23 0 00-1.33-.92 4.16 4.16 0 00-1.38-.33M18.43 8.68a4.13 4.13 0 00-3.27 0 4 4 0 00-1.33.91 4.46 4.46 0 00-.9 1.37 4.37 4.37 0 000 3.33m2.83-1.06a3.82 3.82 0 001 1.07 3.66 3.66 0 004.12 0M26.13 8a4.58 4.58 0 00-.63-.82 4.27 4.27 0 00-1.34-.92 4.1 4.1 0 00-3.26 0 4.27 4.27 0 00-1.34.92 4.35 4.35 0 00-.9 4.69m10.05 1a3.67 3.67 0 00-2.94 0 3.84 3.84 0 00-1.21.83 4 4 0 00-.81 1.22 4.1 4.1 0 00-.29 1.51m8.22-2a4.46 4.46 0 00.9-1.37 4.37 4.37 0 000-3.33 4.3 4.3 0 00-.9-1.36 4.23 4.23 0 00-1.33-.92 4.13 4.13 0 00-3.27 0 4.23 4.23 0 00-1.33.92 4.14 4.14 0 00-.9 1.36 4.37 4.37 0 000 3.33m-12.56 6.9a4 4 0 01-1.63-.34 4 4 0 01-1.33-.91A4.33 4.33 0 018.42 14a4.33 4.33 0 01.91-1.36 4.23 4.23 0 011.33-.92 4.13 4.13 0 011.63-.33h.5a4.33 4.33 0 01.58-1.23 4.41 4.41 0 01.91-1 4 4 0 012.52-.87 4.15 4.15 0 01.93.11l.45.14.42.18a4.15 4.15 0 01.6-1.14 4.46 4.46 0 01.9-.89 4.09 4.09 0 014.52-.23A4.25 4.25 0 0126.11 8a3.86 3.86 0 011.2-.69 4 4 0 01.69-.17 4.39 4.39 0 01.72-.07 4.22 4.22 0 011.64.34 4.35 4.35 0 011.33.92 4.31 4.31 0 011.23 3 4.13 4.13 0 011.54.4A4.28 4.28 0 0136.55 14a4.37 4.37 0 01-.92 4.64 4.08 4.08 0 01-1.34.91 4 4 0 01-1.63.34z'
      />
    </svg>
  );
}
