import React from 'react';

export function GarbageCan({ size = 20, color = '#3db384' }): JSX.Element {
  return (
    <svg width={size} height={size} viewBox='0 0 15.29 17'>
      <path
        d='M.5 2.79h14.29M13.64 2.79l-.57 12a1.72 1.72 0 01-1.71 1.71H3.93a1.72 1.72 0 01-1.72-1.71l-.57-12'
        fill='none'
        stroke={color}
      />
      <path
        d='M5.36 2.79V1.64A1.14 1.14 0 016.5.5h2.29a1.14 1.14 0 011.14 1.14v1.15M5.93 7.07v5.14M9.36 7.07v5.14'
        fill='none'
        stroke={color}
      />
    </svg>
  );
}
