import React from 'react';

type MenuProps = {
  size?: number;
};

export function Menu({ size }: MenuProps): JSX.Element {
  return (
    <svg style={{ display: 'block' }} viewBox='0 0 150 150' width={size} height={size}>
      <path
        d='M165.12 30.961v6.881H6.88v-6.88zm0 51.6v6.877H6.88v-6.876zm0 51.597v6.88H6.88v-6.88zm0 0'
        transform='translate(.45 .45) scale(.86686)'
        fill='#000'
        stroke='black'
        strokeWidth='6'
      />
    </svg>
  );
}
