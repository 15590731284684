import type { CSSProperties } from 'react';

type ReturnType = {
  [key: string]: CSSProperties;
};

export const styles = (isMobile: boolean): ReturnType => {
  return {
    container: {
      flex: 1,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 20,
      marginBottom: 20,
      paddingLeft: isMobile ? 16 : 50,
      paddingRight: isMobile ? 16 : 50,
      maxWidth: 1080,
    },
    heading2: {
      fontSize: 24,
      fontWeight: 'bold',
      marginBottom: 40,
      textAlign: 'center',
    },
    heading3: {
      fontSize: 20,
      fontWeight: 'bold',
      marginTop: 20,
      marginBottom: 20,
      color: '#000',
    },
    row: {
      flexDirection: 'row',
    },
    column: {
      flexDirection: 'column',
    },
    marginTop20: {
      marginTop: 20,
    },
    marginLeft20: {
      marginLeft: 20,
    },
    alignCenter: {
      alignItems: 'center',
    },
    justifyCenter: {
      justifyContent: 'center',
    },
    colorWhite: {
      color: '#fff',
    },
    containerBox: {
      backgroundColor: '#3db384',
      padding: 30,
      borderRadius: 5,
    },
    wrapper: {
      width: '60%',
    },
    button: {
      backgroundColor: '#3db384',
      borderRadius: 24,
      paddingLeft: 50,
      paddingRight: 50,
      paddingTop: 10,
      paddingBottom: 10,
    },
    buttonText: {
      color: '#fff',
    },
  };
};
