import * as React from 'react';

type CheckCircleFilledProps = {
  color?: string;
  size?: string | number;
};

export function CheckCircleFilled({ size = 64, color = '#3DB384' }: CheckCircleFilledProps): JSX.Element {
  return (
    <svg width={size} height={size} viewBox='0 0 84 84' fill={color}>
      <g id='03-Termin-buchen' stroke='none' strokeWidth={1} fill={color} fillRule='evenodd'>
        <g id='01-Step-1-Copy-18' transform='translate(-165.000000, -120.000000)' fill={color}>
          <path
            d='M207,120 C230.195959,120 249,138.804041 249,162 C249,185.195959 230.195959,204 207,204 C183.804041,204 165,185.195959 165,162 C165,138.804041 183.804041,120 207,120 Z M230.952711,142.841405 C230.312837,142.315238 229.367573,142.407415 228.841405,143.047289 L228.841405,143.047289 L201.108,176.773 L185.057989,160.803543 L184.943808,160.700859 C184.35344,160.221815 183.484467,160.258324 182.936675,160.808879 C182.352364,161.396137 182.354753,162.345881 182.942011,162.930192 L182.942011,162.930192 L200.161556,180.063325 L200.276184,180.166413 C200.907843,180.678934 201.84943,180.595677 202.378139,179.952711 L202.378139,179.952711 L231.158595,144.952711 L231.250022,144.829333 C231.671101,144.196316 231.552593,143.334688 230.952711,142.841405 Z'
            id='Combined-Shape'
          />
        </g>
      </g>
    </svg>
  );
}
