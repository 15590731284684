import React from 'react';

type SleepDisordersProps = {
  active: boolean;
};

export function SleepDisorders({ active }: SleepDisordersProps): JSX.Element {
  return (
    <svg viewBox='0 0 87.72 71.13'>
      <path
        stroke={active ? '#fff' : '#3db384'}
        fill='none'
        strokeWidth={1.13}
        d='M85.6 54.49V43.08s-.51-4.56-5.18-4.14S71 43.43 68 44.12s-15.6 1.14-17.11 1.56a4.46 4.46 0 00-3.11 4.14v4.67M15.94 54.49h68.63c1.6 0 2.59 1.5 2.59 3.11V68a2.4 2.4 0 01-2.59 2.59M79.38 38.41s1.2-6.42-3.63-9.85c-6.11-4.33-12.23-1.46-15.56-.56a10.36 10.36 0 00-5.7 3.63 4.56 4.56 0 00-1 2.08'
      />
      <path
        stroke={active ? '#fff' : '#3db384'}
        fill='none'
        strokeWidth={1.13}
        d='M56.57 33.23a25.25 25.25 0 01-5.19.52c-1.38-.21-3.35-5-3.63-5.7s.23-5.24-1.56-7.78-4.79-4.18-9.33-3.63-24.37 5.7-24.37 5.7l-6.22 2.08'
      />
      <path
        stroke={active ? '#fff' : '#3db384'}
        fill='none'
        strokeWidth={1.13}
        d='M36.34 23.38s-8.53.8-13.48 2.59-8.93 1.48-11.41.52a74.74 74.74 0 00-8.81-2.59 13.25 13.25 0 01-2.08-.52M65.9 4.71h-9.85l4.14 5.71M62.79 13.53l3.11 3.63h-9.85M74.71.56H69l2.07 2.6M73.68 6.27l1.55 2.07h-5.7'
      />
    </svg>
  );
}
