import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import type { ReducerAction } from '../../../types/navigationIndex.types';
import { Header } from '../../organisms/Header/Header';
import { StepOneOnlineFirstAppointment } from './OnlineFirstAppointmentSteps/StepOneOnlineFirstAppointment';
import { StepThreeOnlineFirstAppointment } from './OnlineFirstAppointmentSteps/StepThreeOnlineFirstAppointment';
import { StepTwoOnlineFirstAppointment } from './OnlineFirstAppointmentSteps/StepTwoOnlineFirstAppointment';
import style from './OnlineFirstAppointment.module.scss';

const cx = classNames.bind(style);

type OnlineFirstAppointmentProps = {
  currentPage: number;
  setCurrentPage: React.Dispatch<ReducerAction>;
};

const onlineFirstAppointmentClasses = cx({ OnlineFirstAppointment: true });

export function OnlineFirstAppointment({ currentPage, setCurrentPage }: OnlineFirstAppointmentProps) {
  const [onlineFirstAppointmentInfo, setOnlineFirstAppointmentInfo] = useState({});

  useEffect(() => {
    const reducerType = {
      type: '[UPDATE]',
      payload: {
        onlineFirstAppointmentIndex: 1,
      },
    };
    setCurrentPage(reducerType);
  }, []);

  if (Object.entries(onlineFirstAppointmentInfo).length === 0 && currentPage !== 1) {
    return null;
  }

  let pageToRender;
  switch (currentPage) {
    case 1:
      pageToRender = (
        <StepOneOnlineFirstAppointment
          setCurrentPage={setCurrentPage}
          onlineFirstAppointmentInfo={onlineFirstAppointmentInfo}
          setOnlineFirstAppointmentInfo={setOnlineFirstAppointmentInfo}
        />
      );
      break;
    case 2:
      pageToRender = (
        <StepTwoOnlineFirstAppointment
          setCurrentPage={setCurrentPage}
          onlineFirstAppointmentInfo={onlineFirstAppointmentInfo}
          setOnlineFirstAppointmentInfo={setOnlineFirstAppointmentInfo}
        />
      );
      break;
    case 3:
      pageToRender = (
        <StepThreeOnlineFirstAppointment
          setCurrentPage={setCurrentPage}
          onlineFirstAppointmentInfo={onlineFirstAppointmentInfo}
        />
      );
      break;
    default:
      pageToRender = <React.Fragment></React.Fragment>;
  }

  return (
    <React.Fragment>
      <Header showBackButton={true} barTitle='Erstgespräch buchen' />
      <div className={onlineFirstAppointmentClasses}>{pageToRender}</div>
    </React.Fragment>
  );
}
