import React from 'react';

type AdhsProps = {
  active: boolean;
};

export function Adhs({ active }: AdhsProps): JSX.Element {
  return (
    <svg viewBox='0 0 48.27 52.52'>
      <g id='Ebene_2'>
        <g id='Ebene_1-2'>
          <g id='Gruppe_42'>
            <g id='Gruppe_41'>
              <path
                id='Pfad_75'
                stroke={active ? '#fff' : '#3db384'}
                fill='none'
                strokeWidth={1.13}
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M24,43.52s-.09,5.85,4.78,5.85,9.58-.09,9.58-.09,1.95.27,2.39-2.75.45-2.93.45-2.93l1.24-6.38,5-1L43,25.52s.35-8.6-1.6-13.12A18.6,18.6,0,0,0,27.21,1.23,22.64,22.64,0,0,0,5.93,7.88C.7,13.55-.36,23,1.85,28.53S5.93,35,5.93,38.64V51.76'
              />
              <path
                id='Pfad_76'
                stroke={active ? '#fff' : '#3db384'}
                fill='none'
                strokeWidth={1.13}
                strokeLinecap='round'
                d='M27.36,11.87a8.63,8.63,0,1,1-7.89.27'
              />
            </g>
            <path
              id='Pfad_77'
              stroke={active ? '#fff' : '#3db384'}
              fill='none'
              strokeWidth={1.13}
              strokeLinecap='round'
              d='M12.67,13.05l6.61-1.52,1.52,6.61'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
